import queryString from "query-string";
import { cookies } from "../helpers/cookies";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";

const handleAffiliate = ({
  AffiliateEnabled,
  AffiliateCookieTTL,
  AffiliateCookieName,
  AffiliateCookieDomain,
}) => {
  if (AffiliateEnabled) {
    const queryStringDetails = queryString.parse(window.location.search);
    var gcaff = queryStringDetails.gcaff;
    if (isNullOrUndefined(gcaff)) {
      const qsDetails = new URLSearchParams(window.location.search);
      const qsParams = new URLSearchParams();
      for (const [name, value] of qsDetails) {
        qsParams.append(name.toLowerCase(), value);
      }
      gcaff = qsParams.get("gcaff");
    }

    const now = new Date(Date.now());
    const expirationDate = now.setDate(now.getDate() + AffiliateCookieTTL);
    if (isNullOrUndefined(cookies.get(AffiliateCookieName))) {
      if (!isNullOrUndefined(gcaff) && gcaff !== "") {
        cookies.set(AffiliateCookieName, gcaff, {
          expires: new Date(expirationDate),
          path: "/",
          domain: AffiliateCookieDomain,
        });
      }
    }
  }
};

export default handleAffiliate;
