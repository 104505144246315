import React from "react";

const initialState = {
  didCatch: false,
  error: null,
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    this.state = initialState;
  }

  static getDerivedStateFromError(error) {
    return { didCatch: true, error };
  }

  componentDidCatch(error, info) {
    this.props.onError?.(error, info);
  }

  resetErrorBoundary() {
    const { error } = this.state;

    if (error !== null) {
      this.setState(initialState);
    }
  }

  render() {
    const { children, FallbackComponent, fallbackRender } = this.props;
    const { didCatch, error } = this.state;

    let childToRender = children;

    if (didCatch) {
      const fallbackProps = {
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      };

      if (typeof fallbackRender === "function") {
        childToRender = fallbackRender(fallbackProps);
      } else if (FallbackComponent) {
        childToRender = React.createElement(FallbackComponent, fallbackProps);
      } else {
        throw error;
      }
    }

    return childToRender;
  }
}
