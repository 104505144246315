import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import accessibilityHelper from "../../helpers/accessibilityHelper";
import helper from "../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";
import Error from "./StaticControls/Error.Component";
import Info from "./StaticControls/Info.Component";
import Label from "./StaticControls/Label.Component";
import TextArea from "./StaticControls/TextArea.Component";
import Tooltip from "./StaticControls/Tooltip.Component";

const Radio = ({
  id,
  name,
  label,
  controlValuesList,
  handleChange,
  handleInteraction,
  currentValue,
  helpMessages,
  defaultValue,
  infoMessages,
  extraInfo,
  validation,
  attributes,
}) => {
  const customRadioStyle = attributes?.customRadioStyle ?? "";
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const errorClass = validation && validation.isValid === false ? "error" : "";
  const errorNextLineClass =
    name === "preferred-payment" || name === "licence-number-radio" ? "next-line" : "";
  const widthClass =
    name === "preferred-payment" ? "col-lg-8 col-md-8" : "col-lg-4 col-md-5";
  const highlightInfoClass = name === "preferred-payment" ? "info-highlight" : "";
  const buttonWidthClass =
    errorNextLineClass === "" ? "col-lg-4 col-md-8" : "col-lg-8 col-md-8";
  const [isVisible, setIsVisible] = useState(true);
  const hasLiveValidation = AppStore.liveValidation;
  const dependentControls = attributes?.dependentControls;

  const versionType =
    !isNullOrUndefined(AppStore.getUserJourneySettings().customControls) &&
      !isNullOrUndefined(AppStore.getUserJourneySettings().customControls.customRadio)
      ? AppStore.getUserJourneySettings().customControls.customRadio
      : "";
  const versionTypeStyle = versionType !== "" ? "radio-version-" + versionType : "";

  const desktopTooltipToggle =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.desktopTooltipToggle)
      ? attributes.desktopTooltipToggle
      : false;

  const changeHandler = (event) => {
    if (hasLiveValidation) handleChange(event); // (DW, 08-02-23) handleChange() is called if the liveValidation setting is applied in the userJourneySettings. The function handles live validation for dropdowns and radio controls.

    if (
      (name !== "licence-number-radio" ||
        AppStore.getControlByName("licence-not-obtained") !== true) &&
      (name !== "licence-number-radio-additional" ||
        AppStore.getControlByName("licence-not-obtained-additional") !== true)
    ) {
      handleChange(event);
    }

    if (name === "uk-since-birth" && event.target.value === "yes") {
      const dateOfBirth = AppStore.getControlByName("date-of-birth");
      AppStore.setFormData({ "uk-resident-since": dateOfBirth });
    }

    if (name === "uk-since-birth-additional" && event.target.value === "yes") {
      const dateOfBirth = AppStore.getControlByName("date-of-birth-additional");
      AppStore.setFormData({
        "uk-resident-since-additional": dateOfBirth,
      });
    }

    if (
      name === "accident-claims-radio-additional" ||
      name === "motoring-convictions-radio-additional"
    ) {
      if (event.target.value === "no" || event.target.value === "yes") {
        AppStore.setHasUserSelectedAdditionalClaimsConvictionsValue(true);
      }
    }

    //#region - Caravan controls conditional data & visibility
    if (name === "caravan-permanently-site" && event.target.value === "no") {
      AppStore.setFormData({ "caravan-permanently-site": event.target.value });
      AppStore.setVisibilityControls({ ["number-of-months"]: false });
    }

    if (name === "different-address") {
      if (event.target.value === "no") {
        AppStore.setFormData({ "different-address": event.target.value });
        AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: false });
        AppStore.deleteFormData("caravan-storage-postcode");
      }
      else if (event.target.value === "yes") {
        AppStore.setVisibilityControls({ ["caravan-storage-postcode"]: true });
      }
    }

    if (name == "security-gates") {
      AppStore.setFormData({ "security-gates": event.target.value });
    }

    if (name == "static-storage-damage") {
      AppStore.setFormData({ "static-storage-damage": event.target.value });
    }

    if (name == "cctv") {
      AppStore.setFormData({ "cctv": event.target.value });
    }

    if (name == "full-time-warden") {
      AppStore.setFormData({ "full-time-warden": event.target.value });
    }
    //#endregion

    // Set the value of the actual DVLA-medical control to 'N' when the control is hidden by the Radio control.
    helper.ManipulateControlValuesOnChange(name, event.target.value);

    handleInteraction(event);
  };
  // Temporary solution for quotes that are created on !NQS
  // TODO: remove this useEffect() and the dependentControls from controls.json when all sites have v3
  // displayTrigger is needed as some dropdowns get displayd when no is selected

  useEffect(() => {
    // show hidden dropdowns at recall
    if (hasLiveValidation && dependentControls) {
      let hasValueFromRecall = false;
      dependentControls.forEach((element) => {
        const dropDownValue = AppStore.getControlByName(element);
        if (!isNullOrUndefined(dropDownValue) && dropDownValue !== "N") {
          hasValueFromRecall = true;
        }
      });

      if (hasValueFromRecall) {
        AppStore.setFormData({ [name]: attributes.displayingTrigger });
      }
    }
  }, [hasLiveValidation, dependentControls]);

  useEffect(() => {
    if (name === "exclude-pillion") {
      if (AppStore.getControlByName("driving-licence") === "P") {
        setIsVisible(false);
        AppStore.setFormData({ [name]: "yes" });
      } else {
        setIsVisible(true);
      }
    }

    if (name === "include-pillion") {
      if (AppStore.getControlByName("driving-licence") === "P") {
        setIsVisible(false);
        AppStore.setFormData({ [name]: "no" });
      } else {
        setIsVisible(true);
      }
    }
    helper.ManipulateControlValues(name, currentValue);
  });

  const controlComponent = controlValuesList.map((item) => (
    <div
      key={name + "_" + item.Key}
      className={`form-check form-check-inline ${versionTypeStyle} ${customRadioStyle} ${currentValue === item.Key ? "checked" : ""
        }`}>
      <input
        id={`${name}-${item.Key}`}
        key={`${name}-${item.Key}`}
        data-testid={`test-${name}_${item.Key}`}
        className="form-check-input"
        type="radio"
        name={name}
        disabled={helper.GetIsOptionDisabled(name, currentValue, item.Key)}
        value={item.Key}
        onChange={changeHandler}
        checked={
          isNullOrUndefined(currentValue) || currentValue.length === 0
            ? !isNullOrUndefined(defaultValue) &&
              defaultValue.length !== 0 &&
              item.Key === defaultValue
              ? "checked"
              : ""
            : item.Key === currentValue
              ? "checked"
              : ""
        }
      />
      <label
        id={`${name}-${item.Key}`}
        className="form-check-label"
        htmlFor={`${name}-${item.Key}`}
        tabIndex={0}
        onKeyDown={(event) =>
          accessibilityHelper.replicateClickAction(event, `${name}-${item.Key}`)
        }>
        {item.Value}
      </label>
    </div>
  ));

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation ? validation : ""}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: helpMessages ? (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
        desktopToggle={desktopTooltipToggle}
      />
    ) : (
      ""
    ),
    info: (
      <React.Fragment key={`infowrapper-${name}`}>
        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className={"extraInfo"}
                />
              )
          )}

        {infoMessages ? (
          <Info
            key={`infokey-input-${name}`}
            infoMessages={infoMessages != null ? infoMessages.info : ""}
            className={highlightInfoClass}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    ),
    error: (
      <Error
        name={name}
        key={`errorkey-${name}`}
        validation={validation ? validation : ""}
        className={errorNextLineClass}
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `${versionType === "button"
      ? `${buttonWidthClass} col-sm-12 col-12`
      : `${widthClass} col-sm-12 col-12`
      }`,
  };

  return (
    isVisible && (
      <React.Fragment>
        <div
          className={`form-group form-group-container form-group-${name} ${versionType === "button" || versionType === "button-v2" ? "custom-group" : ""
            } ${customFormGroupClass}`}>
          <div id={`row-${name}`} className={`row row-${errorClass} `}>
            <ControlRowFactory
              controlName={name}
              groupClasses={groupClasses}
              componentMappings={componentMapping}
            />
          </div>
        </div>
      </React.Fragment>
    )
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
};

export default observer(Radio);
