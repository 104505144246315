import codelistMappings from "@constants/codelistMappings";
import ClientLogging from "@providers/loggingProvider";
import http from "@services/httpService";

const remapCodelistProps = (list) => {
  return list.map((obj) => {
    return { Key: obj.value, Value: obj.label };
  });
};

const endpointCodeLists = `/${import.meta.env.VITE_CODELIST_API_PATH}/${
  import.meta.env.VITE_PRODUCT
}/${import.meta.env.VITE_SKIN}/${import.meta.env.VITE_VERSION}`;

const getCodelists = async (stringList) => {
  const params = {
    codelistNames: stringList,
  };

  const response = await http
    .get(endpointCodeLists, {
      params: params,
      withCredentials: true,
    })
    .catch((ex) => {
      ClientLogging.logError(ex, "/codelist endpoint error");
    });

  if (response?.data) {
    const remappedCodelist = {};
    response.data.forEach((listObj) => {
      const controlName = codelistMappings[listObj.codeListName];
      remappedCodelist[controlName] = remapCodelistProps(listObj.codes);
    });

    return remappedCodelist;
  }

  return {};
};

export { getCodelists, remapCodelistProps };
