import PropTypes from "prop-types";
import React from "react";
import { cookies } from "../../../helpers/cookies";
import CookieBannerContent from "./CookieBannerContent";

const CONSENT_COOKIE = "CookieConsent";

function getConsentCookie() {
  return cookies.get(CONSENT_COOKIE);
}

function removeConsentCookie() {
  cookies.remove(CONSENT_COOKIE);
}

function getTopDomain() {
  let hostname = document.location.hostname.split(".");

  if (hostname.length <= 2) return hostname.join(".");

  hostname.shift();

  return "." + hostname.join(".");
}

function setConsentCookie(value) {
  cookies.set(CONSENT_COOKIE, value, {
    path: "/",
    expires: 365,
    domain: getTopDomain(),
  });
}

class CookieBanner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      consentCookies: true,
    };

    this.onScroll = this.onScroll.bind(this);
    this.onToggleConsentCookies = this.onToggleConsentCookies.bind(this);
    this.confirm = this.confirm.bind(this);
    this.decline = this.decline.bind(this);
    this.onClick = this.onClick.bind(this);
    this.consetsCallback = this.consetsCallback.bind(this);
    this.addEvtListener = this.addEvtListener.bind(this);
    this.removeEvtListener = this.removeEvtListener.bind(this);
  }

  componentDidMount() {
    const { dismissOnScroll } = this.props;

    if (dismissOnScroll !== true) {
      return;
    }
    if (getConsentCookie() === undefined) {
      this.addEvtListener();
    }
  }

  componentWillUnmount() {
    this.removeEvtListener();
  }

  addEvtListener() {
    if (document.addEventListener) {
      document.addEventListener("mousedown", this.onClick);
    } else if (document.attachEvent) {
      document.attachEvent("mousedown", this.onClick); // < IE9
    }
  }

  removeEvtListener() {
    if (document.removeEventListener) {
      document.removeEventListener("mousedown", this.onClick);
    } else if (document.detachEvent) {
      document.detachEvent("mousedown", this.onClick); // < IE9
    }
  }

  onScroll() {
    // To be used in case of device check
    // const userAgent = typeof window.navigator !== "undefined" ? "" : navigator.userAgent;
    // const isIphone = Boolean(userAgent.match(/Iphone/i));

    //Threshold to avoid fake trigger of scroll event,
    //but still persist on some Iphone - Safari versions.
    if (window.scrollY && window.pageYOffset) {
      this.confirm();
    }
  }

  onClick(event) {
    if (event.button === 2) {
      return;
    }
    if (event.target.id === "cookieConsentFalse") {
      this.decline();
    } else if (event.target.id !== "cookieConsentTrue") {
      this.confirm();
    }
  }

  onToggleConsentCookies() {
    let { consentCookies } = this.state;
    this.setState({ consentCookies: !consentCookies });
  }

  confirm() {
    let { consentCookies } = this.state;
    if (consentCookies) {
      setConsentCookie(consentCookies);
    } else {
      removeConsentCookie();
    }
    this.removeEvtListener();
    this.forceUpdate();
  }

  decline() {
    const { onDeclineConsent = Function } = this.props;
    this.setState({ consentCookies: false });
    setConsentCookie(false);
    onDeclineConsent();
    this.removeEvtListener();
    this.forceUpdate();
  }

  consetsCallback() {
    const {
      onAccept = Function,
      onAcceptConsent = Function,
      onDeclineConsent = Function,
    } = this.props;

    const hasConsentCookie = getConsentCookie();
    onAccept();

    if (hasConsentCookie) {
      onAcceptConsent();
    } else {
      onDeclineConsent();
    }
  }

  render() {
    const {
      className,
      message,
      policyLink,
      privacyPolicyLinkText,
      showDeclineButton,
      acceptButtonText,
      declineButtonText,
      declineText,
    } = this.props;

    if (getConsentCookie()) {
      this.consetsCallback();
      return null;
    }

    const contentProps = {
      className,
      message,
      policyLink,
      privacyPolicyLinkText,
      showDeclineButton,
      acceptButtonText,
      declineButtonText,
      onToggleConsentCookies: this.onToggleConsentCookies,
      onDecline: this.decline,
      onConfirm: this.confirm,
      declineText,
    };

    return <CookieBannerContent {...contentProps} />;
  }
}

CookieBanner.protoTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  message: PropTypes.string.isRequired,
  policyLink: PropTypes.string,
  privacyPolicyLinkText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  declineButtonText: PropTypes.string,
  showDeclineButton: PropTypes.bool,
  dismissOnScroll: PropTypes.bool,
  onAccept: PropTypes.func,
  declineText: PropTypes.string,
};

export default CookieBanner;
