import { controlNames } from "../../constants";
import AppStore from "../../store/AppStore";

function manipulateControls(controlName, controlValue) {
  if (controlName === controlNames.optionalExtrasRadio && controlValue !== "yes") {
    AppStore.deleteFormData(controlNames.optionalExtrasSelect);
    AppStore.shouldRevalidate[controlNames.optionalExtrasSelect] = true;
    // ensures when option "no" is selected,
    // none of addons on results will be selected
    AppStore.clearAddonFilters();
  }
}

function manipulateControlsOnChange(controlName, controlValue) {}

function manipulateControlsPreRender(controlName) {}

export default {
  manipulateControls,
  manipulateControlsOnChange,
  manipulateControlsPreRender,
};
