import ClientLogging from "../providers/loggingProvider";
import { singleQueryStringNotificationAction } from "./../tracking/vistats";
import { isNullOrUndefined } from "./isNullOrUndefined";

export function vistatsV1Call(key, value, valueParser, keyParser) {
  try {
    singleQueryStringNotificationAction({
      data: {
        element: {
          key: `${key}`,
          value: `${value}`,
        },
        valueParser: valueParser,
        keyParser: keyParser,
        formPrefix: "",
      },
    });
  } catch (error) {
    ClientLogging.logError(
      error,
      `vistats call for, key: ${key} value:${value}`
    );
  }
}

export function vistatsSendFieldsWithValidationErrors(errors) {
  try {
    let fieldsWithErrorsUnique = [
      ...new Set(
        errors.map((error) => Object.keys(error)[0].replace("validator-", ""))
      ),
    ].join(";");

    vistatsV1Call(
      "ValidationErrors",
      fieldsWithErrorsUnique,
      () => fieldsWithErrorsUnique,
      () => "ValidationErrors"
    );
  } catch (error) {
    ClientLogging.logError(error, `vistats call for validation errors`);
  }
}

export function vistatsRecallEvents(queryStringValues, recallState, statusValidation) {
    let lqs = queryStringValues;

    let actionLabel = "";
    let categoryLabel = "ManualValidation";
    if (!isNullOrUndefined(lqs["utm_term"]) && lqs["utm_term"] === "view_all") categoryLabel = "ViewValidation";
    else if (lqs["utm_term"] === "amend" || lqs["utm_term"] === "returnlink") categoryLabel = "AmendValidation";
    else if (lqs["utm_content"] === "buy-link") categoryLabel = "CTBValidation";

    switch (recallState) {
        case 'ValidQuote':
            actionLabel = "ValidQuote";
            break;
        case 'RecallComplete':
            actionLabel = "ValidQuote";
            break;
        case 'RecallExpired':
            actionLabel = "ExpiredInception";
            break;
        default:
            actionLabel = "ExpiredOld";
            break;
    }

    let categoryValue = (statusValidation === "Success") ? `${actionLabel}&${statusValidation}` : statusValidation;

    vistatsV1Call(
        categoryLabel,
        categoryValue,
        () => categoryValue,
        () => categoryLabel
    );
}