import React from "react";
import DOMPurify from "dompurify";

const Phone = ({ id, name, value }) => {
  const hrefClasses = `phone text-center d-block ${name}-anchor`;
  const phoneClasses = `phone ${name}`;
  const href = `tel:${value}`;
  return (
    <a href={href} className={hrefClasses} aria-label={name}>
      <span
        className={phoneClasses}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(value, {
            USE_PROFILES: { html: true },
            ADD_TAGS: ["a", "p", "div", "span"],
            ADD_ATTR: ["href", "target", "name", "class"],
          }),
        }}
      ></span>
    </a>
  );
};

export default Phone;
