import { cookies } from "@helpers/cookies";
import { isQueryParamMatched } from "@helpers/queryParamMatcher";
import { resolveStaticAssetPath } from "@helpers/staticAsset";
import AppStore from "../../../store/AppStore";

const StaticImage = ({ currentPageName }) => {
  const ujSettings = AppStore.getUserJourneySettings()?.staticImage;

  const imageURL = cookies.get(ujSettings?.cookieName);

  if (!imageURL) return null;

  if (
    !isQueryParamMatched(
      ujSettings?.showWhenQueryParam?.name,
      ujSettings?.showWhenQueryParam?.value
    )
  ) {
    return null;
  }

  const name = currentPageName.toLowerCase().replace(" ", "");

  return (
    <div className={`static-image ${name} wrapper`}>
      <img className="static-image image" src={resolveStaticAssetPath(imageURL)} />
    </div>
  );
};

export default StaticImage;
