import { useRef, useState } from "react";
import { useEventCallback } from "./useEventCallback";

/** @param {Boolean} isControlled */
function useControlModeChangeWarning(isControlled) {
  const ref = useRef(isControlled);
  const wasControlled = ref.current;

  if (wasControlled !== isControlled) {
    console.warn(
      `WARN: A component changed from ${
        wasControlled ? "controlled" : "uncontrolled"
      } to ${isControlled ? "controlled" : "uncontrolled"}.`
    );
  }

  ref.current = isControlled;
}

/**
 * React hook that allows components to handle
 * both controlled and uncontrolled modes.
 *
 * Useful when a reusable component must support both usage types.
 *
 * @example
 * const ReusableComponent = ({ defaultValue = 0, value, onChange }) => {
 *   const [state, setState] = useControllableState({
 *     defaultValue, value, onChange
 *    });
 *
 *   const increment = () => setState((v) => v + 1);
 *   const decrement = () => setState((v) => v - 1);
 *
 *   return (
 *     <div>
 *       <div>Counter: {state}</div>
 *       <Button onClick={increment}>+</Button>
 *       <Button onClick={decrement}>-</Button>
 *     </div>
 *   );
 * }
 *
 * // Later when using ReusableComponent
 *
 * // works
 * <ReusableComponent />
 *
 * // also works, uncontrolled mode
 * <ReusableComponent defaultValue={15} />
 *
 * // also works, controlled mode
 * <ReusableComponent state={controlledState} onChange={setControlledState} />
 *
 * @template T
 * @param {Object} options config
 * @param {T | (() => T)} [options.defaultValue] Default value in uncontrolled mode
 * @param {T} [options.value] The value to be used in controlled mode
 * @param {(value: T) => void} [options.onChange] The callback fired when the value changes in controlled mode
 
 * @returns {[T,  (value: T | ((prevState: T) => T)) => void]}
 */
export function useControllableState(options) {
  const { value: valueProp, defaultValue, onChange: onChangeProp } = options;

  const [uncontrolledState, setUncontrolledState] = useState(valueProp ?? defaultValue);
  const isControlled = valueProp !== undefined;
  const value = isControlled ? valueProp : uncontrolledState;

  useControlModeChangeWarning(isControlled);

  const setValue = useEventCallback((next) => {
    const nextValue = typeof next === "function" ? next(value) : next;

    if (!isControlled) {
      setUncontrolledState(nextValue);
    }

    onChangeProp?.(nextValue);
  });

  return [value, setValue];
}
