import PropTypes from "prop-types";
import React from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import accessibilityHelper from "../../helpers/accessibilityHelper";
import helpers from "../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";
import Error from "../controls/StaticControls/Error.Component";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";
import TextArea from "./StaticControls/TextArea.Component";

const Input = ({
  id,
  name,
  label,
  type,
  defaultValue,
  required,
  handleBlur,
  handleChange,
  handleInteraction,
  currentValue,
  helpMessages,
  infoMessages,
  extraInfo,
  validation,
  attributes,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const errorClass = validation && validation.isValid === false ? "error" : "";
  const allowedCharsRegex = new RegExp(
    attributes.allowedCharsRegex !== null || attributes.allowedCharsRegex !== "undefined"
      ? attributes.allowedCharsRegex
      : null
  );
  const shouldSendToUnsubscribe =
    !isNullOrUndefined(attributes.sendToUnsubscribe) &&
    attributes.sendToUnsubscribe === "true"
      ? true
      : false;

  const changeHandler = (event) => {
    if (!isNullOrUndefined(validation)) validation.isValid = true;
    event.preventDefault();
    handleInteraction(event);
    if (!allowedCharsRegex.test(event.target.value)) {
      event.target.value = helpers.ReplaceByRegex(name, event.target.value);
    }
    handleChange(event);
    if (shouldSendToUnsubscribe) {
      AppStore.setUnsubscribeData({
        ["proposerEmail"]: event.target.value,
      });
    }
  };

  const controlComponent = (
    <input
      key={`controlkey-${name}`}
      type={
        attributes.type !== null || attributes.type !== "undefined"
          ? attributes.type
          : type
      }
      className={`form-control control-${errorClass}`}
      autoComplete={accessibilityHelper.getAutoCompleteAttribute(name)}
      id={name}
      data-testid={`test-${name}`}
      name={name}
      required={required}
      onBlur={handleBlur}
      onChange={changeHandler}
      value={currentValue}
      maxLength={
        attributes.maxLength !== null || attributes.maxLength !== "undefined"
          ? attributes.maxLength
          : "200"
      }
      placeholder={
        attributes.placeholder !== null || attributes.placeholder !== "undefined"
          ? attributes.placeholder
          : ""
      }
    />
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation ?? ""}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: helpMessages ? (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ) : (
      ""
    ),
    info: (
      <>
        {extraInfo &&
          extraInfo.map(
            (ei) =>
              ei.text && (
                <TextArea
                  key={`extrainfotextkey-${name}`}
                  name={`extrainfotextkey-${name}`}
                  label={ei.text}
                  className={"extraInfo"}
                />
              )
          )}

        {infoMessages ? (
          <Info
            key={`infokey-input-${name}`}
            infoMessages={infoMessages != null ? infoMessages.info : ""}
          />
        ) : (
          ""
        )}
      </>
    ),
    error: (
      <Error key={`errorkey-${name}`} validation={validation ?? ""} forInput={name} />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  return (
    <div
      className={`form-group form-group-container form-group-${name} ${customFormGroupClass}`}>
      <div id={`row-${name}`} className={`row row-${errorClass}`} name={`${errorClass}`}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  currentValue: PropTypes.string,
  helpMessages: PropTypes.object,
  defaultValue: PropTypes.object,
  infoMessages: PropTypes.object,
  extraInfo: PropTypes.array,
  attributes: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleInteraction: PropTypes.func,

  validation: PropTypes.shape({
    isValid: PropTypes.bool,
    messages: PropTypes.array,
  }),
};

export default Input;
