import dayjs from "dayjs";
import { toJS } from "mobx";
import { listOfLicenceDefenceOccupationsArray } from "../components/controls/OccupationAndIndustries/imports/LicenceDefenceOccupationsArray";
import { controlNames } from "../constants";
import { default as DateParser } from "../helpers/dateParser";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";
import * as ControlLogic from "./controlLogic";
import carLicenceBike from "./controlLogic/carLicenceBike";

const controlsLogicHelper = {
  ManipulateControlValues: (controlName, controlValue, version = null) => {
    try {
      if (controlName === "dvla-medical" || controlName === "dvla-medical-additional") {
        const additionalSuffix = controlName.includes("additional") ? "-additional" : "";
        const medName = `dvla-medical${additionalSuffix}`;
        const medBooleanName = `dvla-medical-boolean${additionalSuffix}`;

        if (
          !isNullOrUndefined(controlValue) &&
          controlValue !== "" &&
          controlValue !== "N"
        ) {
          AppStore.setFormData({ [medBooleanName]: "yes" });
        } else {
          AppStore.setFormData({ [medName]: "N" });
        }
      }

      if (controlName === "keeper-owner") {
        const registerKeeperVal = AppStore.getControlByName("register-keeper");
        const legalOwnerVal = AppStore.getControlByName("legal-owner");
        const keeperOwnerVal = AppStore.getControlByName("keeper-owner");
        // controlValue is empty when doing a recall from old TBI and
        // we need to update it based on legal-owner and keeper-owner
        if (
          isNullOrUndefined(controlValue) ||
          controlValue === "" ||
          controlValue === "yes"
        ) {
          if (
            (!isNullOrUndefined(registerKeeperVal) &&
              registerKeeperVal !== "PR" &&
              registerKeeperVal !== "") ||
            (!isNullOrUndefined(legalOwnerVal) &&
              legalOwnerVal !== "PR" &&
              legalOwnerVal !== "")
          ) {
            AppStore.setVisibilityControls({
              "register-keeper": true,
            });
            AppStore.setVisibilityControls({
              "legal-owner": true,
            });
            AppStore.setFormData({ "keeper-owner": "no" });
          } else {
            AppStore.setVisibilityControls({
              "register-keeper": false,
            });
            AppStore.setVisibilityControls({
              "legal-owner": false,
            });
            AppStore.setFormData({ "keeper-owner": "yes" });
            AppStore.setFormData({ "register-keeper": "PR" });
            AppStore.setFormData({ "legal-owner": "PR" });
          }
        }
      }
      if (controlName === "official-uk-model") {
        let mhOfficialUkModel = !isNullOrUndefined(
          AppStore.getControlByLogicalName("mh-official-uk-model")
        )
          ? AppStore.getControlByLogicalName("mh-official-uk-model")
          : "";
        if (
          mhOfficialUkModel !== "" &&
          (mhOfficialUkModel === "yes" || mhOfficialUkModel === true)
        ) {
          AppStore.deleteFormData("vehicle-imported");
        }
      }
      if (controlName === "security-devices") {
        if (controlValue === "no" || controlValue === false) {
          let mhSecurityDevices = !isNullOrUndefined(
            AppStore.getControlByLogicalName("mh-security-devices")
          )
            ? AppStore.getControlByLogicalName("mh-security-devices")
            : "";

          if (mhSecurityDevices === "") return;

          if (mhSecurityDevices === "no") {
            AppStore.setFormData({
              "security-devices-dropdown": "FACT",
            });
          } else {
            AppStore.setFormData({
              "security-devices-dropdown": "NONE",
            });
          }
        }
      }
      if (controlName === "tracker") {
        if (controlValue === "no" || controlValue === false) {
          AppStore.setFormData({
            "tracker-dropdown": "NONE",
          });
        }
      }
      if (controlName === "liability") {
        if (controlValue === "no" || controlValue === false) {
          AppStore.setVisibilityControls({
            "cost-of-claim": false,
          });
          AppStore.deleteFormData("cost-of-claim");
          AppStore.setVisibilityControls({
            "cost-of-third-party-claim": false,
          });
          AppStore.deleteFormData("cost-of-third-party-claim");
        }
      }
      if (controlName === "ncd-other-vehicles") {
        if (AppStore.getControlByName("other-vehicles") !== "NA") {
          AppStore.setVisibilityControls({
            "ncd-other-vehicles": true,
          });
        } else {
          AppStore.setVisibilityControls({
            "ncd-other-vehicles": false,
          });
          AppStore.deleteFormData("ncd-other-vehicles");
        }
      }
      if (controlName === "ncd-other-vehicles-mh") {
        if (
          AppStore.getControlByName("other-vehicles-mh") === "OC" ||
          AppStore.getControlByName("other-vehicles-mh") === "OV" ||
          AppStore.getControlByName("other-vehicles-mh") === "OM"
        ) {
          AppStore.setVisibilityControls({
            "ncd-other-vehicles-mh": true,
          });
        } else {
          AppStore.setVisibilityControls({
            "ncd-other-vehicles-mh": false,
          });
        }
      }
      if (controlName === "vehicle-modifications") {
        if (controlValue === "no" || controlValue === false) {
          AppStore.setVisibilityControls({
            "vehicle-modifications-list": false,
          });
          AppStore.deleteFormData("vehicle-modifications-list");
        }
        if (controlValue === "yes") {
          AppStore.setVisibilityControls({
            "vehicle-modifications-list": true,
          });
        }
      }
      if (controlName === "club-member") {
        if (controlValue === "no" || controlValue === false) {
          AppStore.setFormData({
            "club-member-dropdown": "NONE",
          });
        }
      }
      if (controlName === "motorhome-club-member") {
        if (controlValue === "OTHE") {
          AppStore.setVisibilityControls({
            "motorhome-club-member-other": true,
          });
        } else {
          AppStore.setVisibilityControls({
            "motorhome-club-member-other": false,
          });
        }
      }
      if (controlName === "official-uk-model") {
        let mhOfficialUkModel = !isNullOrUndefined(
          AppStore.getControlByLogicalName("mh-official-uk-model")
        )
          ? AppStore.getControlByLogicalName("mh-official-uk-model")
          : "";
        if (mhOfficialUkModel !== "") {
          if (mhOfficialUkModel === "no" || mhOfficialUkModel === false) {
            AppStore.setVisibilityControls({
              "vehicle-imported": true,
            });
          } else {
            AppStore.setVisibilityControls({
              "vehicle-imported": false,
            });
            AppStore.deleteFormData("vehicle-imported");
          }
        }
      }
      if (controlName === "driving-licence") {
        let drivingLicenceCar = !isNullOrUndefined(
          AppStore.getControlByLogicalName("driving-licence-car")
        )
          ? AppStore.getControlByLogicalName("driving-licence-car")
          : "";
        if (
          drivingLicenceCar !== "" &&
          (drivingLicenceCar === "F" || drivingLicenceCar === "P")
        ) {
          AppStore.setVisibilityControls({
            "driving-qualifications": true,
          });
        } else {
          AppStore.setVisibilityControls({
            "driving-qualifications": false,
          });
          AppStore.deleteFormData("driving-qualifications");
        }
      }
      if (controlName === "driving-licence-additional") {
        let drivingLicenceCarAdditional = !isNullOrUndefined(
          AppStore.getControlByLogicalName("driving-licence-car-additional")
        )
          ? AppStore.getControlByLogicalName("driving-licence-car-additional")
          : "";
        if (
          drivingLicenceCarAdditional !== "" &&
          (drivingLicenceCarAdditional === "F" || drivingLicenceCarAdditional === "P")
        ) {
          AppStore.setVisibilityControls({
            "driving-qualifications-additional": true,
          });
        } else {
          AppStore.setVisibilityControls({
            "driving-qualifications-additional": false,
          });
          AppStore.deleteFormData("driving-qualifications-additional");
        }
      }
      if (controlName === "licence-not-obtained") {
        if (controlValue === true) {
          if (!isNullOrUndefined(AppStore.getControlByName("licence-number-radio"))) {
            AppStore.setFormData({
              "licence-number-radio": "no",
            });
            AppStore.setVisibilityControls({
              "licence-number-input": false,
            });
          }
        }
      }
      if (controlName === "licence-number-radio") {
        if (controlValue === "yes") {
          AppStore.setVisibilityControls({
            "licence-number-input": true,
          });
        } else {
          AppStore.deleteFormData("licence-number-input");
          AppStore.setHasChanged("licence-number-input", false);
          AppStore.setHasChanged("licence-number-input-3", false);
          AppStore.setHasChanged("licence-number-input-4", false);
        }
      }
      if (controlName === "licence-not-obtained-additional") {
        if (controlValue === true) {
          if (
            !isNullOrUndefined(
              AppStore.getControlByName("licence-number-radio-additional")
            )
          ) {
            AppStore.setFormData({
              "licence-number-radio-additional": "no",
            });
            AppStore.setVisibilityControls({
              "licence-number-input-additional": false,
            });
          }
        }
      }
      if (controlName === "licence-number-radio-additional") {
        if (controlValue === "yes")
          AppStore.setVisibilityControls({
            "licence-number-input-additional": true,
          });
        else {
          AppStore.deleteFormData("licence-number-input-additional");
          AppStore.setHasChanged("licence-number-input-additional-3", false);
          AppStore.setHasChanged("licence-number-input-additional-4", false);
          AppStore.setHasChanged("licence-number-input-additional", false);
        }
      }
      if (controlName === "licence-number-input-additional") {
        AppStore.setFormData({
          "licence-number-input-additional": controlValue,
        });
      }
      if (
        controlName === "ncb-bike" ||
        controlName === "ncd-motorhome" ||
        controlName === "ncd-car" ||
        controlName === "ncd-van"
      ) {
        let intValue = parseInt(controlValue);
        if (!isNaN(intValue) && intValue > 3) {
          let shouldShowNCD = true;
          if (AppStore.invalidControls["ncb-bike"]) {
            shouldShowNCD = false;
          }

          AppStore.setVisibilityControls({ ["protected-ncd"]: shouldShowNCD });
        } else {
          AppStore.setVisibilityControls({ ["protected-ncd"]: false });
          AppStore.setFormData({ "protected-ncd": "no" });
        }
      }

      //Set blank licence controls if licence-not-obtained is not in question set
      if (controlName === "driving-licence") {
        const obtainedValue = AppStore.getControlByName("licence-not-obtained");
        const control = AppStore.getControlsInOrder("riderdetails").find(
          (c) => c.Name === "licence-not-obtained"
        );
        if (
          (obtainedValue === true ||
            obtainedValue === "true" ||
            obtainedValue === "True") &&
          isNullOrUndefined(control) &&
          !isNullOrUndefined(version) &&
          version === "v2"
        ) {
          AppStore.setFormData({
            "years-licence-held": "",
          });
          AppStore.setFormData({
            "driving-licence": "",
          });
          AppStore.forceSetFormData("licence-not-obtained", "false");
        }
      }
      if (controlName === "driving-licence-additional") {
        const obtainedValueAdditional = AppStore.getControlByName(
          "licence-not-obtained-additional"
        );
        const controlAdditional = AppStore.getControlsInOrder(
          "additionalriderinformation"
        ).find((c) => c.Name === "licence-not-obtained-additional");
        if (
          (obtainedValueAdditional === true ||
            obtainedValueAdditional === "true" ||
            obtainedValueAdditional === "True") &&
          isNullOrUndefined(controlAdditional) &&
          !isNullOrUndefined(version) &&
          version === "v2"
        ) {
          AppStore.setFormData({
            "years-licence-held-additional": "",
          });
          AppStore.setFormData({
            "driving-licence-additional": "",
          });
          AppStore.forceSetFormData("licence-not-obtained-additional", "false");
        }
      }
      if (controlName === "year-manufacture" || controlName === "vehicle-value") {
        let carManufactureYear = !isNullOrUndefined(
          AppStore.getControlByLogicalName("car-year-manufacture")
        )
          ? AppStore.getControlByLogicalName("car-year-manufacture")
          : "";
        let carVehicleValue = !isNullOrUndefined(
          AppStore.getControlByLogicalName("car-vehicle-value")
        )
          ? AppStore.getControlByLogicalName("car-vehicle-value")
          : "";
        if (carManufactureYear !== "" && carVehicleValue !== "") {
          let currentDateValue = dayjs().format("YYYY-MM-DD");
          let currentDay = dayjs().format("DD");
          let currentMonth = dayjs().format("MM") - 1;
          let manufactureDate = dayjs(
            new Date(carManufactureYear, currentMonth, currentDay)
          ).format("YYYY-MM-DD");

          let vehicleAge = parseInt(
            DateParser.getDateDiffInYears(manufactureDate, currentDateValue)
          );

          if (vehicleAge < 10 && carVehicleValue > 5000) {
            AppStore.setVisibilityControls({
              "gap-insurance-agreement": true,
            });
          } else {
            AppStore.setVisibilityControls({
              "gap-insurance-agreement": false,
            });
            AppStore.setFormData({
              "gap-insurance-agreement": "",
            });
          }
        } else {
          AppStore.setVisibilityControls({
            "gap-insurance-agreement": false,
          });
          AppStore.setFormData({
            "gap-insurance-agreement": "",
          });
        }
      }

      if (controlName.includes("uk-resident-since-radio")) {
        const additionalName = controlName.includes("additional") ? "-additional" : "";
        const residentSinceName = "uk-resident-since" + additionalName;
        const dobName = "date-of-birth" + additionalName;

        if (controlValue === "yes") {
          AppStore.setFormData({
            [residentSinceName]: AppStore.getControlByName(dobName),
          });
        }
      }

      if (controlName.includes("occupation-type")) {
        const additionalName = controlName.includes("additional") ? "-additional" : "";
        const occupationStudentControlName = "occupation-student" + additionalName;

        const occupationDropdownName = "occupation-abi-dropdown" + additionalName;
        const industryDropdownName = "industry-abi-dropdown" + additionalName;

        if (controlValue !== "FTE") AppStore.deleteFormData(occupationStudentControlName);

        if (isNullOrUndefined(controlValue) || controlValue === "") {
          AppStore.deleteFormData(occupationStudentControlName);
          AppStore.deleteFormData(industryDropdownName);
          AppStore.deleteFormData(occupationDropdownName);
        }
      }

      if (controlName.includes("occupation-student")) {
        const additionalName = controlName.includes("additional") ? "-additional" : "";
        const occupationTypeControlName = "occupation-type" + additionalName;

        if (AppStore.getControlByName(occupationTypeControlName) !== "FTE") {
          AppStore.deleteFormData(controlName);
        }
      }

      if (controlName === "title") {
        if (controlValue === "Mr" || controlValue === "Dr (Male)") {
          AppStore.setFormData({
            gender: "Male",
          });
        } else {
          AppStore.setFormData({
            gender: "Female",
          });
        }
      }

      if (controlName === "title-additional") {
        if (controlValue === "Mr" || controlValue === "Dr (Male)") {
          AppStore.setFormData({
            "gender-additional": "Male",
          });
        } else {
          AppStore.setFormData({
            "gender-additional": "Female",
          });
        }
      }

      Object.keys(ControlLogic).forEach((module) => {
        try {
          if (ControlLogic[module].manipulateControls)
            ControlLogic[module].manipulateControls(controlName, controlValue);
        } catch (ex) {
          ClientLogging.logError(ex, `ControlLogic ${module} manipulateControls`);
        }
      });
    } catch (ex) {
      ClientLogging.logError(ex, "Manipulate control values");
    }
  },
  ManipulateControlValuesOnChange: (controlName, controlValue) => {
    try {
      if (
        controlName === "dvla-medical-boolean" ||
        controlName === "dvla-medical-boolean-additional"
      ) {
        const additionalSuffix = controlName.includes("additional") ? "-additional" : "";
        const medName = `dvla-medical${additionalSuffix}`;

        if (controlValue === "yes") {
          AppStore.deleteFormData([medName]);
        } else {
          AppStore.setFormData({ [medName]: "N" });
        }
      }
      if (controlName === "keeper-owner") {
        if (controlValue === "yes") {
          AppStore.setFormData({ "register-keeper": "PR" });
          AppStore.setFormData({ "legal-owner": "PR" });
        } else {
          AppStore.setVisibilityControls({
            "register-keeper": true,
          });
          AppStore.setVisibilityControls({
            "legal-owner": true,
          });
        }
      }
      if (controlName.includes("occupation-type")) {
        const additionalName = controlName.includes("additional") ? "-additional" : "";
        const occupationControlName = "occupation-abi-dropdown" + additionalName;
        const industryControlName = "industry-abi-dropdown" + additionalName;
        const occupationStudentControlName = "occupation-student" + additionalName;
        const secondOccupationControlName = "second-occupation" + additionalName;

        let studentValue = AppStore.getControlByName(occupationStudentControlName);

        if (controlValue === "E" || controlValue === "SE") {
          AppStore.setFormData({ [occupationControlName]: "" });
          AppStore.setFormData({ [industryControlName]: "" });
          AppStore.deleteFormData(occupationStudentControlName);
        } else if (controlValue === "U") {
          AppStore.setFormData({ [occupationControlName]: "U03" });
          AppStore.setFormData({ [industryControlName]: "186" });
          AppStore.deleteFormData(occupationStudentControlName);
          AppStore.setFormData({ [secondOccupationControlName]: "no" });
        } else if (controlValue === "FTE") {
          if (studentValue === undefined || studentValue === "")
            AppStore.setFormData({ [occupationControlName]: "U03" });
          else AppStore.setFormData({ [occupationControlName]: studentValue });
          AppStore.setFormData({ [industryControlName]: "323" });
        } else if (controlValue === "H") {
          if (AppStore.getControlByName("gender").includes("Female")) {
            AppStore.setFormData({ [occupationControlName]: "H09" });
            AppStore.setFormData({ [industryControlName]: "186" });
            AppStore.deleteFormData(occupationStudentControlName);
          } else {
            AppStore.setFormData({ [occupationControlName]: "163" });
            AppStore.setFormData({ [industryControlName]: "186" });
            AppStore.deleteFormData(occupationStudentControlName);
          }
          AppStore.setFormData({ [secondOccupationControlName]: "no" });
        } else if (controlValue === "R") {
          AppStore.setFormData({ [occupationControlName]: "R09" });
          AppStore.setFormData({ [industryControlName]: "186" });
          AppStore.deleteFormData(occupationStudentControlName);
          AppStore.setFormData({ [secondOccupationControlName]: "no" });
        }
      }

      if (controlName === "title") {
        if (controlValue === "Mr" || controlValue === "Dr (Male)") {
          AppStore.setFormData({
            gender: "Male",
          });
        } else {
          AppStore.setFormData({
            gender: "Female",
          });
        }
      }

      if (controlName === "title-additional") {
        if (controlValue === "Mr" || controlValue === "Dr (Male)") {
          AppStore.setFormData({
            "gender-additional": "Male",
          });
        } else {
          AppStore.setFormData({
            "gender-additional": "Female",
          });
        }
      }

      if (controlName.includes("uk-resident-since-radio")) {
        const additionalName = controlName.includes("additional") ? "-additional" : "";
        const residentSinceName = "uk-resident-since" + additionalName;
        const dobName = "date-of-birth" + additionalName;

        if (controlValue === "yes") {
          AppStore.setFormData({
            [residentSinceName]: AppStore.getControlByName(dobName),
          });
        }
      }

      if (controlName.includes("date-of-birth")) {
        const additionalName = controlName.includes("additional") ? "-additional" : "";
        const residentSinceName = "uk-resident-since" + additionalName;
        const residentSinceRadioName = "uk-resident-since-radio" + additionalName;

        if (AppStore.getControlByName(residentSinceRadioName) === "yes") {
          AppStore.setFormData({
            [residentSinceName]: AppStore.getControlByName(
              "date-of-birth" + additionalName
            ),
          });
        }

        if (
          !AppStore.getHasChanged(residentSinceName) &&
          !AppStore.getHasChanged(residentSinceRadioName)
        )
          AppStore.setFormData({
            [residentSinceName]: controlValue,
          });

        if (controlName === controlNames.dateOfBirth) {
          const age = DateParser.getDateDiffInYears(controlValue, dayjs());
          AppStore.deleteFormData(controlNames.hasCarLicenceBike);

          if (age <= 16) {
            carLicenceBike.updateCarLicenceControlsVisibility(false, false, false);
            carLicenceBike.setNoCarLicenceValues();
            AppStore.setPolicyCarToBeRevealed(false);
          } else {
            carLicenceBike.resetCarLicenceControlValues();
            AppStore.setPolicyCarToBeRevealed(true);
          }
        }
      }

      Object.keys(ControlLogic).forEach((module) => {
        try {
          if (ControlLogic[module].manipulateControlsOnChange)
            ControlLogic[module].manipulateControlsOnChange(controlName, controlValue);
        } catch (ex) {
          ClientLogging.logError(ex, `ControlLogic ${module} manipulateControlsOnChange`);
        }
      });
    } catch (ex) {
      ClientLogging.logError(ex, "Manipulate control values on change");
    }
  },
  HandleControlsPreRender: (controlName) => {
    try {
      if (
        controlName === "dvla-medical-boolean" ||
        controlName === "dvla-medical-boolean-additional"
      ) {
        const additionalSuffix = controlName.includes("additional") ? "-additional" : "";
        const medName = `dvla-medical${additionalSuffix}`;
        const medValue = AppStore.getControlByName(medName);
        if (isNullOrUndefined(medValue) || medValue === "") {
          AppStore.setFormData({ [medName]: "N" });
        }
      }

      if (controlName === "motoring-convictions-additional") {
        AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: false });
        AppStore.setVisibilityControls({ ["message-box-cross-sell-convictions"]: false });
      }
      if (controlName === "motoring-convictions") {
        const licenceDefenceOccupationOptInValue =
          AppStore.getLicenceDefenceOccupationOptInValue();
        if (
          licenceDefenceOccupationOptInValue === "yes" ||
          licenceDefenceOccupationOptInValue === "no"
        ) {
          AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: false });
          AppStore.setVisibilityControls({
            ["message-box-cross-sell-convictions"]: false,
          });
        } else {
          AppStore.setMessageBoxConvictionVisibility(true);
          AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: true });
          AppStore.setVisibilityControls({
            ["message-box-cross-sell-convictions"]: true,
          });
        }
      }

      Object.keys(ControlLogic).forEach((module) => {
        try {
          if (ControlLogic[module].manipulateControlsPreRender)
            ControlLogic[module].manipulateControlsPreRender(controlName);
        } catch (ex) {
          ClientLogging.logError(
            ex,
            `ControlLogic ${module} manipulateControlsPreRender`
          );
        }
      });
    } catch (ex) {
      ClientLogging.logError(ex, "Handle controls pre render method");
    }
  },
  HandleControlsPostRender: (controlName, controlValue) => {
    try {
      Object.keys(ControlLogic).forEach((module) => {
        try {
          if (ControlLogic[module].manipulateControlsPostRender) {
            ControlLogic[module].manipulateControlsPostRender(controlName, controlValue);
          }
        } catch (ex) {
          ClientLogging.logError(
            ex,
            `ControlLogic ${module} manipulateControlsPostRender`
          );
        }
      });
    } catch (ex) {
      ClientLogging.logError(ex, "HandleControlsPostRender method");
    }
  },
  HandleControlsPostDelete: (submitSender) => {
    try {
      Object.keys(ControlLogic).forEach((module) => {
        try {
          if (ControlLogic[module].manipulateControlsPostDelete) {
            ControlLogic[module].manipulateControlsPostDelete(submitSender);
          }
        } catch (ex) {
          ClientLogging.logError(
            ex,
            `ControlLogic ${module} manipulateControlsPostDelete`
          );
        }
      });
    } catch (ex) {
      ClientLogging.logError(ex, "HandleControlsPostDelete method");
    }
  },
  GetIsOptionDisabled: (controlName, controlValue, optionKey) => {
    try {
      let returnVal = false;

      Object.keys(ControlLogic).forEach((module) => {
        try {
          if (ControlLogic[module].getIsOptionDisabled) {
            returnVal = ControlLogic[module].getIsOptionDisabled(
              controlName,
              controlValue,
              optionKey
            );
          }
        } catch (ex) {
          ClientLogging.logError(ex, `ControlLogic ${module} getIsOptionDisabled`);
        }
      });
      return returnVal;
    } catch (ex) {
      ClientLogging.logError(ex, "GetIsOptionDisabled method");
    }
  },
  FocusOnFirstInvalidElement: (firstElementWithError) => {
    try {
      if (!isNullOrUndefined(firstElementWithError)) {
        let elementName = firstElementWithError.replace("validator-", "row-");
        let control = document.getElementById(elementName);

        let isDisabled = !isNullOrUndefined(control) ? control.disabled : false;
        let htmlFor = document.getElementById(elementName + "_yes");

        if (!isNullOrUndefined(htmlFor)) {
          htmlFor.classList.add("focused");
          control.focus();
          control.scrollIntoView();
        }
        if (isDisabled) {
          control.parentNode.focus();
          control.parentNode.scrollIntoView();
        }
        if (!isNullOrUndefined(control)) {
          control.focus();
          control.scrollIntoView();
        } else {
          let day = document.getElementById(elementName + "[day]");
          let month = document.getElementById(elementName + "[month]");
          if (!isNullOrUndefined(day)) {
            day.focus();
            day.scrollIntoView();
          } else if (!isNullOrUndefined(month)) {
            month.focus();
            month.scrollIntoView();
          }
        }
      }
    } catch (ex) {
      ClientLogging.logError(ex, "Focus on fisrt element : " + firstElementWithError);
    }
  },
  ScrollElementIntoView: (elementName) => {
    let element = document.getElementById(elementName);
    if (!isNullOrUndefined(element) && !isNullOrUndefined(element.scrollIntoView)) {
      element.scrollIntoView();
    }
  },
  ReplaceByRegex: (controlName, value, method = null) => {
    try {
      switch (controlName) {
        case "email":
        case "quote-reference-email":
          value = value.replace(/[`~!#$%^&*()|+=÷¿?;:",'<>\{}[\]\\/]/gi, "");
          break;
        case "mobile-phone":
        case "mobile-phone-unsubscribe":
          value = value.replace(
            /[A-Za-z`~!@#$%^&*£¬¦€Ұ_|\=÷¿?;:.'",<>\{\}\[\]\\\/\s]/gi,
            ""
          );
          break;
        case "cost-of-claim":
        case "cost-of-third-party-claim":
        case "vehicle-value":
        case "value-new":
        case "current-value":
        case "value-of-contents":
        case "value-of-equipment":
        case "amount-claimed":
          if (method === "keyDown") {
            value = value.replace(/[^0-9.]/, "");
          } else {
            value = value.match(/[0-9.]+/);
          }
          break;
        case "engine-cc":
          value = value.replace(
            /[A-Za-z`~!@#$%^&*()_|+\-=÷¿?;':",\.<>\{\}\[\]\\\/\s]/gi,
            ""
          );
          break;
        case "quote-reference":
        case "proposer-street-name":
        case "caravan-storage-street-name":
        case "proposer-town":
        case "caravan-storage-town":
        case "proposer-city":
        case "caravan-storage-city":
        case "mh-registration":
          value = value.replace(/[~!@#$%^&*()_|+\-=÷¿?;:",.<>\{\}\[\]\\\/]/gi, "");
          break;
        case "proposer-house-number-name":
        case "caravan-storage-house-number-name":
          value = value.replace(/[~!@#$%^*()_|+\-=÷¿?;:".<>\{\}\[\]\\\/]/gi, "");
          break;
        case "level-of-reading":
          value = value.replace(/[A-Za-z`~!@#$%^&*()_|+\-=÷¿?;:.",<>\{\}\[\]\\/]/gi, "");
          break;
        case "first-name":
        case "first-name-additional":
        case "surname":
        case "surname-additional":
          value = value
            .replace(/[0-9`~!@#$%^&*£¬¦€Ұ¥•()_|+\=÷¿?;:",.<>\{\}\[\]\\/]/gi, "")
            .replace(/( ){1,}/gi, " ");
          break;
        case "licence-number-input":
          value = value.replace(/[`~!@#$%^*()_|+\-=÷¿?;:",.'<>\{\}\[\]\\\/\ ]/gi, "");
          break;
        case "static-caravan-width":
          value = value.replace(/[A-Za-z`~!@#$€£%^&*()_|+\-=÷¿?;':",<>\{\}\[\]\\\/\s]/gi, '');
          break;
        case "static-caravan-length":
          value = value.replace(/[A-Za-z`~!@#$€£%^&*()_|+\-=÷¿?;':",<>\{\}\[\]\\\/\s]/gi, '');
          break;
        case "touring-caravan-serial-number":
        case "static-caravan-serial-number":
          //Replace all special characters and allow only numbers and letters
          value = value.replace(/[`~!@#$%^&*£¬¦€Ұ¥•()_|+\-=÷¿?;:",.'<>{}[\]\\/]/gi, "");
          break;
        default:
          value = value.replace(/[0-9`~!@#$%^&*()_|+\-=÷¿?;:",.<>\{\}\[\]\\/]/gi, "");
          break;
      }
      return value;
    } catch (ex) {
      ClientLogging.logError(ex, "Replace by regex : " + controlName + value);
    }
  },
  ReplaceChars: (allowedCharsRegex, value) => {
    let valueArray = Array.from(value);
    valueArray.forEach((char) => {
      if (!allowedCharsRegex.test(char)) {
        value = value.replace(char, "");
      }
    });
    return value;
  },
  GetQuoteSummaryValues: (controlName, controlValue, valueType) => {
    if (controlValue === undefined || controlValue === null) return;
    let modelDescription = !isNullOrUndefined(
      AppStore.getControlByLogicalName("vehicle-model-description")
    )
      ? AppStore.getControlByLogicalName("vehicle-model-description")
      : "";
    let modelDescriptionArray = [];
    if (modelDescription !== "") {
      modelDescriptionArray = modelDescription.split(",");
    }
    let type = valueType;
    if (!isNullOrUndefined(valueType) && valueType.split("|").length > 0)
      type = valueType.split("|")[0];
    if (controlName === "date-of-conviction") {
      return DateParser.getFriendlyDate(controlValue);
    }

    switch (type) {
      case "date":
        return DateParser.getFriendlyDate(controlValue);
      case "description":
        // returns the description based on code
        // e.g. for security devices if Code is I238
        // then it will return the devices name 'Squire Pin Disk Lo'
        let valueToCheck =
          typeof controlValue === "number" ? controlValue.toString() : controlValue;
        let controlNameToCheck = controlName;

        if (controlName === "security-devices" && controlValue === "yes") {
          controlNameToCheck = "security-devices-dropdown";
        } else if (controlName === "secure-markings" && controlValue === "yes") {
          controlNameToCheck = "markings-dropdown";
        } else if (controlName === "bike-organisation" && controlValue === "yes") {
          controlNameToCheck = "bike-organisation-dropdown";
        } else if (controlName === "riding-qualifications" && controlValue === "yes") {
          controlNameToCheck = "riding-qualifications-dropdown";
        } else if (
          controlName === "bike-organisation-additional" &&
          controlValue === "yes"
        ) {
          controlNameToCheck = "bike-organisation-dropdown-additional";
        } else if (
          controlName === "riding-qualifications-additional" &&
          controlValue === "yes"
        ) {
          controlNameToCheck = "riding-qualifications-dropdown-additional";
        } else if (controlName === "dvla-medical-boolean" && controlValue === "yes") {
          controlNameToCheck = "dvla-medical";
        } else if (
          controlName === "dvla-medical-boolean-additional" &&
          controlValue === "yes"
        ) {
          controlNameToCheck = "dvla-medical-additional";
        } else if (controlName === "postcode-overnight" && controlValue !== undefined) {
          return valueToCheck;
        }

        //gets the actual code based on control name
        if (controlNameToCheck !== controlName) {
          valueToCheck = AppStore.getControlByName(controlNameToCheck);
        }

        //returns the description based on code
        return AppStore.getDescriptionByCode(valueToCheck, controlNameToCheck);

      case "checkbox":
        let result = controlValue === true || controlValue === "true" ? "Yes" : "No";
        return result;
      case "model":
        return modelDescriptionArray ? modelDescriptionArray[0] : "";
      case "diffFromToday":
        let currentDateValue = dayjs().format("YYYY-MM-DD");
        let licenceYearsNumber = DateParser.getDateDiffInYears(
          controlValue,
          currentDateValue
        );
        let returnedValue =
          licenceYearsNumber === 0
            ? DateParser.getDateDiffInMonths(controlValue, currentDateValue) + " month(s)"
            : licenceYearsNumber + " year(s)";
        return returnedValue;
      case "engineCC":
        let engine = modelDescriptionArray ? modelDescriptionArray[1] : "";
        if (isNullOrUndefined(engine)) {
          return "";
        } else {
          let len = engine.length - 2;
          return engine.substring(0, len);
        }
      case "property-ownership":
        const valueToTest = valueType.split("|")[1];
        const customValueToDisplay = valueType.split("|")[2]
          ? valueType.split("|")[2]
          : "";
        if (controlValue === valueToTest) {
          return customValueToDisplay;
        } else {
          return DateParser.getFriendlyDate(controlValue);
        }
      case "pound":
        return (
          "£" +
          parseFloat(controlValue)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
      case "modifications":
        if (controlValue === "yes") {
          let value = toJS(AppStore.getControlByName("vehicle-modifications-list"));
          return value.map((v) => v.Modification).join(", ");
        }
        return "No";
      default:
        let defaultValueToReturn = controlValue.toString();
        return (
          defaultValueToReturn.charAt(0).toUpperCase() + defaultValueToReturn.slice(1)
        );
    }
  },
  SetCrossSellOccupationsVisibility: (controlVisibility) => {
    AppStore.setMessageBoxOccupationVisibility(controlVisibility);
    AppStore.setLicenceDefenceOccupationOptInVisibility(controlVisibility);
    AppStore.setVisibilityControls({
      ["message-box-cross-sell-occupations"]: controlVisibility,
    });
    AppStore.setVisibilityControls({ ["ld-occupations-opt-in"]: controlVisibility });
  },
  SetCrossSellConvictionsVisibility: (controlVisibility) => {
    AppStore.setMessageBoxConvictionVisibility(controlVisibility);
    AppStore.setLicenceDefenceConvictionOptInVisibility(controlVisibility);
    AppStore.setVisibilityControls({
      ["message-box-cross-sell-convictions"]: controlVisibility,
    });
    AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: controlVisibility });
  },
  SetCrossSellOccupationsOptInValue: (value) => {
    AppStore.setLicenceDefenceOccupationOptInValue(value);
    AppStore.setFormData({ ["ld-occupations-opt-in"]: value });
  },
  SetCrossSellOccupationsVisibilityBasedOnControlName: (
    controlName,
    value,
    userInput,
    suggestions
  ) => {
    if (controlName === "occupation-abi-dropdown") {
      AppStore.setSearchInputUserInput(userInput);
      let input = userInput === "" ? value : userInput;
      let foundOccupationValue = suggestions.find(
        (item) =>
          item.Key.toLowerCase() === input.toLowerCase() ||
          item.Value.toLowerCase() === input.toLowerCase()
      );
      if (foundOccupationValue) {
        let userSelectedOccupation = foundOccupationValue.Value;
        if (listOfLicenceDefenceOccupationsArray.includes(userSelectedOccupation)) {
          controlsLogicHelper.SetCrossSellOccupationsVisibility(true);
        } else {
          controlsLogicHelper.SetCrossSellOccupationsVisibility(false);
        }
      } else {
        controlsLogicHelper.SetCrossSellOccupationsVisibility(false);
      }
      if (
        AppStore.getLicenceDefenceOccupationOptInVisibility() &&
        AppStore.getLicenceDefenceOccupationOptInValue() === ""
      ) {
        controlsLogicHelper.SetCrossSellOccupationsOptInValue("");
      }
    }
  },
  SetCrossSellConvictionsVisibilityBasedOnControlName: (controlName) => {
    if (controlName === "occupation-type-additional") {
      controlsLogicHelper.SetCrossSellConvictionsVisibility(false);
    } else {
      controlsLogicHelper.SetCrossSellConvictionsVisibility(true);
    }
  },
  SetCrossSellOccupationsVisibility: (controlVisibility) => {
    AppStore.setMessageBoxOccupationVisibility(controlVisibility);
    AppStore.setLicenceDefenceOccupationOptInVisibility(controlVisibility);
    AppStore.setVisibilityControls({
      ["message-box-cross-sell-occupations"]: controlVisibility,
    });
    AppStore.setVisibilityControls({ ["ld-occupations-opt-in"]: controlVisibility });
  },
  SetCrossSellConvictionsVisibility: (controlVisibility) => {
    AppStore.setMessageBoxConvictionVisibility(controlVisibility);
    AppStore.setLicenceDefenceConvictionOptInVisibility(controlVisibility);
    AppStore.setVisibilityControls({
      ["message-box-cross-sell-convictions"]: controlVisibility,
    });
    AppStore.setVisibilityControls({ ["ld-convictions-opt-in"]: controlVisibility });
  },
  SetCrossSellOccupationsOptInValue: (value) => {
    AppStore.setLicenceDefenceOccupationOptInValue(value);
    AppStore.setFormData({ ["ld-occupations-opt-in"]: value });
  },
  SetCrossSellOccupationsVisibilityBasedOnControlName: (
    controlName,
    value,
    userInput,
    suggestions
  ) => {
    if (controlName === "occupation-abi-dropdown") {
      AppStore.setSearchInputUserInput(userInput);
      let input = userInput === "" ? value : userInput;
      let foundOccupationValue = suggestions.find(
        (item) =>
          item.Key.toLowerCase() === input.toLowerCase() ||
          item.Value.toLowerCase() === input.toLowerCase()
      );
      if (foundOccupationValue) {
        let userSelectedOccupation = foundOccupationValue.Value;
        if (listOfLicenceDefenceOccupationsArray.includes(userSelectedOccupation)) {
          controlsLogicHelper.SetCrossSellOccupationsVisibility(true);
        } else {
          controlsLogicHelper.SetCrossSellOccupationsVisibility(false);
          controlsLogicHelper.SetCrossSellConvictionsVisibility(true);
        }
      } else {
        controlsLogicHelper.SetCrossSellOccupationsVisibility(false);
      }
      if (
        AppStore.getLicenceDefenceOccupationOptInVisibility() &&
        AppStore.getLicenceDefenceOccupationOptInValue() === ""
      ) {
        controlsLogicHelper.SetCrossSellOccupationsOptInValue("");
      }
    }
  },
  SetCrossSellConvictionsVisibilityBasedOnControlName: (controlName) => {
    if (controlName === "occupation-type-additional") {
      controlsLogicHelper.SetCrossSellConvictionsVisibility(false);
    } else {
      controlsLogicHelper.SetCrossSellConvictionsVisibility(true);
    }
  },
  HasCrossSellOccupationsOptInValue: () => {
    const crossSellOccupationsOptInValue =
      AppStore.getLicenceDefenceOccupationOptInValue();
    if (
      crossSellOccupationsOptInValue === "yes" ||
      crossSellOccupationsOptInValue === "no"
    )
      return true;
    else return false;
  },
  ResetCrossSellOccupationsOptInValue: () => {
    AppStore.setFormData({ ["ld-occupations-opt-in"]: null });
    AppStore.setLicenceDefenceOccupationOptInValue(null);
  },
  ResetLicenceDefense: () => {
    AppStore.setLicenceDefenceOccupationOptInValue(null);
    AppStore.setFormData({ ["ld-occupations-opt-in"]: null });
    AppStore.setLicenceDefenceConvictionOptInValue(null);
    AppStore.setFormData({ ["ld-convictions-opt-in"]: null });
  },
  ResetClaimsAndConvictions(data, accidentKey, convictionKey) {
    if (data[accidentKey] === "no") {
      delete data[accidentKey];
      AppStore.deleteFormData(accidentKey);
    }
    if (data[convictionKey] === "no") {
      delete data[convictionKey];
      AppStore.deleteFormData(convictionKey);
    }
  },
  ResetClaimsAndConvictionsRadioValues(collectedQuoteData) {
    const isQuoteRecalled = AppStore.getIsQuoteRecalled();
    const currentPageName = AppStore.getCurrentPageName();
    const hasUserSelectedClaimsConvictionsValue =
      AppStore.getHasUserSelectedClaimsConvictionsValue();
    const hasUserSelectedAdditionalClaimsConvictionsValue =
      AppStore.getHasUserSelectedAdditionalClaimsConvictionsValue();

    let tempCollectedQuoteData;
    try {
      tempCollectedQuoteData = JSON.parse(collectedQuoteData);
    } catch (error) {
      return collectedQuoteData;
    }

    const shouldResetClaimsAndConvictions =
      tempCollectedQuoteData &&
      isQuoteRecalled &&
      typeof tempCollectedQuoteData === "object";

    if (shouldResetClaimsAndConvictions) {
      if (!hasUserSelectedClaimsConvictionsValue && currentPageName === "About You") {
        controlsLogicHelper.ResetClaimsAndConvictions(
          tempCollectedQuoteData,
          "accident-claims-radio",
          "motoring-convictions-radio"
        );
      } else if (
        !hasUserSelectedAdditionalClaimsConvictionsValue &&
        currentPageName === "Rider Details"
      ) {
        controlsLogicHelper.ResetClaimsAndConvictions(
          tempCollectedQuoteData,
          "accident-claims-radio-additional",
          "motoring-convictions-radio-additional"
        );
      } else {
        return collectedQuoteData;
      }

      return JSON.stringify(tempCollectedQuoteData);
    }

    return collectedQuoteData;
  },
};

export default controlsLogicHelper;
