import React from "react";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";
import AppLink from "../AppLink";

const NavBar = ({ pages }) => {
  const pagesToDisplay = pages.filter((p) => p.showInNavigation === true);
  const page = pages.find(
    (p) =>
      p.url.toLowerCase() ===
      window.location.pathname
        .replace(`${import.meta.env.VITE_SITE_ROUTE}/`, "")
        .toLowerCase()
  );
  const currentPage = page || { orderNo: 1 };

  const additionalDriverClickable = () => {
    const settings = AppStore.getUserJourneySettings();
    const setting = settings?.navigation?.additionalDriverClickable === "true" || false;
    const existsAddDriver =
      AppStore.getCurrentDriver() > 0 ||
      AppStore.getControlByName("joint-policy") === "IN";

    return setting && existsAddDriver;
  };

  const navResultsTitle = () => {
    const result = pages.filter((p) => p.pageSettings.wireframeType === "results");

    if (!isNullOrUndefined(result) && result.length === 1) {
      return result[0].title;
    }

    return "Your Quotes";
  };

  let conditionalPagesToDisplay = [];

  pagesToDisplay.forEach((p) => {
    const showInNavigationConditional = p?.pageSettings?.showInNavigationConditional;

    if (isNullOrUndefined(showInNavigationConditional)) {
      conditionalPagesToDisplay.push(p);
    } else {
      const [conditionalControl, conditionalValues] =
        showInNavigationConditional.split("|");
      const formDataConditionalValue = AppStore.getControlByName(conditionalControl);

      if (
        !isNullOrUndefined(formDataConditionalValue) &&
        conditionalValues.split(",").includes(formDataConditionalValue)
      ) {
        conditionalPagesToDisplay.push(p);
      }
    }
  });

  const renderListItem = (p, index) => {
    let cssClases = "nav-link";
    let toLink = "";
    let tabIndex = "0";

    if (p.orderNo === currentPage.orderNo) {
      cssClases = "nav-link active";
      toLink = p.url;
    } else if (p.orderNo <= currentPage.orderNo) {
      if (
        !additionalDriverClickable() &&
        (p.title.replace(/\s+/g, "").toLowerCase() === "additionaldriverdetails" ||
          p.title.replace(/\s+/g, "").toLowerCase() === "additionalriderinformation")
      ) {
        cssClases = "nav-link visited disabled";
        toLink = "";
      } else {
        cssClases = "nav-link visited";
        toLink = p.url;
      }
    } else {
      cssClases = "nav-link disabled";
      toLink = "";
      tabIndex = "-1";
    }

    return (
      <li
        key={p.url}
        className={`nav-item nav-item-${p.title
          .replace(/\s+/g, "")
          .toLowerCase()} col-lg-2 col-md-2 col-sm-2 col-2 ${
          p.orderNo === currentPage.orderNo ? "active" : ""
        }`}>
        <AppLink className={`${cssClases} `} to={toLink} tabIndex={tabIndex}>
          <span
            className={`nav-link-no col-lg-2 col-md-2 col-sm-2 col-2 d-xl-inline-block d-lg-inline-block d-md-inline-block d-none`}>
            {index + 1}
          </span>
          <span
            className={`nav-link-text col-lg-10 col-md-10 col-sm-10 col-10 d-xl-inline-block d-lg-inline-block d-md-inline-block d-none`}>
            {p.title}
          </span>
        </AppLink>
      </li>
    );
  };

  return (
    <div data-testid="nav-bar" className="nav-container container">
      <nav className="navbar navbar-light col-lg-12 col-md-12 col-sm-12 col-12">
        <ul className="nav nav-pills col-lg-12 col-md-12 col-sm-12 col-12">
          {pagesToDisplay.map((p, index) => {
            return renderListItem(p, index);
          })}
          <li
            key={`results`}
            className={`nav-item nav-item-results col-lg-2 col-md-2 col-sm-2 col-2`}>
            <AppLink className={`nav-link disabled`} to={""}>
              <span
                className={`nav-link-no col-lg-4 col-md-4 col-sm-4 col-4 d-xl-inline-block d-lg-inline-block d-md-inline-block d-none`}></span>
              <span
                className={`nav-link-text col-lg-8 col-md-8 col-sm-8 col-8 d-xl-inline-block d-lg-inline-block d-md-inline-block d-none`}>
                {navResultsTitle()}
              </span>
            </AppLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
