import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import { controlNames } from "../../constants";
import AppStore from "../../store/AppStore";

function manipulateControls(controlName, controlValue) {}

function manipulateControlsOnChange(controlName, controlValue) {
  if (controlName === controlNames.addressIsManualName) {
    const addressIsManual = AppStore.getAddressState().addressIsManual;
    if (addressIsManual === true) {
      //was clicked when manual inputs were visible
      AppStore.setFormData({
        [controlName]: false,
      });
      AppStore.deleteFormData(controlNames.postcodeDropdownControlName);
      AppStore.setFormData({
        [controlNames.postcodeName]: "",
      });
      AppStore.setVisibilityControls({
        [controlNames.postcodeName]: true,
      });
      AppStore.setAddressState({ addressIsManual: false });

      [
        controlNames.postcodeManualControlName,
        controlNames.houseNumberManualControlName,
        controlNames.streetControlName,
        controlNames.townControlName,
        controlNames.cityControlName,
      ].forEach((c) => {
        AppStore.setVisibilityControls({
          [c]: false,
        });
        AppStore.deleteFormData(c);
      });
    } else if (
      isNullOrUndefined(controlValue) ||
      controlValue === false ||
      controlValue === ""
    ) {
      //was clicked when manual inputs were not shown or was retrieved from v1 site
      AppStore.setAddressState({ addressIsManual: true });
    }
  }

  if (controlName === controlNames.postcodeManualControlName) {
    AppStore.setFormData({
      [controlNames.postcodeName]: controlValue,
    });
  }
}

function manipulateControlsPreRender(controlName) {}

export default {
  manipulateControls,
  manipulateControlsOnChange,
  manipulateControlsPreRender,
};
