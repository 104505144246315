import { action, makeObservable, observable, when } from "mobx";

const ScriptStatus = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  ERROR: "ERROR",
  DONE: "DONE",
};

class VistatsProvider {
  vistatsStatus = ScriptStatus.INITIAL;
  queue = [];

  constructor() {
    makeObservable(this, {
      vistatsStatus: observable,
      setStatus: action,
    });
  }

  setup() {
    const productKey = import.meta.env.VITE_VISTATS_PRODUCT_KEY;
    const endpoint = import.meta.env.VITE_VISTATS_API_ENDPOINT;

    window.vv.vst.setup(endpoint, productKey);
    window.vv.vst.enableRecommended();
  }

  setStatus(status) {
    this.vistatsStatus = ScriptStatus[status];
  }

  reportInfo(name, value = "unknown", section = "Page", concept = false, type = "Text") {
    function report() {
      window.vv.vst.reportInfo(name, value, {
        section: section,
        concept: concept,
        type:
          window.vv.vst.PageUnitValueType[type] || window.vv.vst.PageUnitValueType.Text,
      });
    }
    if (this.vistatsStatus === ScriptStatus.DONE) {
      report();
    } else {
      this.queue.push(report);
    }
  }

  refreshPageVisit() {
    function report() {
      window.vv.vst.refreshPageVisit();
    }
    if (this.vistatsStatus === ScriptStatus.DONE) {
      report();
    } else {
      this.queue.push(report);
    }
  }
}

const vistatsProvider = new VistatsProvider();
when(
  () => vistatsProvider.vistatsStatus === ScriptStatus.DONE,
  () => {
    while (vistatsProvider.queue.length > 0) {
      vistatsProvider.queue.shift()();
    }
  }
);

export { ScriptStatus, vistatsProvider };
