const loadAsync = (key) => {
  return new Promise((res, rej) => {
      import(
      /* webpackChunkName: "UserJourneyComponents" */
      /* webpackPrefetch: true */
      `../jsonsource/${key}_components.js`
    ).then((data) => {
      res(data?.default);
    });
  });
};

export const loadPageContentAsync = (key) => {
  return new Promise((res, rej) => {
      import(
      /* webpackChunkName: "PageContent" */
      /* webpackPrefetch: false */
      `../jsonsource/${key}_pagecontent.js`
    ).then((data) => {
      res(data?.default);
    });
  });
};

export default loadAsync;