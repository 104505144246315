/// source: https://stackoverflow.com/questions/61464342/how-to-lazy-load-multiple-components-at-a-same-time

import { lazy } from "react";

const multiLazy = (moduleLoaderArray) => {
  const promises = Promise.all(moduleLoaderArray.map((loader) => loader()));
  return moduleLoaderArray.map((m, index) =>
    lazy(() => promises.then((results) => results[index]))
  );
};

export default multiLazy;
