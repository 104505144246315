import { observer } from "mobx-react";
import React from "react";
import AppStore from "../../store/AppStore";

const Submit = observer(
  ({ type, label, name, values, attributes, reverseButtonLabels, formtarget }) => {
    let separator = name.lastIndexOf("-");
    let wrapperClass = name.substring(0, separator);

    return (
      <div
        className={`form-group button-wrapper wrapper-${wrapperClass}`}
        onClick={() => {
          AppStore.setActiveElementName(name);
        }}>
        <button
          form={formtarget}
          type={type}
          data-testid={`test-${name}`}
          className={`btn btn-primary ${name} col-lg-6 col-md-10 col-sm-2`}
          name={name}
          id={name}
          redirectto={values}
          disabled={AppStore.disableContinue}>
          <span
            id={`${name}-spinner`}
            className={"spinner-border d-none"}
            role="status"
            aria-hidden="true"></span>

          {!reverseButtonLabels && <span id={`${name}-label`}>{label}</span>}

          {attributes?.icon && (
            <i
              id={`${name}-icon`}
              className={`fa fa-${attributes.icon} ml-2`}
              redirectto={values}
              onClick={(ev) => {
                ev.preventDefault();
              }}></i>
          )}

          {reverseButtonLabels && (
            <span id={`${name}-label`} className="col-lg-6 col-md-10 col-sm-8">
              {label}
            </span>
          )}
        </button>
      </div>
    );
  }
);

export default Submit;
