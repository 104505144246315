import axios from "axios";
import ClientLogging from "../providers/loggingProvider";
import session from "../services/sessionService";

axios.interceptors.request.use(
  (config) => {
    config.headers["token"] = session.load() !== undefined ? session.load() : "";
    return config;
  },
  (error) => {
    ClientLogging.logError(error, "http service");
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
};
