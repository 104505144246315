import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import Button from "./SimpleButton.Component";

/** Base user prompt modal */
const PromptPopup = ({
  isOpen,
  handleClose,
  hasCloseBtn = true,
  closeOnEsc = false,
  closeOnOutsideClick = false,
  id,
  dataTestId,
  title,
  text,
  actionBtn = {},
  cancelBtn = {},
  customClassMapping = {},
}) => {
  const containerRef = useRef();
  const mainRef = useRef();

  useOutsideClick({
    enabled: closeOnOutsideClick && isOpen,
    ref: mainRef,
    handler: handleClose,
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        containerRef.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  const handleKeyDown = (ev) => {
    if (closeOnEsc && ev.key === "Escape") {
      handleClose();
    }
  };

  const classes = {
    container: "promptpopup-container overlay",
    main: "promptpopup-main",
    head: "promptpopup-head",
    closeBtn: "promptpopup-btn-close close",
    closeBtnIcon: "promptpopup-btn-close-icon",
    content: "content",
    title: "promptpopup-title",
    text: "promptpopup-text",
    footer: "promptpopup-footer",
    actionBtn: "promptpopup-btn action",
    cancelBtn: "promptpopup-btn cancel",
    ...customClassMapping,
  };

  return isOpen ? (
    <div
      id={id}
      data-testid={dataTestId}
      className={`${classes.container} d-flex flex-column align-items-center`}
      ref={containerRef}
      tabIndex={-1}
      onKeyDown={handleKeyDown}>
      <div ref={mainRef} className={classes.main}>
        <div className={`${classes.head} d-flex`}>
          {hasCloseBtn && (
            <button
              type="button"
              className={`${classes.closeBtn} p-2`}
              onClick={handleClose}>
              <span
                aria-hidden="true"
                className={`${classes.closeBtnIcon} far fa-times-circle`}></span>
            </button>
          )}
        </div>
        <div className="d-flex flex-column align-items-center p-0">
          <div
            className={`${classes.content} flex-column align-items-center text-center`}>
            <label className={`${classes.title}`}>{title}</label>
            <label className={`${classes.text}`}>{text}</label>
          </div>
        </div>
        <div className={`${classes.footer} d-flex`}>
          <Button
            type="button"
            id={actionBtn.id}
            name={actionBtn.name}
            label={actionBtn.label}
            className={`${classes.actionBtn} mb-4`}
            icon={actionBtn.icon}
            onClick={actionBtn.onClick}
          />
          <Button
            type="button"
            id={cancelBtn.id}
            name={cancelBtn.name}
            label={cancelBtn.label}
            className={`${classes.cancelBtn} mb-4`}
            icon={cancelBtn.icom}
            onClick={cancelBtn.onClick}
          />
        </div>
      </div>
    </div>
  ) : null;
};

PromptPopup.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  hasCloseBtn: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataTestId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  text: PropTypes.string,
  actionBtn: PropTypes.object,
  cancelBtn: PropTypes.object,
  customClassMapping: PropTypes.object,
};

export default PromptPopup;
