import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import React from "react";
import AppStore from "../../../store/AppStore";

const Notification = ({
  id,
  name,
  label,
  type,
  placeholder,
  required,
  handleBlur,
  currentValue,
  helpMessages,
  infoMessages,
  validation,
}) => {
  let isAddDriver = AppStore.getIsAddDriver();
  let displayCssClass = "";
  let firstNameAdd = AppStore.getControlByName("first-name-additional");
  let surnameAdd = AppStore.getControlByName("surname-additional");

  if (name == "claims-add-driver-name" || name === "conviction-add-driver-name") {
    displayCssClass = isAddDriver ? "" : "d-none";
    if (isAddDriver) {
      if (firstNameAdd !== "" && surnameAdd !== "") {
        label = `For additional driver: ${firstNameAdd} ${surnameAdd}`;
      }
    }
  }

  return (
    <div
      className={`form-group notice col-11 pl-0 notice-${name} mx-auto ${displayCssClass}`}>
      <label
        className="notice-label"
        key={name}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(label, {
            USE_PROFILES: { html: true },
            ADD_TAGS: ["a", "p", "div", "span"],
            ADD_ATTR: ["href", "target", "name", "class"],
          }),
        }}></label>
    </div>
  );
};

Notification.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Notification;
