import { cookies } from "../helpers/cookies";
import ClientLogging from "../providers/loggingProvider";

export default {
  update: (data) => {
    try {
      cookies.set("vast-abtesting", data, {
        secure: true,
        path: "/",
        maxAge: 2592000,
      }); // 7 days max age
    } catch (ex) {
      ClientLogging.logError(ex, "Session Service - Update Cookie Token: ");
    }
  },
};
