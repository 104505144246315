import DOMPurify from "dompurify";
import { toJS } from "mobx";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import accessibilityHelper from "../../../helpers/accessibilityHelper";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import CheckboxLabel from "../CheckboxLabel.Component";
import Error from "../StaticControls/Error.Component";

const Optin = ({
  id,
  name,
  label,
  defaultValue,
  helpMessages,
  infoMessages,
  handleCheckboxChange,
  handleChange,
  handleInteraction,
  validation,
  validateControl,
  controlValuesList,
  attributes,
}) => {
  const errorClass = validation && validation.isValid === false ? "error" : "";

  const labelRef = useRef(null);

  let receiveTheLatestNewsErrorClass;

  const showErrorMessage =
    !isNullOrUndefined(AppStore.getUserJourneySettings().optin) &&
    !isNullOrUndefined(AppStore.getUserJourneySettings().optin.showErrorMessage)
      ? AppStore.getUserJourneySettings().optin.showErrorMessage
      : false;

  let proposerEmailControlName =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.proposerEmail)
      ? attributes.proposerEmail
      : "";
  let proposerPhoneControlName =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.proposerPhone)
      ? attributes.proposerPhone
      : "";
  let termsUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.termsUrl)
      ? `${import.meta.env.VITE_SITE_ROUTE}/${attributes.termsUrl}`
      : "";
  let privacyUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.privacyUrl)
      ? `${import.meta.env.VITE_SITE_ROUTE}/${attributes.privacyUrl}`
      : "";
  let assumptionsUrl =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.assumptionsUrl)
      ? `${import.meta.env.VITE_SITE_ROUTE}/${attributes.assumptionsUrl}`
      : "";
  let termsText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.termsText)
      ? attributes.termsText
      : "";
  let privacyText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.privacyText)
      ? attributes.privacyText
      : "";
  let assumptionsText =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.assumptionsText)
      ? attributes.assumptionsText
      : "";
  let checkboxDisabled =
    !isNullOrUndefined(attributes) && !isNullOrUndefined(attributes.checkboxDisabled)
      ? attributes.checkboxDisabled
      : false;

  const version =
    !isNullOrUndefined(attributes) &&
    !isNullOrUndefined(attributes.version) &&
    attributes.version;

  const terms = '<a href="%%TERMSURL%%" target="_blank">%%TERMSTEXT%%</a>'
    .replace("%%TERMSURL%%", termsUrl)
    .replace("%%TERMSTEXT%%", termsText);

  const privacy = '<a href="%%PRIVACYURL%%" target="_blank">%%PRIVACYTEXT%%</a>'
    .replace("%%PRIVACYURL%%", privacyUrl)
    .replace("%%PRIVACYTEXT%%", privacyText);

  const assumptions = '<a href="%%ASSUMPTIONURL%%" target="_blank">%%ASSUMPTIONTEXT%%</a>'
    .replace("%%ASSUMPTIONURL%%", assumptionsUrl)
    .replace("%%ASSUMPTIONTEXT%%", assumptionsText);

  const [OptInChecks, setOptInChecks] = useState([]);

  useEffect(() => {
    let unsubList = AppStore.getDataSourceByControlName(name);
    AppStore.setUnsubscribeData({ ["unsubscriptionList"]: unsubList });

    AppStore.setFormData({ ["opt-in"]: false });
  }, []);

  useEffect(() => {
    AppStore.setUnsubscribeData({
      ["proposerPhone"]: AppStore.getControlByName(proposerPhoneControlName),
    });

    AppStore.setUnsubscribeData({
      ["proposerEmail"]: AppStore.getControlByName(proposerEmailControlName),
    });
  });

  const changeOptInState = (controlName, checked) => {
    if (OptInChecks.length == 0) {
      setOptInChecks([{ controlName: controlName, checked: checked }]);
    } else {
      let optinStates = [...OptInChecks];
      let index = optinStates.findIndex((optin) => optin.controlName === controlName);

      if (index === -1) optinStates.push({ controlName: controlName, checked: checked });
      else optinStates[index] = { ...optinStates[index], checked: checked };

      setOptInChecks(optinStates);
    }
  };

  const getOptInState = (controlName) => {
    if (OptInChecks.length === 0) return false;

    return OptInChecks.some(
      (optIn) => optIn.controlName === controlName && optIn.checked === true
    );
  };

  const updateAppStoreUnsubscribeData = (itemIndex, checked) => {
    //populate data for unsubcribe
    let unsubscribeData = AppStore.getUnsubscribeData();

    let unsubscriptionList = toJS(unsubscribeData.get("unsubscriptionList"));

    if (!isNullOrUndefined(unsubscriptionList) && unsubscriptionList !== "") {
      let newUnsubOptions = [...unsubscriptionList];

      newUnsubOptions[itemIndex] = {
        ...newUnsubOptions[itemIndex],
        Ticked: checked,
      };

      AppStore.setUnsubscribeData({ ["unsubscriptionList"]: newUnsubOptions });

      let requiredChecks = newUnsubOptions.filter(
        (unsub) => unsub.ShouldValidate === true
      );

      let optinValidation = requiredChecks.every((e) => e.Ticked === true);
      handleCheckboxChange(name, optinValidation);
    }
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  let errorComponent = showErrorMessage ? (
    <div className="error-message-container col-lg-4 col-md-6 col-sm-12 col-12 next-line">
      <div className={`${receiveTheLatestNewsErrorClass} ${errorClass}`}>
        {validation && validation.isValid === false ? validation.messages[0] : null}
      </div>
    </div>
  ) : (
    ""
  );

  if (AppStore.liveValidation) {
    errorComponent = <Error key={`errorkey-${name}`} validation={validation} />;
  }

  return !isNullOrUndefined(controlValuesList) ? (
    controlValuesList.map((item, itemIndex) => {
      const text = item.Value.split("|").pop();

      let optInName = name + "-" + item.Key;
      let optInIndex = itemIndex;
      //Refactor this to remove key dependency for higlighting validation errors
      let optin2ndOptionIndex = ["82", "47", "118", "124"];

      receiveTheLatestNewsErrorClass = optin2ndOptionIndex.includes(item.Key)
        ? ""
        : errorClass;

      let optContent = text
        .replace("%%TERMS%%", terms)
        .replace("%%ASSUMPTIONS%%", assumptions)
        .replace("%%POLICY%%", privacy);

      if (!optContent.includes(termsUrl))
        optContent = optContent.replace(/termsconditions/g, termsUrl);

      if (!optContent.includes(privacyUrl))
        optContent = optContent.replace(/privacypolicy/g, privacyUrl);

      if (
        !isNullOrUndefined(attributes.assumptionsUrl) &&
        !optContent.includes(assumptionsUrl)
      )
        optContent = optContent.replace(
          /termsconditions#assumptions/g,
          attributes.assumptionsUrl
        );

      const changeHandler = (event) => {
        changeOptInState(optInName, event.target.checked);
        updateAppStoreUnsubscribeData(itemIndex, event.target.checked);
        handleInteraction(event);
      };

      const checkboxKeyDownHandler = (event) => {
        event.target.checked = !event.target.checked;

        accessibilityHelper.executeCallbackFunction(event, changeHandler, event);
      };

      const inputComponent = (
        <Fragment key={`inputComponent-${optInName}}-${optInIndex}`}>
          {!checkboxDisabled && (
            <input
              id={optInName}
              data-testid={`test-${optInName}`}
              className="form-check-input"
              type="checkbox"
              name={optInName}
              onChange={changeHandler}
              onKeyDown={checkboxKeyDownHandler}
              checked={getOptInState(optInName)}
            />
          )}
        </Fragment>
      );

      const controlComponent = (
        <Fragment key={`controlComponent-${optInName}-${optInIndex}`}>
          <div
            className="label-group col-lg-11 col-md-11 col-sm-11 col-10"
            key={`label-group-${optInName}-${optInIndex}`}>
            <div
              id={`${optInName}-label`}
              className={`qs-label qs-label-${name} d-inline-block col-lg-12 col-md-12 col-sm-12 col-12`}>
              <label
                data-testid={`${optInName}-label`}
                htmlFor={optInName}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(optContent, {
                    USE_PROFILES: { html: true },
                    ADD_TAGS: ["a", "p", "div", "span"],
                    ADD_ATTR: ["href", "target", "name", "class"],
                  }),
                }}></label>
            </div>
          </div>

          <div
            className="form-group col-lg-1 col-md-1 col-sm-1 col-1"
            key={`form-group-${optInName}-${optInIndex}`}>
            <div className="form-check form-checkbox form-check-inline">
              {inputComponent}
              <CheckboxLabel
                parentId={optInName}
                parentName={optInName}
                version={version}
                changeHandler={changeHandler}
                keyDownHandler={checkboxKeyDownHandler}
                ref={labelRef}
              />
            </div>
          </div>
        </Fragment>
      );

      const componentMapping = {
        label: "",
        tooltip: "",
        info: "",
        error: errorComponent,
        control: controlComponent,
      };

      return (
        <div
          className={`form-group form-group-container form-group-${optInName}`}
          key={`optin-option-${optInName}-${optInIndex}`}>
          <div
            id={`row-${optInName}`}
            className={`row row-${optInName} row-${receiveTheLatestNewsErrorClass}`}>
            {version === 3 ? (
              <ControlRowFactory
                controlName={name}
                groupClasses={groupClasses}
                componentMappings={componentMapping}
              />
            ) : (
              controlComponent
            )}
            {version !== 3 && errorComponent}
          </div>
        </div>
      );
    })
  ) : (
    <></>
  );
};

Optin.propTypes = {
  id: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  helpMessages: PropTypes.any,
  infoMessages: PropTypes.any,
  handleCheckboxChange: PropTypes.any,
  handleChange: PropTypes.any,
  handleInteraction: PropTypes.any,
  validation: PropTypes.any,
  validateControl: PropTypes.any,
  controlValuesList: PropTypes.any,
  attributes: PropTypes.any,
};

export default Optin;
