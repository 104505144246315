import React from "react";

const DfpBg = () => {
  return (
    <React.Fragment>
      <div className="dfp-background-container">
        <div className="dfp-unit-wrapper dfp-background">
          <div className="dfp-bg">
            <div className="dfp-left-background"></div>
            <div className="dfp-right-background"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DfpBg;
