import { resolveStaticAssetPath } from "@helpers/staticAsset";
import React from "react";
import { ReactSVG } from "react-svg";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";

class SVGIcon extends React.Component {
  render() {
    const inheritCSS = !isNullOrUndefined(this.props.className)
      ? this.props.className
      : "";
    const svgName = !isNullOrUndefined(this.props.svgName) ? this.props.svgName : "";
    const svgUrl = !isNullOrUndefined(this.props.svgUrl) ? this.props.svgUrl : "";

    return (
      <React.Fragment>
        {svgName !== "" && svgUrl !== "" && (
          <div className="focus-holder">
            <ReactSVG
              src={resolveStaticAssetPath(`${svgUrl}/${svgName}.svg`)}
              beforeInjection={(svg) => {
                svg.classList.add("svg-class-name");
              }}
              evalScripts="always"
              fallback={() => <span></span>}
              renumerateIRIElements={false}
              wrapper="span"
              className={inheritCSS}
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SVGIcon;
