import { HashLink } from "react-router-hash-link";

export const GoCoCookieBanner = ({
  message,
  privacyPolicyLinkText,
  policyLink,
  onConfirm,
  declineText,
  declineButtonText,
  onDecline,
  closeButton,
}) => (
  <div className="react-cookie-law-dialog">
    <div className="react-cookie-law-container container">
      <p className="cookie-text">
        {`${declineText} `}
        <a
          id="cookieConsentTrue"
          className="btn btn-link react-cookie-law-policy p-0"
          onClick={() => onDecline()}>
          {declineButtonText}
        </a>
        {"."}
        {` ${message}`}
        <HashLink
          key={`cookie-banner-policy-link`}
          smooth
          to={policyLink}
          id="cookieConsentTrue"
          className="btn btn-link react-cookie-law-policy p-0"
          target="_blank"
          onClick={() => onConfirm()}>
          {privacyPolicyLinkText}
        </HashLink>
        {"."}
      </p>
    </div>
    <div className="react-cookie-law-button-wrapper">{closeButton}</div>
  </div>
);
