import React from "react";
import { ScriptStatus, vistatsProvider } from "../providers/VistatsProvider";
import ClientLogging from "../providers/loggingProvider";

class Vistats extends React.Component {
  constructor(props) {
    super(props);
  }

  loadScript() {
    if (import.meta.env.VITE_VISTATS_V2_ENABLED == "true") {
      addScriptTag("https://api.visibilitystats.com/vst.js")
        .then(() => {
          vistatsProvider.setup();
          vistatsProvider.setStatus(ScriptStatus.DONE);
        })
        .catch((ex) => {
          vistatsProvider.setStatus(ScriptStatus.ERROR);
          ClientLogging.logError(ex, "Vistats script failed to load");
        });
    }
  }

  render() {
    return null;
  }

  componentDidMount() {
    if (vistatsProvider.vistatsStatus === ScriptStatus.INITIAL) {
      vistatsProvider.setStatus(ScriptStatus.LOADING);
      this.loadScript();
    }
  }
}

function addScriptTag(src) {
  return new Promise(function (resolve, reject) {
    var script = document.createElement("script");
    script.src = src;
    script.addEventListener("load", function () {
      resolve(`${src} script loaded successfully.`);
    });
    script.addEventListener("error", function (e) {
      reject(e);
    });
    document.head.appendChild(script);
  });
}

export default Vistats;
