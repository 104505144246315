import React from "react";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

const CustomList = ({ id, name, label }) => {
  let valueList = label ? label.split("|") : "";
  return (
    <React.Fragment>
      <div
        className={`list list-${name} col-12 col-lg-12 col-md-12 col-sm-12`}
        name={name}
        id={name}
      >
        <ul className="col-12 pl-0 pr-0">
          {valueList &&
            valueList.map((item, index) => {
              return (
                <li
                  key={index}
                  className={`item-list list-unstyled`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      '<i class="fa fa-check mr-2"></i>' + item,
                      {
                        USE_PROFILES: { html: true },
                        ADD_TAGS: ["a", "p", "div", "span"],
                        ADD_ATTR: ["href", "target", "name", "class"],
                      }
                    ),
                  }}
                ></li>
              );
            })}
        </ul>
      </div>
    </React.Fragment>
  );
};

CustomList.propTypes = {
  name: PropTypes.string.isRequired,
};

export default CustomList;
