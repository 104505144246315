import dayjs from "dayjs";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import ControlRowFactory from "../../../factories/ControlRowFactory";
import accessibilityHelper from "../../../helpers/accessibilityHelper";
import controlsLogicHelper from "../../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import useWindowSize from "../../../helpers/windowSize";
import Error from "../../controls/StaticControls/Error.Component";
import Info from "../../controls/StaticControls/Info.Component";
import Label from "../../controls/StaticControls/Label.Component";
import TextArea from "../../controls/StaticControls/TextArea.Component";
import Tooltip from "../../controls/StaticControls/Tooltip.Component";

const DateControl = observer(
  ({
    id,
    name,
    label,
    currentValue,
    handleDateChange,
    handleInteraction,
    helpMessages,
    infoMessages,
    extraInfo,
    attributes,
    validation,
  }) => {
    let day;
    let month;
    let year;
    let currentDate = dayjs();
    let dayValues = [["", ""]];
    let monthValues = [["", ""]];
    let yearValues = [["", ""]];

    const customFormGroupClass = attributes?.customFormGroupClass ?? "";
    const errorClass = validation && validation.isValid === false ? "error" : "";
    const yearsRangeDifference =
      attributes !== null && !isNullOrUndefined(attributes.yearsRangeDifference)
        ? attributes.yearsRangeDifference
        : null;
    const maxYearDifference =
      attributes !== null && !isNullOrUndefined(attributes.maxYearDifference)
        ? attributes.maxYearDifference
        : 0;
    const startYearValue = parseInt(
      dayjs(currentDate).subtract(parseInt(maxYearDifference), "years").format("YYYY")
    );
    const endYearValue = parseInt(
      dayjs(currentDate).subtract(parseInt(yearsRangeDifference), "years").format("YYYY")
    );

    let backdate = new Date();
    backdate.setMinutes(new Date().getMinutes() - 20);

    //adds DD/MM/YYYY as placeholder on select date
    if (useWindowSize().width < 991 && attributes?.usePlaceholder === true) {
      dayValues = [["", "DD"]];
      monthValues = [["", "MM"]];
      yearValues = [["", "YYYY"]];
    }

    for (let i = 1; i <= 31; i++) {
      dayValues.push([i < 10 ? `0${i}` : `${i}`, `${i}`]);
    }

    for (let i = 1; i <= 12; i++) {
      monthValues.push([i < 10 ? `0${i}` : `${i}`, `${i}`]);
    }

    if (endYearValue < startYearValue) {
      for (let i = startYearValue; i >= endYearValue; i--)
        yearValues.push([`${i}`, `${i}`]);
    } else {
      for (let i = startYearValue; i <= endYearValue; i++)
        yearValues.push([`${i}`, `${i}`]);
    }

    if (name === "date-to-commence") {
      yearValues.splice(0, 1);
    }

    const checkDateChanged = (dateToSave = false) => {
      if (dateToSave) {
        day = dateToSave[2];
        month = dateToSave[1];
        year = dateToSave[0];
      } else {
        const date =
          isNullOrUndefined(currentValue) || currentValue === ""
            ? "YYYY-MM-DD"
            : currentValue;
        const dateSubfield = date.split("-");

        day = dateSubfield[2];
        month = dateSubfield[1];
        year = dateSubfield[0];
      }

      if (day !== "DD" && month !== "MM" && year !== "YYYY") return true;

      return false;
    };

    let dateChanged = checkDateChanged();

    const handleValidationForControl = (year, month, day) => {
      dateChanged = checkDateChanged([year, month, day]);
      let defaultYear = yearValues[0][0];

      let value = `${year}-${month}-${day}`;
      if (name === "date-to-commence") {
        value = `${defaultYear}-${month}-${day}`;
      }

      handleDateChange(name, value, dateChanged);
      controlsLogicHelper.ManipulateControlValuesOnChange(name, value);
    };

    const controlComponent = (
      <React.Fragment key={`controlkey-${name}`}>
        <div className="field-date-part field-date-part-day d-inline-block col-lg-3 col-md-3 col-sm-4 col-4">
          <label className="col-lg-4 col-md-4 d-none d-md-inline-block d-lg-inline-block ">
            Day
          </label>
          <select
            className="form-control d-inline-block col-lg-8 col-md-8 col-sm-12 col-12"
            autoComplete={accessibilityHelper.getAutoCompleteAttribute(`${name}[day]`)}
            id={`${name}[day]`}
            name={`${name}-day`}
            value={day}
            data-testid={`test-${name}-day`}
            data-inner-item={`${name}-day`}
            data-fieldname={`${name}[day]`}
            onChange={(event) => {
              handleValidationForControl(year, month, event.target.value);
              handleInteraction(event);
            }}>
            {dayValues.map((item) => (
              <option value={item[0]} key={name + "_day_" + item[0]}>
                {item[1]}
              </option>
            ))}
          </select>
        </div>

        <div className="field-date-part field-date-part-month d-inline-block col-lg-4 col-md-4 col-sm-4 col-4">
          <label className="col-lg-5 col-md-5 d-none d-md-inline-block d-lg-inline-block">
            Month
          </label>
          <select
            className="form-control d-inline-block col-lg-6 col-md-6 col-sm-12 col-12"
            id={`${name}[month]`}
            autoComplete={accessibilityHelper.getAutoCompleteAttribute(`${name}[month]`)}
            name={`${name}-month`}
            value={month}
            data-testid={`test-${name}-month`}
            data-inner-item={`${name}-month`}
            data-fieldname={`${name}[month]`}
            onChange={(event) => {
              handleValidationForControl(year, event.target.value, day);
              handleInteraction(event);
            }}>
            {monthValues.map((item) => (
              <option value={item[0]} key={name + "_month_" + item[0]}>
                {item[1]}
              </option>
            ))}
          </select>
        </div>

        <div className="field-date-part field-date-part-year d-inline-block col-lg-4 col-md-4 col-sm-4 col-4">
          <label className="col-lg-3 col-md-3 d-none d-md-inline-block d-lg-inline-block">
            Year
          </label>
          <select
            className="form-control d-inline-block col-lg-7 col-md-7 col-sm-12 col-12"
            autoComplete={accessibilityHelper.getAutoCompleteAttribute(`${name}[year]`)}
            id={`${name}[year]`}
            name={`${name}-year`}
            data-testid={`test-${name}-year`}
            data-inner-item={`${name}-year`}
            data-fieldname={`${name}[year]`}
            value={year}
            onChange={(event) => {
              handleValidationForControl(event.target.value, month, day);
              handleInteraction(event);
            }}>
            {yearValues.map((item) => (
              <option value={item[0]} key={name + "_year_" + item[0]}>
                {item[1]}
              </option>
            ))}
          </select>
        </div>
      </React.Fragment>
    );

    const componentMapping = {
      label: (
        <Label
          key={`labelkey-${name}`}
          id={id}
          label={label}
          validation={validation ? validation : ""}
          forInput={name}
        />
      ),
      tooltip: helpMessages ? (
        <Tooltip
          key={`tooltipkey-${name}`}
          helpMessages={helpMessages != null ? helpMessages.help : ""}
          controlName={name}
        />
      ) : (
        ""
      ),
      info: (
        <>
          {extraInfo &&
            extraInfo.map(
              (ei) =>
                ei.text && (
                  <TextArea
                    key={`extrainfotextkey-${name}`}
                    name={`extrainfotextkey-${name}`}
                    label={ei.text}
                    className={"extraInfo"}
                  />
                )
            )}

          {infoMessages ? (
            <Info
              key={`infokey-${name}`}
              infoMessages={infoMessages != null ? infoMessages.info : ""}
            />
          ) : (
            ""
          )}
        </>
      ),
      error: <Error validation={validation ? validation : ""} className={"next-line"} />,
      control: controlComponent,
    };

    const groupClasses = {
      "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
      "form-group": `col-lg-8 col-md-8 col-sm-12 col-12`,
    };

    return (
      <div
        className={`form-group form-group-container form-group-${name} ${customFormGroupClass}`}>
        <div
          id={`row-${name}`}
          className={`row row-${errorClass} `}
          data-testid={`test-row-${name}`}>
          <ControlRowFactory
            controlName={name}
            groupClasses={groupClasses}
            componentMappings={componentMapping}
          />
        </div>
      </div>
    );
  }
);

DateControl.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  currentValue: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  handleInteraction: PropTypes.func.isRequired,
  helpMessages: PropTypes.shape({
    help: PropTypes.object,
  }),
  infoMessages: PropTypes.shape({
    info: PropTypes.object,
  }),
  attributes: PropTypes.object,
  validation: PropTypes.shape({
    messages: PropTypes.array,
    isValid: PropTypes.bool,
  }),
};

export default DateControl;
