export const getDeviceType = () => {
  let windowWidth = window.innerWidth;
  if (windowWidth >= 320 && windowWidth <= 850) {
    return "M";
  }
  if (windowWidth >= 768 && windowWidth <= 1024) {
    return "T";
  }
  if (windowWidth >= 1025) {
    return "D";
  }
  return "U";
};
