import queryString from "query-string";
import { forwardRef } from "react";
import { Link, Redirect } from "react-router-dom";

/**
 * Wrapper around react-router's <Link> component which preserves query string.
 *
 * @example
 * // (1)
 * // current url is /aboutyou?refId=wow&foo=bar
 * <AppLink to="/summary" />
 * // results into /summary?refId=wow&foo=bar
 *
 * // (2)
 * // current url is /aboutyou?color=red&gender=male
 * <AppLink to="/summary?color=green" />
 * // results into /summary?color=green&gender=male
 *
 * // (3)
 * // current url is /aboutyou?color=red&gender=male
 * <AppLink to="/summary" preserveQueryString={false} />
 * // results into /summary
 */
const AppLink = forwardRef(
  (
    /** @type {import('react-router-dom').LinkProps} */ props,
    /** @type {import('react').ForwardedRef<HTMLAnchorElement>} */ ref
  ) => {
    return (
      <Link
        {...props}
        ref={ref}
        to={preserveQueryStringParams(props.to)}
        data-app-link="true"
      />
    );
  }
);

export default AppLink;

/**
 * Wrapper around react-router's <Redirect> component which preserves query string.
 *
 * @param {import('react-router-dom').RedirectProps} props
 */
export const AppRedirect = (props) => {
  return (
    <Redirect
      {...props}
      to={preserveQueryStringParams(props.to)}
      data-app-redirect="true"
    />
  );
};

/**
 * This function makes sure react router's prop `to`
 * preserves current query string.
 *
 * @param toProp {import('react-router-dom').LinkProps['to']}
 * @returns {import('react-router-dom').LinkProps['to']}
 */
export function preserveQueryStringParams(toProp) {
  if (window.location.search === "") return toProp;

  const parsedToProp = typeof toProp === "function" ? toProp(window.location) : toProp;

  let urlToNavigate;

  if (typeof parsedToProp === "string") {
    urlToNavigate = parsedToProp;
  }

  if (typeof parsedToProp === "object") {
    urlToNavigate =
      (parsedToProp.pathname || "") +
      (parsedToProp.search || "") +
      (parsedToProp.hash || "");
  }

  if (!urlToNavigate) return toProp;

  const parsedCurrentUrl = queryString.parseUrl(window.location.href);
  const parsedToUrl = queryString.parseUrl(urlToNavigate, {
    parseFragmentIdentifier: true,
  });

  let result = queryString.stringifyUrl({
    url: parsedToUrl.url,
    query: { ...parsedCurrentUrl.query, ...parsedToUrl.query },
  });

  if (parsedToUrl.fragmentIdentifier) {
    // passing `stringifyUrl({ fragmentIdentifier })` throws for some reason
    // manually attach fragmentIdentifier
    result += "#" + parsedToUrl.fragmentIdentifier;
  }

  return result;
}

/**
 * Makes sure all `href`s in the string preserve current page query params.
 * It is smart and will NOT touch href="tel:"
 *
 * @param htmlString {String}
 * @returns {String}
 */
export function preserveQueryStringParamsInHtmlString(htmlString) {
  return htmlString.replace(/href=(\"|\')(?!tel).*?(\"|\')/g, (match) => {
    try {
      const hrefValue = JSON.parse(match.split("=")[1]);
      return `href="${preserveQueryStringParams(hrefValue)}"`;
    } catch (err) {
      return match;
    }
  });
}
