import queryString from "query-string";
import { cookies } from "../helpers/cookies";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";

const handleGclidParameter = ({
  CookieTTL,
  DefaultDomain,
  GclidCookie,
  BlacklistedRefIds,
  IgnoreDomains,
}) => {
  const queryStringDetails = queryString.parse(window.location.search);

  const now = new Date(Date.now());
  const expirationDate = now.setDate(now.getDate() + CookieTTL);

  var gclid = queryStringDetails.gclid;
  if (isNullOrUndefined(gclid)) {
    const qsDetails = new URLSearchParams(window.location.search);
    const qsParams = new URLSearchParams();
    for (const [name, value] of qsDetails) {
      qsParams.append(name.toLowerCase(), value);
    }
    gclid = qsParams.get("gclid");
  }
  //if the value is valid override
  if (!isNullOrUndefined(gclid) && gclid !== "" && !BlacklistedRefIds.includes(gclid)) {
    cookies.set(GclidCookie, gclid, {
      secure: true,
      expires: new Date(expirationDate),
      path: "/",
      domain: DefaultDomain,
      sameSite: "Lax",
    });
  }
};

export default handleGclidParameter;
