import reactHtmlParser from "html-react-parser";
import React, { Component } from "react";
import reactStringReplace from "react-string-replace";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import { vistatsV1Call } from "../../../helpers/vistatsV1Helper";
import AppStore from "../../../store/AppStore";

class AdUnit extends Component {
  state = { currentAd: null, renderedAd: null };

  render() {
    if (this.state.currentAd != null) {
      let { cssClass } = this.props;

      return (
        <React.Fragment>
          <div
            className={`ad-unit ad-unit-${cssClass}`}
            id={`${this.state.currentAd.adName}-${this.state.currentAd.slotName}`}>
            {this.state.renderedAd}
          </div>
        </React.Fragment>
      );
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      !isNullOrUndefined(prevProps.currentPage) &&
      !isNullOrUndefined(this.props.currentPage) &&
      prevProps.currentPage.url !== this.props.currentPage.url
    )
      this.getAdDetails();
  }

  componentDidMount() {
    this.getAdDetails();
  }

  getAdDetails() {
    let { currentPage, slotName, specificAd } = this.props;
    let renderedAd = "%%";
    let responsiveAd = "";
    var queryString = AppStore.getEntryQueryString();

    if (!isNullOrUndefined(currentPage)) {
      var currentAd = null;

      if (!isNullOrUndefined(specificAd)) currentAd = specificAd;
      else currentAd = AppStore.getAdProperties(currentPage.url, slotName, queryString);

      if (currentAd != null && this.state.adDisplayed == null) {
        responsiveAd = AppStore.getResponsiveAd(currentAd, null);

        if (currentAd.clickUrl != "") {
          renderedAd = reactStringReplace(renderedAd, "%%", (match, i) => (
            <a
              name={`ad-name-${currentAd.adName}-${currentAd.slotName}`}
              className={`${currentAd.adName}-${currentAd.slotName}-click-ad-unit`}
              target="_blank"
              href={`${currentAd.clickUrl}`}
              key={slotName}>
              <div>{reactHtmlParser(responsiveAd)}</div>
            </a>
          ));
        } else {
          renderedAd = reactStringReplace(renderedAd, "%%", (match, i) => (
            <div key={slotName}>{reactHtmlParser(responsiveAd)}</div>
          ));
        }
        this.setState({ ["currentAd"]: currentAd, ["renderedAd"]: renderedAd });
        this.notifyVistatsAdRendered(currentAd);
      } else {
        this.setState({ ["currentAd"]: null, ["renderedAd"]: null });
      }
    }
  }

  notifyVistatsAdRendered(currentAd) {
    if (currentAd === null) return;

    let adSize = "";

    if (window.$ !== undefined) {
      let imgLoaded = window.$(`#${currentAd.adName}-${currentAd.slotName} img`);

      if (imgLoaded !== null && imgLoaded.length > 0) {
        let fullSourceStr = imgLoaded[0].currentSrc;
        adSize = fullSourceStr.substring(fullSourceStr.lastIndexOf("/"));
      }
    }

    vistatsV1Call(
      currentAd.slotName,
      currentAd.adName,
      () =>
        `${currentAd.adName}&SlotName=${currentAd.slotName}&Size=${adSize}&Creative=RENDERED`,
      () => `/AdRendered`
    );
  }
}

export default AdUnit;
