import { isNullOrUndefined } from "@helpers/isNullOrUndefined";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ControlsFactory from "../../factories/ControlsFactory";
import helper from "../../helpers/controlsLogicHelper";
import AppStore from "../../store/AppStore";
import Error from "../controls/StaticControls/Error.Component";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";

const CustomRadio = ({
    id,
    name,
    label,
    controlValuesList,
    handleChange,
    handleInteraction,
    currentValue,
    helpMessages,
    children,
    defaultValue,
    infoMessages,
    validation,
    validationResult,
}) => {
    let subControls = {};
    let [childVisible, setChildControlVisibility] = useState(true);

    children.map((item) => {
        let controlName = item.Name;
        item.currentValue = item.defaultValue;
        let control = (
            <ControlsFactory model={item} key={item.Name} validationResult={validationResult} />
        );
        subControls[controlName] = control;
    });

    const errorClass = validation && validation.isValid === false ? "error" : "";
    const versionType =
        AppStore.getUserJourneySettings()?.customControls?.customRadio || "";
    const versionTypeStyle = versionType !== "" ? "radio-version-" + versionType : "";
    const info = infoMessages?.info || "";
    const infoClass = infoMessages?.info?.class || "";
    const infoPosition = infoMessages?.info?.position || "before";

    useEffect(() => {
        if (isNullOrUndefined(currentValue) || currentValue?.length === 0) {
            AppStore.setFormData({ [name]: defaultValue !== "" ? defaultValue : "" });
        }
    }, []);

    useEffect(() => {
        if (currentValue === "yes" || currentValue === true) {
            setChildControlVisibility(true);
        } else {
            setChildControlVisibility(false);
        }

        helper.ManipulateControlValues(name, currentValue);
    });

  return (
    <React.Fragment>
      <div
        className={`form-group form-group-container form-group-${name} ${
          versionType === "button" ? "custom-group" : ""
        }`}>
        <div className={`row${errorClass ? ` row-${errorClass}` : ""}`}>
          <div className="label-group col-lg-4 col-md-4 col-sm-12 col-12">
            <Label id={name} label={label} />
            <Tooltip
              helpMessages={helpMessages != null ? helpMessages.help : ""}
              controlName={name}
            />
          </div>
          <div
            className={`form-group d-inline-block ${
              versionType === "button"
                ? "col-lg-8 col-md-8 col-sm-12 col-12"
                : `col-lg-4 col-md-5 col-sm-12 col-12`
            }`}>
            {controlValuesList.map((item) => (
              <div
                key={name + "_" + item.Key}
                className={`form-check form-check-inline ${versionTypeStyle} ${
                  currentValue === item.Key ? "checked" : ""
                }`}>
                <input
                  id={name + "_" + item.Key}
                  className="form-check-input"
                  type="radio"
                  name={name}
                  value={item.Key}
                  onChange={(event) => {
                    if (event.currentTarget.value === "yes") {
                      setChildControlVisibility(true);
                      AppStore.setVisibilityControls({
                        ["cost-of-claim"]: true,
                      });
                      AppStore.setVisibilityControls({
                        ["cost-of-third-party-claim"]: true,
                      });
                      AppStore.setVisibilityControls({
                        ["club-member-dropdown"]: true,
                      });
                      if (name === "storage-address" || name === "overnight-storage") {
                        AppStore.setVisibilityControls({
                          ["postcode-overnight"]: true,
                        });
                      }
                    } else {
                      setChildControlVisibility(false);
                      if (name === "liability") {
                        AppStore.setVisibilityControls({
                          ["cost-of-claim"]: false,
                        });
                        AppStore.deleteFormData("cost-of-claim");
                        AppStore.setVisibilityControls({
                          ["cost-of-third-party-claim"]: false,
                        });
                        AppStore.deleteFormData("cost-of-third-party-claim");
                        AppStore.setVisibilityControls({
                          ["club-member-dropdown"]: false,
                        });
                        AppStore.deleteFormData("club-member-dropdown");
                      }
                      if (name === "storage-address") {
                        AppStore.setVisibilityControls({
                          ["postcode-overnight"]: false,
                        });
                      }
                    }
                    handleInteraction(event);
                    handleChange(event);
                  }}
                  onClick={(event) => {
                    if (event.target.checked === "yes" || event.target.checked === true) {
                      setChildControlVisibility(true);
                    } else {
                      setChildControlVisibility(false);
                    }
                    if (
                      name === "ld-occupations-opt-in" &&
                      typeof event.target.value !== "undefined"
                    ) {
                      const value = event.target.value.toLowerCase();
                      helper.SetCrossSellOccupationsOptInValue(value);
                      helper.SetCrossSellConvictionsVisibility(false);
                    } else if (
                      name === "ld-occupations-opt-in" &&
                      typeof event.target.value === "undefined"
                    ) {
                      helper.SetCrossSellConvictionsVisibility(true);
                    }
                    handleChange(event);
                  }}
                  checked={
                    isNullOrUndefined(currentValue) || currentValue.length === 0
                      ? !isNullOrUndefined(defaultValue) &&
                        defaultValue.length !== 0 &&
                        item.Key === defaultValue
                        ? "checked"
                        : ""
                      : item.Key === currentValue
                      ? "checked"
                      : ""
                  }
                />
                <label className="form-check-label" htmlFor={name + "_" + item.Key}>
                  {item.Value}
                </label>
              </div>
            ))}
            {infoPosition === "before" && (
              <Info infoMessages={info} className={infoClass} />
            )}
          </div>
          {childVisible && [
            subControls["markings-dropdown"],
            subControls["tracker-dropdown"],
            subControls["security-devices-dropdown"],
            subControls["riding-qualifications-dropdown"],
            subControls["bike-organisation-dropdown"],
            subControls["riding-qualifications-dropdown-additional"],
            subControls["bike-organisation-dropdown-additional"],
            subControls["optional-extras-select"],
          ]}
          {validationResult !== undefined &&
            validationResult.find((obj) => {
              return obj[`validator-${name}`] !== undefined;
            }) !== undefined && <Error validation={validation ? validation : ""} />}
          {infoPosition === "after" && <Info infoMessages={info} className={infoClass} />}
        </div>
      </div>
      {childVisible && [subControls["club-member-dropdown"]]}
    </React.Fragment>
  );
};

CustomRadio.propTypes = {
    name: PropTypes.string.isRequired,
};

export default CustomRadio;
