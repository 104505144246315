import React from "react";

const DfpMpuOne = () => {
  return (
    <React.Fragment>
      <div className="dfp-unit-wrapper dfp-mpu dfp-mpu-one">
        <div className="dfp-mpu-one-image"></div>
      </div>
    </React.Fragment>
  );
};

export default DfpMpuOne;
