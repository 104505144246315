import React, { Component } from "react";
import AmendButton from "../../controls/QuoteSummary/QuoteSummaryAmendButton";

class QuoteSummaryHeader extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="header-bar header-quotesummary m-1">
          <div className="col-12 text-right">
            <AmendButton />

            <button
              type={`button`}
              className={`btn btn-quotesummary-close d-inline-block m-1`}
              name={`quotesummary-close`}
              onClick={(event) => {
                window.close();
              }}
            >
              {`Close`}
            </button>
            <button
              type={`button`}
              className={`btn btn-quotesummary-print d-inline-block m-1`}
              name={`quotesummary-print`}
              onClick={(event) => {
                window.print();
              }}
            >
              {`Print`}
              <i className={`fa fas fa-print ml-2`}></i>
            </button>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default QuoteSummaryHeader;
