import PropTypes from "prop-types";
import React from "react";

const HiddenInput = ({ name, required, currentValue }) => {
  return (
    <input
      type="hidden"
      className={`d-none hidden`}
      id={name}
      name={name}
      required={required}
      value={currentValue}
    />
  );
};

HiddenInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default HiddenInput;
