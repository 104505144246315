import queryString from "query-string";

/**
 * Check if supplied query param name and value found on the page.
 *
 * @param {String} key Query param name
 * @param {String|Boolean|Number} value Query param value
 *
 * @returns {Boolean}
 */
export const isQueryParamMatched = (key, value) => {
  if (!key || !value) return false;

  const keyLowerCased = key.toLowerCase();
  const valueLowerCased = value.toString().toLowerCase();

  const parsedQueryStr = queryString.parse(window.location.search);

  const keyOnPage = Object.keys(parsedQueryStr).find(
    (paramName) => paramName.toLowerCase() === keyLowerCased
  );
  const valueOnPage = parsedQueryStr[keyOnPage];

  if (!valueOnPage) return false;

  return valueLowerCased === valueOnPage.toLowerCase();
};
