import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import logoClickStream from "../helpers/logoClickStream";
import { getPartnershipLogoImage } from "../helpers/partnership";
import AppStore from "../store/AppStore";
import AppLink, { preserveQueryStringParams } from "./AppLink";
import CTAPhone from "./controls/StaticControls/Phone.Component";
import LogoPromptPopup from "./page-components/LogoPromptPopup";
import SideBar from "./page-components/SideBar";

class Header extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    toggleContainer: false,
  };

  componentDidUpdate() {
    AppStore.liveValidation &&
      document.getElementById("help-area-questions-alternate-container")?.focus();
  }

  handleToggleState = () => {
    this.setState({
      toggleContainer: !this.state.toggleContainer,
    });
  };

  renderLogo = () => {
    const ujCfg = this.props.header_info.logo;
    const logoCfg = {
      redirectToHref: ujCfg?.redirectToHref ?? "",
      redirectToNewTab: ujCfg?.redirectToNewTab ?? false,
      redirectPopup: ujCfg?.redirectPopup
        ? {
            title: ujCfg?.redirectPopup?.title ?? "",
            text: ujCfg?.redirectPopup?.text ?? "",
            actionBtnText: ujCfg?.redirectPopup?.actionBtnText ?? "",
            actionBtnAction: ujCfg?.redirectPopup?.actionBtnAction ?? "close",
            cancelBtnText: ujCfg?.redirectPopup?.cancelBtnText ?? "",
            cancelBtnAction: ujCfg?.redirectPopup?.cancelBtnAction ?? "close",
          }
        : null,
    };

    const partnershipLogo = getPartnershipLogoImage(
      AppStore.getUserJourneySettings().partnership
    );
    const hrefStyle = partnershipLogo
      ? {
          backgroundImage: `url(${partnershipLogo.imageUrl})`,
          height: partnershipLogo.imageHeightPx,
        }
      : null;
    const hrefClassName = "logo col-12";
    const hrefTarget = logoCfg.redirectToNewTab === true ? "_blank" : "_self";
    const isHrefExternal = /^((http|https):\/\/)/.test(logoCfg.redirectToHref);
    const ariaContent = `Go to ${logoCfg.redirectToHref}`;
    const testId = "header-logo";

    if (logoCfg.redirectToHref !== "") {
      if (logoCfg.redirectPopup) {
        return (
          <LogoPromptPopup
            logoCfg={logoCfg}
            isHrefExternal={isHrefExternal}
            renderTrigger={({ open }) => (
              <button
                type="button"
                data-testid={testId}
                style={hrefStyle}
                className={hrefClassName}
                onClick={open}
              />
            )}
          />
        );
      }

      // react-router's <Link> always appends href to base url
      // having https://test.com will result in baseurl.com/https://test.com
      // use <a> to avoid base url
      return isHrefExternal ? (
        <a
          data-testid={testId}
          data-is-router-link="false"
          href={logoCfg.redirectToHref}
          target={hrefTarget}
          style={hrefStyle}
          className={hrefClassName}
          aria-label={ariaContent}
          onClick={logoClickStream.userRedirected}
        />
      ) : (
        <AppLink
          data-testid={testId}
          data-is-router-link="true"
          to={logoCfg.redirectToHref}
          target={hrefTarget}
          style={hrefStyle}
          className={hrefClassName}
          aria-label={ariaContent}
          onClick={logoClickStream.userRedirected}
        />
      );
    }

    return <div data-testid={testId} style={hrefStyle} className={hrefClassName} />;
  };

  render() {
    const showOnTop = AppStore.userInteractionState.get("isLightboxVisible") === true;

    const isResultsPage = this.props.isResultsPage === "yourquotes" ? true : false;
    const amendButtonVisible =
      this.props.header_info.resultsAmendButton.toLowerCase() === "true" ? true : false;

    let iarBannerVisibile = false;
    if (!isNullOrUndefined(this.props.header_info.iarBanner)) {
      iarBannerVisibile =
        this.props.header_info.iarBanner.toLowerCase() === "true" ? true : false;
    }

    let isPriceGuarantee = false;
    if (!isNullOrUndefined(this.props.header_info.isPriceGuarantee)) {
      isPriceGuarantee =
        this.props.header_info.isPriceGuarantee.toLowerCase() === "true" ? true : false;
    }
    let resultsPhoneCTA = false;
    if (!isNullOrUndefined(this.props.header_info.resultsPhoneCTA)) {
      resultsPhoneCTA =
        this.props.header_info.resultsPhoneCTA.toLowerCase() === "true" ? true : false;
    }

    let customHeaderTitle = false;
    if (!isNullOrUndefined(this.props.header_info.customHeaderTitle)) {
      customHeaderTitle =
        this.props.header_info.customHeaderTitle.toLowerCase() === "true" ? true : false;
    }

    let isNeedHelpButton = false;
    if (!isNullOrUndefined(this.props.header_info.needLabelButton)) {
      isNeedHelpButton = this.props.header_info.needLabelButton === "true" ? true : false;
    }

    const needHelp = !isNullOrUndefined(this.props.header_info.need_help_label)
      ? this.props.header_info.need_help_label
      : "";
    const overlay = this.state.toggleContainer ? "overlay" : "";

    return (
      <React.Fragment>
        <header className={`header-bar ${showOnTop ? "on-top" : ""}`}>
          <div className="container pl-2 pr-0 pr-lg-3 pr-md-3 d-flex">
            <div
              className={`header-container col-lg-4 col-md-4 col-sm-6 col-6 d-flex flex-row py-2`}>
              {this.renderLogo()}
            </div>

            <div className="header-container header-title col-lg-4 col-md-4 col-sm-0 col-0 align-items-center d-xl-flex d-lg-flex d-md-flex flex-row d-sm-none d-none ">
              <div className="platform-title col-12 p-0 mx-auto d-flex flex-column text-center">
                {this.props.header_info.title}
              </div>
            </div>
            {isNeedHelpButton === true && (
              <div
                className={`need-help-panel-sidebar header-container container text-center pt-3 pb-3 pr-2 header-toggle-${this.state.toggleContainer}`}>
                <div className="help-area-questions-alternate-container">
                  <button
                    id="help-area-questions-alternate-container"
                    type="button"
                    className="btn help-area-questions-alternate"
                    onClick={this.handleToggleState}>
                    {this.state.toggleContainer ? (
                      <span>
                        <i className="fa fa-times" aria-hidden="true"></i>Close
                      </span>
                    ) : (
                      needHelp
                    )}
                  </button>
                </div>
                {this.state.toggleContainer ? (
                  <>
                    <div id="overlay" className={overlay}></div>
                    <SideBar
                      isOpened={this.state.toggleContainer}
                      setIsOpened={this.handleToggleState}
                      sidebar_info={AppStore.getUserJourneySettings().sidebar}
                    />
                  </>
                ) : null}
              </div>
            )}

            {isResultsPage === false && (
              <div className="header-container phone-cta col-lg-4 col-md-4 col-sm-6 col-6 d-flex px-0 flex-row-reverse text-right">
                <div className="phone-cta-wrapper px-3">
                  <span className="phone-text text-center d-block">
                    {this.props.header_info.text}
                  </span>
                  <CTAPhone
                    id="headerPhoneCTA"
                    name="headerPhoneCTA"
                    value={this.props.header_info.phone}
                  />
                </div>
              </div>
            )}
            {isResultsPage === true && amendButtonVisible === true && (
              <div className="amend-button-area col-lg-4 col-md-6 col-sm-6 col-6 d-flex flex-row-reverse p-0 text-right">
                <div
                  className={`form-group button-wrapper button-wrapper-amend col-lg-8 col-md-8 col-sm-12 p-0`}>
                  <a
                    href={preserveQueryStringParams(
                      `${import.meta.env.VITE_SITE_ROUTE}/${this.props.amendUrl}`
                    )}
                    className={`btn btn-secondary col-lg-12 col-md-10 col-sm-10 col-10`}>
                    <i className={`fa fas fa-chevron-left mr-2`}></i>
                    {`Amend my details`}
                  </a>
                </div>
              </div>
            )}
            {isResultsPage === true && isPriceGuarantee === true && (
              <div className="header-container phone-cta price-guarantee col-lg-4 col-md-4 col-sm-6 col-6 d-flex px-0 flex-row-reverse text-right">
                <div className="phone-cta-wrapper price-guarantee-wrapper px-4">
                  <span className="phone-text price-guarantee-text text-center d-block">
                    {this.props.header_info.priceGuaranteeText}
                  </span>
                  <CTAPhone
                    id="priceGuaranteePhoneCTA"
                    name="priceGuaranteePhoneCTA"
                    value={this.props.header_info.priceGuaranteePhone}
                  />
                </div>
              </div>
            )}
            {isResultsPage === true && resultsPhoneCTA == true && (
              <div className="header-container phone-cta results-phone-cta col-lg-4 col-md-4 col-sm-6 col-6 d-flex px-0 flex-row-reverse text-right">
                <div className="phone-cta-wrapper px-4">
                  <span className="phone-text text-center d-block">
                    {this.props.header_info.text}
                  </span>
                  <CTAPhone
                    id="headerPhoneCTA"
                    name="headerPhoneCTA"
                    value={
                      !isNullOrUndefined(this.props.header_info.resultsPhone)
                        ? this.props.header_info.resultsPhone
                        : this.props.header_info.phone
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </header>
        {iarBannerVisibile === true && (
          <div className="iar-header-banner">
            <div className="iar-header-content">
              <span className="iar-header-title">
                {!isNullOrUndefined(this.props.header_info.iarBannertext)
                  ? this.props.header_info.iarBannertext
                  : ""}
              </span>
              <span className="iar-header-title-poweredby">
                Powered by Vast Visibility
              </span>
            </div>
          </div>
        )}
        {isResultsPage === false && customHeaderTitle === true && (
          <div className="custom-header-title">
            <span>
              {!isNullOrUndefined(this.props.header_info.customTitle)
                ? this.props.header_info.customTitle
                : ""}
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter((Header = observer(Header)));

Header.propTypes = {
  amendUrl: PropTypes.string.isRequired,
  isResultsPage: PropTypes.string,
  header_info: PropTypes.shape({
    logo: PropTypes.object,
    customTitle: PropTypes.string,
    customHeaderTitle: PropTypes.string,
    resultsPhone: PropTypes.string,
    phone: PropTypes.string,
    isPriceGuarantee: PropTypes.string,
    priceGuaranteePhone: PropTypes.string,
    priceGuaranteeText: PropTypes.string,
    text: PropTypes.string,
    resultsAmendButton: PropTypes.string,
    resultsPhoneCTA: PropTypes.string,
    needLabelButton: PropTypes.string,
    need_help_label: PropTypes.string,
  }).isRequired,
};
