import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import {
  default as controlsLogicHelper,
  default as helpers,
} from "../../helpers/controlsLogicHelper";
import { isNullOrUndefined } from "../../helpers/isNullOrUndefined";
import AppStore from "../../store/AppStore";
import AppStoreActions from "../../store/AppStoreActions";
import Error from "../controls/StaticControls/Error.Component";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";

const LicenceNumber = ({
  id,
  name,
  label,
  type,
  required,
  handleBlur,
  handleChange,
  currentValue,
  defaultValue,
  helpMessages,
  infoMessages,
  validation,
  attributes,
}) => {
  const customFormGroupClass = attributes?.customFormGroupClass ?? "";
  const liveValidation = AppStore.liveValidation;
  const version = attributes && attributes.version ? attributes.version : 1;
  const errorClass = validation && validation.isValid === false ? "error" : "";
  const errorNextLineClass = "next-line";
  const allowedCharsRegex = new RegExp(
    attributes.allowedCharsRegex !== null || attributes.allowedCharsRegex !== "undefined"
      ? attributes.allowedCharsRegex
      : null
  );

  const commonChangeEvent = {
    target: {
      name: name,
      value: currentValue,
    },
  };

  let licenceNumberData = AppStore.getControlByName(name);
  //When radio === no, if any input value is empty, it won't pass validation, hence it's set to 000... in controlsLogicHelper
  let isEmpty =
    isNullOrUndefined(licenceNumberData) ||
    licenceNumberData === "0000000000000000" ||
    licenceNumberData === "" ||
    licenceNumberData.length !== 16;
  const [licenceNumberValue, setLicenceNumberValue] = useState(
    !isNullOrUndefined(licenceNumberData) && !isEmpty
      ? licenceNumberAutocomplete(name)
      : licenceNumberAutocomplete(name)
  );
  const [licenceNumberAdditional, setLicenceNumberAdditional] = useState(
    !isNullOrUndefined(licenceNumberData) && !isEmpty
      ? licenceNumberData.substr(11, 16)
      : ""
  );

  useEffect(() => {
    if (!isNullOrUndefined(licenceNumberValue) && licenceNumberValue !== "")
      AppStore.setHasChanged(name + "-first", true);
    AppStore.setHasChanged(name, false);
  }, []);

  useEffect(() => {
    currentValue = licenceNumberValue + licenceNumberAdditional;
    AppStore.setFormData({ [name]: currentValue });
    AppStoreActions.executeActionsOnChange(name, currentValue);
    controlsLogicHelper.ManipulateControlValues(name, currentValue);
  });

  const handleBlurCustom = (event) => {
    if (
      AppStore.getHasChanged(`${name}-first`) &&
      AppStore.getHasChanged(`${name}-second`)
    )
      handleBlur(commonChangeEvent);
  };

  const controlComponent = (
    <Fragment>
      <input
        type={
          attributes.type !== null || attributes.type !== "undefined"
            ? attributes.type
            : type
        }
        className={`form-control mx-1 my-1 col-lg-4 col-md-4 control-${errorClass}`}
        id={name + "-first"}
        name={name + "-first"}
        data-testid={`test-${name}-first`}
        required={required}
        onBlur={(event) => {
          AppStore.setHasChanged(event.target.name, true);
          handleBlurCustom(event);
        }}
        onChange={(event) => {
          event.preventDefault();
          if (liveValidation) {
            AppStore.setHasChanged(event.target.name, false);
            handleChange(commonChangeEvent);
          }
          if (!allowedCharsRegex.test(event.target.value)) {
            event.target.value = helpers.ReplaceByRegex(name, event.target.value);
          }
          event.target.value = event.target.value.toUpperCase();
          setLicenceNumberValue(event.target.value);
        }}
        value={licenceNumberValue}
        maxLength={!isNullOrUndefined(attributes.maxLength) ? attributes.maxLength : "11"}
        placeholder={
          !isNullOrUndefined(attributes.placeholder)
            ? attributes.placeholder
            : "- - - - - - - - - - -"
        }
      />
      <input
        type={
          attributes.type !== null || attributes.type !== "undefined"
            ? attributes.type
            : type
        }
        className={`form-control mx-1 my-1 col-lg-4 col-md-4 control`}
        id={`${name}-second`}
        name={`${name}-second`}
        data-testid={`test-${name}-second`}
        required={required}
        onBlur={(event) => {
          AppStore.setHasChanged(event.target.name, true);
          handleBlurCustom(event);
        }}
        onChange={(event) => {
          event.preventDefault();
          if (liveValidation) {
            AppStore.setHasChanged(event.target.name, false);
            handleChange(commonChangeEvent);
          }
          if (!allowedCharsRegex.test(event.target.value)) {
            event.target.value = helpers.ReplaceByRegex(
              name + "-second",
              event.target.value
            );
          }
          event.target.value = event.target.value.toUpperCase();
          setLicenceNumberAdditional(event.target.value);
        }}
        value={licenceNumberAdditional}
        maxLength={
          !isNullOrUndefined(attributes.maxLengthSecond)
            ? attributes.maxLengthSecond
            : "5"
        }
        placeholder={
          !isNullOrUndefined(attributes.placeholderSecond)
            ? attributes.placeholderSecond
            : "- - - - -"
        }
      />
    </Fragment>
  );

  const componentMapping = {
    label: (
      <Label
        key={`labelkey-${name}`}
        id={id}
        label={label}
        forInput={name}
        validation={validation ? validation : ""}
        defaultValue={defaultValue}
      />
    ), // forInput, validation and defaultValue are added for live validation purposes.
    tooltip: (
      <Tooltip
        key={`tooltipkey-${name}`}
        helpMessages={helpMessages != null ? helpMessages.help : ""}
        controlName={name}
      />
    ),
    info: (
      <Info
        key={`infokey-${name}`}
        infoMessages={infoMessages != null ? infoMessages.info : ""}
      />
    ),
    error: (
      <Error
        key={`errorkey-${name}`}
        validation={validation ? validation : ""}
        name={name}
      />
    ),
    control: controlComponent,
  };

  const groupClasses = {
    "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
    "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
  };

  const controlV1 = (
    <div className={`form-group form-group-container form-group-${name} custom-group`}>
      <div id={`row-${name}`} className={`row row-${errorClass}`} name={`${errorClass}`}>
        <div className="label-group col-lg-4 col-md-4 col-sm-12 col-12">
          <Label id={id} label={label} />
          <Tooltip
            helpMessages={helpMessages != null ? helpMessages.help : ""}
            controlName={name}
          />
        </div>
        <div className="form-group row justify-content-start bg-transparent col-lg-8 col-md-8 col-sm-12 col-12">
          {controlComponent}
          <Info infoMessages={infoMessages != null ? infoMessages.info : ""} />
        </div>
        <Error validation={validation ? validation : ""} className={errorNextLineClass} />
      </div>
    </div>
  );

  const controlV2 = (
    <div
      className={`form-group form-group-container form-group-${name} custom-group ${customFormGroupClass}`}>
      <div id={`row-${name}`} className={`row row-${errorClass}`} name={`${errorClass}`}>
        <ControlRowFactory
          controlName={name}
          groupClasses={groupClasses}
          componentMappings={componentMapping}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      {version === "nqs" && controlV2}
      {version !== "nqs" && controlV1}
    </Fragment>
  );
};

//https://ukdriving.org.uk/driving-licence-number/
function licenceNumberAutocomplete(controlName) {
  let additional = controlName.includes("additional") ? "-additional" : "";
  let surname = AppStore.getControlByName("surname" + additional);
  let dob = AppStore.getControlByName("date-of-birth" + additional);
  let isFemale = AppStore.getControlByName("gender" + additional) === "Female";
  if (
    isNullOrUndefined(surname) ||
    isNullOrUndefined(dob) ||
    surname === "" ||
    dob === ""
  ) {
    return "";
  }

  let result = formatSurname(surname) + formatDateofbirth(dob, isFemale);
  return result;
}

function formatDateofbirth(dob, isFemale) {
  dob = dob.split("-");
  let monthFirstDigit = parseInt(dob[1][0]);
  monthFirstDigit += isFemale ? 5 : 0;
  dob[1] = monthFirstDigit.toString() + dob[1].substr(1);

  let result = dob[0][2] + dob[1] + dob[2] + dob[0][3];
  return result;
}

function formatSurname(surname) {
  if (surname.toUpperCase().startsWith("MAC"))
    surname = surname.slice(0, 1) + surname.slice(2);
  surname = surname.padEnd(5, "9");
  surname = surname.substr(0, 5).toUpperCase();

  return surname;
}

LicenceNumber.propTypes = {
  name: PropTypes.string.isRequired,
};

export default LicenceNumber;
