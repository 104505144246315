import { isQueryParamMatched } from "./queryParamMatcher";
import { resolveStaticAssetPath } from "./staticAsset";

export const getPartnershipLogoImage = (partnershipConfigInUserJourney) => {
  const cfg = {
    paramName: partnershipConfigInUserJourney?.showWhenQueryParam?.name ?? "",
    paramValue: partnershipConfigInUserJourney?.showWhenQueryParam?.value ?? "",
    swapLogoUrl: partnershipConfigInUserJourney?.swapLogoUrl ?? "",
    swapLogoHeightPx: partnershipConfigInUserJourney?.swapLogoHeightPx ?? null,
  };

  if (!cfg.swapLogoUrl || !cfg.paramName || !cfg.paramValue) return null;

  return isQueryParamMatched(cfg.paramName, cfg.paramValue)
    ? {
        imageUrl: resolveStaticAssetPath(cfg.swapLogoUrl),
        imageHeightPx: cfg.swapLogoHeightPx,
      }
    : null;
};
