import React from "react";

const SimpleButton = ({
  id,
  type,
  label,
  name,
  redirectTo,
  onClick,
  className,
  icon,
  jsonpath,
  index,
  disabled,
  reverseButtonLabels,
  form,
  onKeyDown
}) => {
  return (
    <button
      form={form}
      id={id}
      data-testid={`test-${name}`}
      type={type}
      className={`btn ${className}`}
      name={name}
      redirectto={redirectTo}
      onClick={onClick}
      jsonpath={jsonpath}
      index={index}
      disabled={disabled === true ? true : null}
      onKeyDown={onKeyDown}
    >
      {!reverseButtonLabels && label}
      {/* reverseLabel reverses the order the icon and label are positioned in */}
      {icon && (
        <i
          className={`fa fa-${icon} ml-2`}
          redirectto={redirectTo}
          jsonpath={jsonpath}
          index={index}
          onClick={(ev) => {
            ev.preventDefault();
          }}
        ></i>
      )}
      {reverseButtonLabels && label}
    </button>
  );
};

export default SimpleButton;
