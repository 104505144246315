import { useEffect, useLayoutEffect, useState } from "react";

const useEffectInEvent = (enabled, event, set) => {
  useEffect(() => {
    if (!enabled) return;

    window.addEventListener(event, set);

    return () => window.removeEventListener(event, set);
  }, [enabled]);
};

/**
 * React hook to get element's `getBoundingClientRect`
 *
 * @param {Object} ref react ref of the element for which calculate bounding box
 * @param {Object} config optional hook config
 * @param {boolean} config.listenResize recalculate on window resize
 * @param {boolean} config.listenScroll recalculate on scroll
 */
export const useBoundingBox = (ref, config = {}) => {
  const { listenResize = false, listenScroll = false } = config;

  const [rect, setRect] = useState({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const set = () => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }
  };

  useLayoutEffect(() => {
    set();
  }, []);

  useEffectInEvent(listenResize, "resize", set);
  useEffectInEvent(listenScroll, "scroll", set);

  return rect;
};
