import React, { useId } from "react";
import AppStore from "../store/AppStore";

const ControlRowFactory = ({
  controlName,
  groupClasses,
  componentMappings,
  customDefaultOrder,
}) => {
  const defaultOrder = customDefaultOrder
    ? customDefaultOrder
    : {
        before: [],
        after: ["error"],
        groups: [
          {
            name: "label-group",
            elements: ["label", "tooltip"],
          },
          {
            name: "form-group",
            elements: ["control", "info"],
          },
        ],
      };

  const settings = AppStore.getUserJourneySettings();
  const order =
    settings.customControls && settings.customControls.elementOrder
      ? settings.customControls.elementOrder
      : defaultOrder;

  const uuid = useId();

  const solveBaseGroupMappings = (groupName) =>
    order[groupName].map((cType) => (
      <React.Fragment key={uuid + groupName + cType}>
        {componentMappings[cType]}
      </React.Fragment>
    ));
  const baseComponentGroups = {
    before: solveBaseGroupMappings("before"),
    after: solveBaseGroupMappings("after"),
  };

  const componentGroups = {};
  order.groups.forEach(
    (group) =>
      (componentGroups[group.name] = group.elements.map((cType) => (
        <React.Fragment key={uuid + group.name + cType}>
          {componentMappings[cType]}
        </React.Fragment>
      )))
  );

  return (
    <React.Fragment key={`${controlName}-rowfactory`}>
      {baseComponentGroups["before"]}
      {Object.keys(componentGroups).map((className) => (
        <div
          key={`${controlName}-${className}`}
          className={`${className} ${groupClasses[className]}`}>
          {componentGroups[className]}
        </div>
      ))}
      {baseComponentGroups["after"]}
    </React.Fragment>
  );
};

export default ControlRowFactory;
