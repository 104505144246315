/**
 * Check if value is either `null` or `undefined`
 *
 * @param {any} value value to check
 *
 * @example
 * isNullOrUndefined(); // true
 * isNullOrUndefined(undefined); // true
 * isNullOrUndefined(null); // true
 * isNullOrUndefined(''); // false
 * isNullOrUndefined(0); // false
 * isNullOrUndefined({}); // false
 */
export const isNullOrUndefined = (value) => value === undefined || value === null;
