import { useCallback, useLayoutEffect, useRef } from "react";

/**
 * Similar to useCallback but without dependency array
 *
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 *
 * @template T
 * @param {T} fn function to memoize
 * @returns {T}
 */
export function useEventCallback(fn) {
  const ref = useRef(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args) => ref.current?.(...args), []);
}
