import Submit from "../controls/Submit.Component";
import Button from "./Button.Component";

const ButtonFooterBanner = ({
  id,
  type,
  label,
  name,
  values,
  attributes,
  handleClick,
}) => {
  /*
        ButtonFooterBanner is used to simplify the display of the 'Previous' and 'Continue' submit buttons, found at the bottom of each page.
        In controls.json, replace the continue and previous buttons with the ButtonFooterBanner, and set the following attributes appropriately:
         - nextLabel: the label for the 'continue' button. Also is used to check if a 'continue' button should exist
         - previousLabel: the label for the 'previous' button. Also is used to check if a 'previous' button should exist
         - page: the name of the current page. This is used to set the className.
         - nextPage: the name of the next page. This isn't required as redirect behaviour is handled on submitting the form.
         - prevPage: the name of the previous page. This IS required as redirect behaviour is handled with the onClick() method in the controlFactory.jsx file.
         - submitShowIcon: controls if the component displays the icon for submit button
         - submitIcon: font awesome icon to display on the submit button
         - backShowIcon: controls if the component displays the icon for back button
         - backIcon: font awesome icon to display on the back button
        
        The redirection rules are applied as usual, handled by the FormComponent component, and determined by the given page name.
    */

  const hasForwardButton =
    attributes.nextLabel &&
    attributes.nextLabel !== "" &&
    attributes.nextLabel !== undefined &&
    attributes.nextLabel !== null;
  const hasBackwardButton =
    attributes.previousLabel &&
    attributes.previousLabel !== "" &&
    attributes.previousLabel !== undefined &&
    attributes.previousLabel !== null;

  const nextLabel = hasForwardButton ? attributes.nextLabel : "";
  const previousLabel = hasBackwardButton ? attributes.previousLabel : "";

  const nextValues = attributes.nextPage ? attributes.nextPage : "";
  const previousValues = attributes.prevPage ? attributes.prevPage : "";

  const additionalRedirectValue = attributes.previousAdditionalRedirectValue
    ? attributes.previousAdditionalRedirectValue
    : ""; // used to define target page for back button

  // We check if either of the buttons are to be displayed. If the answer is no, we display an empty div.
  // This is due to a desired flex-box display, spacing two elements to the edges of the containing element.

  return (
    <div className="button-footer-container">
      <div className="button-footer-wrapper">
        {/* We set the "icon" attribute to "left" to fit with the logic of the Button component, which positions the button icon to the left of the label */}
        {hasBackwardButton ? (
          <Button
            values={previousValues}
            formtarget={attributes.formtarget}
            label={previousLabel}
            type="button"
            name={`back-button-${attributes.page}`}
            handleClick={handleClick}
            attributes={{
              icon:
                attributes.backShowIcon === true ||
                attributes.backShowIcon === undefined
                  ? attributes.backIcon || "left"
                  : undefined,
              additionalRedirectValue: additionalRedirectValue,
            }}
            reverseButtonLabels={true}
          />
        ) : (
          <div />
        )}

        {hasForwardButton ? (
          <Submit
            values={nextValues}
            formtarget={attributes.formtarget}
            label={nextLabel}
            type="submit"
            name={`continue-button-${attributes.page}`}
            attributes={{
              icon:
                attributes.submitShowIcon === true ||
                attributes.submitShowIcon === undefined
                  ? attributes.submitIcon || "right"
                  : undefined,
            }}
            reverseButtonLabels={false}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export default ButtonFooterBanner;
