import { useEffect, useRef } from "react";

/**
 * React hook that listen for clicks/touches outside of supplied DOM
 * element.
 *
 * Can be used in components like Dropdown and Popover to close them when a
 * user clicks outside.
 *
 * @param {Object} props hook config
 * @param {Object} props.ref react ref to element for which listen click outside
 * @param {(event: Event, didUserScroll: boolean) => any} props.handler function to run when click outside occurs
 * @param {boolean} props.enabled whether to listen to click outside
 */
export function useOutsideClick(props) {
  const { ref, handler, enabled = true } = props;

  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
  });

  useEffect(() => {
    if (!enabled) return;

    const didUserScroll = () => {
      return (
        Math.abs(document.documentElement.scrollTop - stateRef.current.startScrollTop) > 0
      );
    };

    const onPointerDown = (e) => {
      if (isValidEvent(e, ref)) {
        stateRef.current.isPointerDown = true;
      }
    };

    const onMouseUp = (event) => {
      if (stateRef.current.ignoreEmulatedMouseEvents) {
        stateRef.current.ignoreEmulatedMouseEvents = false;
        return;
      }

      if (stateRef.current.isPointerDown && handler && isValidEvent(event, ref)) {
        stateRef.current.isPointerDown = false;
        handler(event, didUserScroll());
      }
    };

    const onTouchEnd = (event) => {
      stateRef.current.ignoreEmulatedMouseEvents = true;
      if (handler && stateRef.current.isPointerDown && isValidEvent(event, ref)) {
        stateRef.current.isPointerDown = false;
        handler(event, didUserScroll());
      }
    };

    const doc = ref.current?.ownerDocument ?? window.document;

    doc.addEventListener("mousedown", onPointerDown, true);
    doc.addEventListener("mouseup", onMouseUp, true);
    doc.addEventListener("touchstart", onPointerDown, true);
    doc.addEventListener("touchend", onTouchEnd, true);

    return () => {
      doc.removeEventListener("mousedown", onPointerDown, true);
      doc.removeEventListener("mouseup", onMouseUp, true);
      doc.removeEventListener("touchstart", onPointerDown, true);
      doc.removeEventListener("touchend", onTouchEnd, true);
    };
  }, [ref, enabled]);
}

function isValidEvent(event, ref) {
  const target = event.target;

  if ("button" in event && event.button > 0) {
    // if not left click of the mouse
    return false;
  }

  if (target) {
    // if the event target is no longer in the document
    const doc = target.ownerDocument ?? window.document;
    if (!doc.documentElement.contains(target)) {
      return false;
    }
  }

  return !ref.current?.contains(target);
}
