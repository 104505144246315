import ClientLogging from "../providers/loggingProvider";
import { isNullOrUndefined } from "./isNullOrUndefined";

const keyboardAccessKeys = "1234567890QWERTYUIOP";

//Attribute names from https://www.w3.org/TR/WCAG21/#input-purposes
//Postcode mapping from example https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#united_kingdom
const autocompleteMappings = {
  "first-name": "given-name",
  surname: "family-name",
  "first-name-additional": "given-name",
  "surname-additional": "family-name",
  email: "email",
  "mobile-phone": "tel",
  "date-of-birth[day]": "bday-day",
  "date-of-birth[month]": "bday-month",
  "date-of-birth[year]": "bday-year",
  "proposer-postcode": "postal-code",
  "proposer-postcode-house-number": "address-line1",
  "quote-reference-postcode": "postal-code",
  "recall-check-postcode": "postal-code",
  "buy-check-postcode": "postal-code",
};

const getControlsWithTooltips = (controlsArray) => {
  let newControls = [];
  controlsArray.forEach((control) => {
    if (!isNullOrUndefined(control.HelpMessages)) newControls.push(control);

    if (!isNullOrUndefined(control.Children) && control.Children.length > 0) {
      newControls = newControls.concat(getControlsWithTooltips(control.Children));
    }
  });
  return newControls;
};

const accessibilityHelper = {
  currentControls: [],

  setCurrentControls(controls) {
    try {
      if (!isNullOrUndefined(controls)) {
        this.currentControls = [];
        this.currentControls = this.currentControls.concat(
          getControlsWithTooltips(controls)
        );
      }
    } catch (ex) {
      ClientLogging.logError(ex, "accessibilityHelper setCurrentControls");
    }
  },

  getKeyboardAccessKey(controlName) {
    try {
      if (!isNullOrUndefined(this.currentControls) && this.currentControls.length > 0) {
        const index = this.currentControls.findIndex((el) => el.Name === controlName);
        return index === -1 ? null : keyboardAccessKeys[index];
      }
      return null;
    } catch (ex) {
      ClientLogging.logError(ex, "accessibilityHelper getKeyboardAccessKey");
    }
  },

  getAutoCompleteAttribute(controlName) {
    try {
      if (!isNullOrUndefined(autocompleteMappings[controlName]))
        return autocompleteMappings[controlName];
      return null;
    } catch (ex) {
      ClientLogging.logError(ex, "accessibilityHelper getAutoCompleteAttribute");
    }
  },

  // prevents default and returns true if 'Enter' or 'Space' is pressed.
  isEnterOrSpaceKey(event) {
    if (event.key === "Enter" || event.code === "Space") {
      event.preventDefault();
      return true;
    } else return false;
  },

  // called onKeyDown to replicate clicking an element.
  replicateClickAction(event, elementId) {
    if (this.isEnterOrSpaceKey(event)) {
      document.getElementById(elementId).click();
    }
  },

  // called onKeyDown to execute a desired function.
  executeCallbackFunction(event, callbackFn, params) {
    if (this.isEnterOrSpaceKey(event)) {
      callbackFn(params || event);
    }
  },
};

export default accessibilityHelper;
