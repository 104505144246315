import DOMPurify from "dompurify";
import React from "react";
import AppStore from "../../../store/AppStore";

class Info extends React.Component {
  render() {
    const processInfoMessage = (info) => {
      let infoMessage = info ? info.message : "";
      let infoType;
      if (infoMessage) {
        infoType = info.infoType;
      }

      let visibilityClass = info !== "" ? "d-block" : "d-none";
      let inheritClass = this.props.className ? this.props.className : "";

      const settings = AppStore.getUserJourneySettings();
      const hasInfoIcon = settings.hasInfoIcons ? settings.hasInfoIcons : false;

      return (
        <React.Fragment>
          {hasInfoIcon && (
            <>
              {info?.title && <label className={"info info-title"}>{info.title}</label>}
              <label
                className={`info ${inheritClass} ${visibilityClass}`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(infoMessage, {
                    USE_PROFILES: { html: true },
                    ADD_TAGS: ["a", "p", "div", "span"],
                    ADD_ATTR: ["href", "target", "name", "class"],
                  }),
                }}
              />
            </>
          )}
          {!hasInfoIcon && (
            <div
              className={`${visibilityClass} info-container ${infoType ? infoType : ""}`}>
              <div className={"info-image"}></div>
              <div className={"info-content"}>
                {info?.title && <label className={"info info-title"}>{info.title}</label>}
                <label
                  className={`info ${inheritClass}`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(infoMessage, {
                      USE_PROFILES: { html: true },
                      ADD_TAGS: ["a", "p", "div", "span"],
                      ADD_ATTR: ["href", "target", "name", "class"],
                    }),
                  }}></label>
              </div>
            </div>
          )}
        </React.Fragment>
      );
    };

    if (Array.isArray(this.props.infoMessages)) {
      let returnArray = [];
      this.props.infoMessages.forEach((m, idx) => {
        returnArray.push(
          <React.Fragment key={idx + (m.title || "")}>
            {processInfoMessage(m)}
          </React.Fragment>
        );
      });
      return returnArray;
    } else {
      return processInfoMessage(this.props.infoMessages);
    }
  }
}

export default Info;
