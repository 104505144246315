import helpers from "../helpers/destructure";
import Validator from "../helpers/validatorWrapper";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";

class FormValidator {
  validationResult = new ValidationResult();

  constructor() {}

  getIsValid(Validator, rule, value) {
    return typeof Validator[rule.method](value, rule.args, rule.message) === "boolean"
      ? Validator[rule.method](value, rule.args, rule.message)
      : Validator[rule.method](value, rule.args, rule.message).isValid;
  }

  getErrorMessage(Validator, rule, value) {
    return typeof Validator[rule.method](value, rule.args, rule.message) === "boolean"
      ? rule.message
      : Validator[rule.method](value, rule.args, rule.message).message;
  }

  updateValidationResult(Validator, rule, value, validatorKey, submitSender = "") {
    const validatorValid = this.getIsValid(Validator, rule, value);
    const errorMessage = this.getErrorMessage(Validator, rule, value);
    const shouldSkip = rule.args?.skipIfSubmitSender
      ? rule.args.skipIfSubmitSender.includes(submitSender)
      : false;

    if (!shouldSkip && validatorValid !== rule.validWhen) {
      this.validationResult.setValid(false);
      this.validationResult.addField({
        [validatorKey]: errorMessage,
      });
    }
  }

  //use this method to get the result for one or more given validation methods for a field
  validateRules = (field, exclusion) => {
    this.validationResult = new ValidationResult();
    const f = helpers.destructure(field);
    const validatorKey = `validator-${f.key}`;

    try {
      AppStore.getValidationRules()
        .filter((p) => {
          let excludeOnSet;
          if (p.field !== f.key) return false;

          const pArgsExists = p.args && p.args.excludeOn;

          if (pArgsExists) {
            excludeOnSet = p.args.excludeOn.split(",");
            return !excludeOnSet.includes(exclusion);
          } else return true;
        })
        .forEach((rule) => {
          this.updateValidationResult(Validator, rule, f.value, validatorKey);
        });
      return this.validationResult;
    } catch (e) {
      ClientLogging.logError("validationService -> missing or mistyped ruleName", e);
    }
  };

  validateField = (field) => {
    this.validationResult = new ValidationResult();

    const f = helpers.destructure(field);
    const validatorKey = `validator-${f.key}`;

    try {
      AppStore.getValidationRules()
        .filter((p) => p.field === f.key)
        .forEach((rule) => {
          this.updateValidationResult(Validator, rule, f.value, validatorKey);
        });
      return this.validationResult;
    } catch (e) {
      ClientLogging.logError("validationService -> error when validating fields", e);
    }
  };

  validateForm = (formData, submitSender) => {
    try {
      const visibControls = AppStore.getVisibilityControls();

      for (let [key, value] of Object.entries(formData)) {
        const validatorKey = `validator-${key}`;
        const controlVisibility = visibControls.get(key);

        if (
          AppStore.liveValidation &&
          (AppStore.getControlsToAvoidValidation()[validatorKey] ||
            controlVisibility === false)
        ) {
          continue;
        }

        AppStore.getValidationRules()
          .filter((p) => p.field === key)
          .forEach((rule) => {
            this.updateValidationResult(
              Validator,
              rule,
              value,
              validatorKey,
              submitSender
            );
          });
      }

      return this.validationResult;
    } catch (e) {
      ClientLogging.logError("validationService -> error when validating form", e);
    }
  };
}

class ValidationResult {
  isValid = true;
  fields = [];

  addField = (field) => {
    let f = helpers.destructure(field);
    this.fields.push({
      [f.key]: f.value,
    });
  };

  setValid = (isValid) => {
    this.isValid = isValid;
  };
}

export default FormValidator;
