import React, { Component } from "react";

import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";
import AppLink from "../../AppLink";

class BulletNavBar extends Component {
  state = {
    pagesToDisplay: this.props.pages.filter((p) => p.showInNavigation === true),
  };

  render() {
    const { pages } = this.props;

    let currentPage = {
      orderNo: 1,
    };

    const page = pages.find(
      (p) =>
        p.url.toLowerCase() ===
        window.location.pathname
          .replace(`${import.meta.env.VITE_SITE_ROUTE}/`, "")
          .toLowerCase()
    );
    if (page !== undefined) {
      currentPage = page;
    }
    let cssClases = "nav-link";
    let toLink = "";

    const isAdditionalDriver = (() => {
      const prevPage = AppStore.getDetectedPrevPage();
      return (
        prevPage === "additionaldriverdetails" ||
        prevPage === "additionalriderinformation"
      );
    })();

    const additionalDriverClickable = (() => {
      let existsAddDriver =
        AppStore.getCurrentDriver() > 0 ||
        AppStore.getControlByName("joint-policy") === "IN";
      return existsAddDriver;
    })();

    let conditionalPagesToDisplay = [];

    this.state.pagesToDisplay.forEach((p) => {
      const showInNavigationConditional = p.pageSettings.showInNavigationConditional;
      if (!isNullOrUndefined(showInNavigationConditional)) {
        const conditionalControl = showInNavigationConditional.split("|")[0];
        const conditionalValue = showInNavigationConditional.split("|")[1].split(",");
        const formDataConditionalValue = AppStore.getControlByName(conditionalControl);
        if (
          !isNullOrUndefined(formDataConditionalValue) &&
          conditionalValue.includes(formDataConditionalValue)
        ) {
          conditionalPagesToDisplay.push(p);
        }
      } else {
        conditionalPagesToDisplay.push(p);
      }
    });

    return (
      <div
        data-testid="bulet-nav-bar"
        className="nav-container container mt-2 col-12 col-lg-8 col-md-8 mx-auto mb-4 ">
        <nav className="navbar-bullet navbar-light col-lg-12 col-md-12 col-sm-12 col-12">
          <ul className="nav-bullet nav-pills d-flex mx-auto justify-content-center">
            {pages
              .filter((p) => p.showInNavigation === true)
              .map((p, index) => (
                <li
                  key={p.url}
                  className={`nav-item nav-item-${p.title
                    .replace(/\s+/g, "")
                    .toLowerCase()} d-inline-flex p-0`}>
                  {(() => {
                    if (p.orderNo === currentPage.orderNo) {
                      cssClases = "nav-link active";
                      toLink = p.url;
                    } else if (p.orderNo <= currentPage.orderNo) {
                      if (
                        p.url === "additionaldriverdetails" ||
                        p.url === "additionalriderinformation"
                      ) {
                        if (
                          currentPage.url === "accidentinformation" ||
                          currentPage.url === "convictioninformation"
                        ) {
                          if (isAdditionalDriver) {
                            cssClases = "nav-link visited";
                            toLink = p.url;
                          } else {
                            cssClases = "nav-link disabled";
                            toLink = "";
                          }
                        } else {
                          if (additionalDriverClickable) {
                            cssClases = "nav-link visited";
                            toLink = p.url;
                          } else {
                            cssClases = "nav-link visited disabled";
                            toLink = "";
                          }
                        }
                      } else {
                        cssClases = "nav-link visited";
                        toLink = p.url;
                      }
                    } else {
                      cssClases = "nav-link disabled";
                      toLink = "";
                    }
                  })()}

                  <AppLink className={`${cssClases} col-12 p-0`} to={toLink}>
                    <span className={`nav-link-step-group col-12 d-flex p-0`}>
                      <span
                        className={`nav-link-delimiter nav-link-left-delimiter col-4 d-inline-block`}
                      />
                      <span className={`nav-link-no col-auto d-inline-block p-2`}>
                        {index + 1}
                      </span>
                      <span
                        className={`nav-link-delimiter nav-link-right-delimiter col-5 d-inline-block`}
                      />
                    </span>
                    <span
                      className={`nav-link-text col-12 d-xl-block d-lg-block d-md-block d-none text-center px-1`}>
                      {p.title}
                    </span>
                  </AppLink>
                </li>
              ))}
            <li
              key={`results`}
              className={`nav-item nav-item-results col-2 d-inline-flex p-0`}>
              <AppLink className={`nav-link col-12 p-0 disabled`} to={""}>
                <span className={`nav-link-step-group col-12 p-0 d-flex`}>
                  <span
                    className={`nav-link-delimiter nav-link-left-delimiter col-4 d-inline-block`}
                  />
                  <span className={`nav-link-no col-auto d-inline-block mx-auto p-2`}>
                    <span className={`nav-link-icon col-12 d-block mx-auto`}></span>
                  </span>
                  <span
                    className={`nav-link-delimiter nav-link-right-delimiter col-5 d-inline-block`}
                  />
                </span>
                <span
                  className={`nav-link-text col-12 d-xl-block d-lg-block d-md-block d-none text-center px-1`}>
                  Your Quotes
                </span>
              </AppLink>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default BulletNavBar;
