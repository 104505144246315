import { initDevTools } from "@src/devtools";
import { observer } from "mobx-react";
import React, { Component, Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Footer, Navigation } from ".";
import AppError from "../AppError";
import BoundaryFallback from "../BoundaryFallback";
import { getDeviceType } from "../helpers";
import { errorBoundaryLogError } from "../helpers/errorBoundary/logging";
import helper from "../helpers/googleAnalyticsHelper";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import { resolveStaticAssetPath } from "../helpers/staticAsset";
import { processTimeActions } from "../helpers/timeActions";
import pageSettingsJson from "../jsonsource/pagesSettings.json";
import userJourneySettingsJson from "../jsonsource/userjourneysettings.json";
import ClickStreamProvider from "../providers/ClickStreamProvider";
import SsoRedirectHandler from "../providers/ssoRedirectHandler";
import AppStore from "../store/AppStore";
import handleAffiliate from "../tracking/Affiliate";
import Floodlight from "../tracking/Floodlight";
import GTMComponent from "../tracking/GTMComponent";
import handleGclidParameter from "../tracking/GclidTracker";
import Permutive from "../tracking/Permutive";
import handleRefIdReferer from "../tracking/RefidReferer";
import { ErrorBoundary } from "./ErrorBoundary";
import Head from "./Head";
import Header from "./Header";
import AdUnit from "./controls/Ads/AdUnit.Component";
import DfpBg from "./controls/Ads/DfpBg.Component";
import DfpMpuOne from "./controls/Ads/DfpMpuOne.Component";
import LightBox from "./controls/Ads/LightBoxPopup.Component";
import GdprBanner from "./controls/CookieBanner/CookieBannerUniversal";
import QuoteSummaryHeader from "./controls/QuoteSummary/QuoteSummaryHeader";
import SideImage, {
  getSideImageBehavior,
} from "./controls/StaticControls/SideImage.Component";
import StaticImage from "./controls/StaticControls/StaticImage.Component";
import Banner from "./page-components/Banner";
import ButtonBanner from "./page-components/ButtonFooterBanner";
import SideBar from "./page-components/SideBar";

const DynamicForm = React.lazy(() => import("./DynamicForm"));

class App extends Component {
  constructor() {
    super();
    initDevTools();
    ClickStreamProvider.setCSIDValueInCookie();

    this.ssoRedirectHandler = new SsoRedirectHandler();

    GTMComponent.load();

    Floodlight.init(userJourneySettingsJson);

    if (
      !isNullOrUndefined(userJourneySettingsJson.UserJourneySettings.tracking) &&
      !isNullOrUndefined(userJourneySettingsJson.UserJourneySettings.tracking.permutive)
    )
      Permutive.init(userJourneySettingsJson.UserJourneySettings.tracking.permutive);

    AppStore.userData = {
      userJourneyStructure: {
        UserJourneySettings: userJourneySettingsJson.UserJourneySettings,
        FunctionalityConfiguration: userJourneySettingsJson.FunctionalityConfiguration,
        RefererTrackerConfiguration: userJourneySettingsJson.RefererTrackerConfiguration,
      },
    };

    AppStore.UserJourneySettings = userJourneySettingsJson.UserJourneySettings;
    AppStore.FunctionalityConfiguration =
      userJourneySettingsJson.FunctionalityConfiguration;
    AppStore.RefererTrackerSkinConfiguration =
      userJourneySettingsJson.RefererTrackerSkinConfiguration;
    AppStore.setEntryQueryString(window.location.search);
    AppStore.deviceType = getDeviceType();

    handleRefIdReferer(userJourneySettingsJson.RefererTrackerConfiguration);
    handleGclidParameter(userJourneySettingsJson.RefererTrackerConfiguration);
    handleAffiliate(userJourneySettingsJson.RefererTrackerConfiguration);

    const pages = [];
    const pageComponents = [];
    pageSettingsJson.forEach((item) => {
      pages.push({
        title: item.Title,
        url: item.Url,
        orderNo: item.OrderNo,
        showInNavigation: item.ShowInNavigation,
        pageSettings: item.PageSettings,
      });

      AppStore.pages.push({
        title: item.Title,
        url: item.Url,
        orderNo: item.OrderNo,
        showInNavigation: item.ShowInNavigation,
        pageSettings: item.PageSettings,
      });

      pageComponents.push({
        url: item.Url,
        title: item.Title,
        components: [],
        pageSettings: item.PageSettings,
      });

      AppStore.pageComponents.push({
        url: item.Url,
        title: item.Title,
        components: [],
        pageSettings: item.PageSettings,
      });
    });

    AppStore.userData.userJourneyStructure.Pages = pageSettingsJson;
    AppStore.theme = userJourneySettingsJson?.UserJourneyName?.toLowerCase() || "";
    AppStore.setUserInteractionState({ isLightboxVisible: false });

    // setting the liveValidation setting in the AppStore, based on UserJourneySettings.json
    AppStore.setLiveValidation(
      AppStore.getUserJourneySettings()?.generalSettings?.liveValidation || false
    );

    this.state = {
      currentPage: 1,
      currentPageName: "",
      currentPageUrl: "",
      currentPageSettings: {},
      pages,
      pageComponents,
      structureLoaded: false,
      theme: "",
      userJourneySettings: userJourneySettingsJson.UserJourneySettings,
    };
  }

  handleFormRendered = (index, url, title, pageSettings, loadAppStateCalled) => {
    this.setState({
      currentPage: index,
      currentPageName: title,
      currentPageUrl: url,
      currentPageSettings: pageSettings,
      structureLoaded: loadAppStateCalled,
    });
    AppStore.setCurrentPage(index);
    AppStore.setCurrentPageName(title);
    AppStore.setDetectedPrevPage({ route: url });

    Permutive.handleNewPage();

    if (AppStore.getUserJourneySettings()?.tracking?.pushOldPlatformData === "true") {
      helper.pushOldPlatformData();
    }

    if (!isNullOrUndefined(AppStore.userData.visitorId)) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ QuotePlatformVisitId: AppStore.userData.visitorId });
    }

    Floodlight.handleNewPage();

    const timeActions = AppStore.getUserJourneySettings()?.timeActions;
    if (timeActions) {
      processTimeActions(timeActions);
    }
  };

  render() {
    const {
      currentPageName,
      userJourneySettings,
      currentPageSettings,
      theme,
      pages,
      structureLoaded,
      pageComponents,
      currentPage,
    } = this.state;
    this.ssoRedirectHandler.handleSsoRedirect();
    const currentPageCssClass = currentPageName.toLowerCase().replace(/ /g, "");
    const extrapPagesCssClass =
      !isNullOrUndefined(userJourneySettings.generalSettings) &&
      !isNullOrUndefined(userJourneySettings.generalSettings.extraPages) &&
      userJourneySettings.generalSettings.extraPages
        .split(",")
        .includes(currentPageCssClass)
        ? "extrapage"
        : "";
    const wireframe = currentPageSettings.wireframeType;
    const footerEnabled = wireframe !== "basic" || currentPageSettings.footerEnabled;
    const contentCssClass =
      wireframe === "form"
        ? `col-12 col-md-9 bd-content-${wireframe}`
        : `col-12 col-md-12 col-sm-12 col-lg-12 bd-content-${wireframe}`;
    const sideBarVersion =
      !isNullOrUndefined(userJourneySettings.sidebar) &&
      !isNullOrUndefined(userJourneySettings.sidebar.version)
        ? userJourneySettings.sidebar.version
        : "";
    const mobileRetrieveLinkCssClass =
      !isNullOrUndefined(currentPageSettings.displayRetrieveLink) &&
      currentPageSettings.displayRetrieveLink === "true"
        ? `mobile-retrieve-link d-md-block d-sm-block d-block ${
            sideBarVersion === "v2" ? "d-lg-block" : "d-lg-none"
          }`
        : "mobile-retrieve-link d-none";
    const isQuoteSummary = !!currentPageCssClass.includes("quotesummary");
    const isResultsPage = !!currentPageCssClass.includes("yourquotes");
    const displayPageTitle = !isNullOrUndefined(currentPageSettings.displayPageTitle)
      ? currentPageSettings.displayPageTitle
      : currentPageName;
    const userJourneyNavType =
      !isNullOrUndefined(userJourneySettings.navigation) &&
      !isNullOrUndefined(userJourneySettings.navigation.navType)
        ? userJourneySettings.navigation.navType
        : "standard";
    const resultsPageWidth =
      !isNullOrUndefined(userJourneySettings.resultsLayout) &&
      !isNullOrUndefined(userJourneySettings.resultsLayout.width) &&
      isResultsPage
        ? userJourneySettings.resultsLayout.width
        : "";
    const retrieveQuoteTitle =
      !isNullOrUndefined(userJourneySettings.sidebar) &&
      !isNullOrUndefined(userJourneySettings.sidebar.retrieveQuoteTitle)
        ? userJourneySettings.sidebar.retrieveQuoteTitle
        : "Already got a quote?";
    const retrieveQuoteLabel =
      !isNullOrUndefined(userJourneySettings.sidebar) &&
      !isNullOrUndefined(userJourneySettings.sidebar.retrieveQuoteLabel)
        ? userJourneySettings.sidebar.retrieveQuoteLabel
        : "Retrieve your quote here";

    const amendUrl =
      !isNullOrUndefined(userJourneySettings.generalSettings) &&
      !isNullOrUndefined(userJourneySettings.generalSettings.amendUrl)
        ? userJourneySettings.generalSettings.amendUrl
        : "";
    const resultsAdsProps = !isNullOrUndefined(
      AppStore.getUserJourneySettings().resultsAds
    )
      ? AppStore.getUserJourneySettings().resultsAds
      : "";
    const showDFPBG = !!(
      !isNullOrUndefined(resultsAdsProps) &&
      !isNullOrUndefined(resultsAdsProps.DFPBGEnabled) &&
      resultsAdsProps.DFPBGEnabled === "true"
    );
    const showDFPMPUOne = !!(
      !isNullOrUndefined(resultsAdsProps) &&
      !isNullOrUndefined(resultsAdsProps.DFPMPUOneEnabled) &&
      resultsAdsProps.DFPMPUOneEnabled === "true"
    );
    const skinAdUnits = AppStore.getSkinAdUnits();
    let skinHasActiveAds = false;
    if (!isNullOrUndefined(skinAdUnits)) {
      skinHasActiveAds = true;
    }
    const showSideBar =
      !isNullOrUndefined(userJourneySettings.sidebar) &&
      (userJourneySettings.sidebar.show === "false" ||
        userJourneySettings.sidebar.show === false)
        ? false
        : wireframe === "form"
        ? true
        : false;

    const sideImageBehavior = getSideImageBehavior(
      userJourneySettings.sidebar?.sideImage,
      this.state.currentPageUrl,
      showSideBar && !AppStore.hiddenSpecials.has("sideImage")
    );
    const staticImageEnabled = currentPageSettings?.staticImage?.enabled;

    return (
      <BrowserRouter>
        <ErrorBoundary
          FallbackComponent={BoundaryFallback}
          onError={errorBoundaryLogError}>
          <Head
            themeName={theme}
            appTitle={currentPageSettings.title}
            metaDescription={currentPageSettings.description}
          />
          <div
            className={`page-group ${currentPageCssClass} ${extrapPagesCssClass} ${resultsPageWidth}`}>
            {wireframe !== "basic" && (
              <Header
                header_info={userJourneySettings.header}
                isResultsPage={currentPageCssClass}
                amendUrl={amendUrl}
              />
            )}
            {wireframe === "basic" && isQuoteSummary && <QuoteSummaryHeader />}
            <ErrorBoundary
              FallbackComponent={BoundaryFallback}
              onError={errorBoundaryLogError}>
              {wireframe === "form" && extrapPagesCssClass === "" && (
                <Navigation pages={pages} type={userJourneyNavType} />
              )}
              {currentPageSettings.banner === "true" && (
                <Banner bannerText={currentPageSettings.banner_text} />
              )}
              <div
                className={`container bd-container mt-2 container-${currentPageCssClass} ${
                  sideImageBehavior.isRevealForOuterWrapper ? "overflow-hidden" : ""
                } ${
                  sideImageBehavior.isOverlapsWithInfoContainerOnMobile
                    ? "has-info-container-overlap-on-mobile"
                    : ""
                }`}>
                {sideImageBehavior.isRevealForOuterWrapper && (
                  <div className="d-block d-lg-none">
                    <SideImage sideImageUjCfg={sideImageBehavior.sideImageUjCfg} />
                  </div>
                )}
                <div className={`row row-${currentPageCssClass}`}>
                  <div
                    className={`bd-content ${contentCssClass}  d-inline-block pull-left`}>
                    <div
                      className={`wrapper dynamic-form-wrapper col-12 ${
                        sideImageBehavior.isRevealForInnerWrapper ? "overflow-hidden" : ""
                      }`}>
                      {sideImageBehavior.isRevealForInnerWrapper && (
                        <div className="d-block d-lg-none">
                          <SideImage sideImageUjCfg={sideImageBehavior.sideImageUjCfg} />
                        </div>
                      )}

                      <div className="dynamic-form-wrapper pt-2 pb-4">
                        {!isNullOrUndefined(currentPageSettings.displayRetrieveLink) &&
                          currentPageSettings.displayRetrieveLink === "true" && (
                            <div className={mobileRetrieveLinkCssClass}>
                              <p className="text-center title d-inline-block mr-3">
                                {retrieveQuoteTitle}
                              </p>
                              <a
                                href={`${import.meta.env.VITE_SITE_ROUTE}/default`}
                                className="button-container d-inline-block p-0">
                                <p className="btn btn-link btn-retrieve-quote text-center">
                                  {retrieveQuoteLabel}
                                </p>
                                {!isNullOrUndefined(
                                  AppStore.getUserJourneySettings().sidebar
                                    ?.button_retrieve_label_mobile
                                )
                                  ? AppStore.getUserJourneySettings().sidebar
                                      ?.button_retrieve_label_mobile
                                  : ""}
                                <i
                                  className={`fa fas ${
                                    sideBarVersion === "v2"
                                      ? "fa-arrow-right"
                                      : "fa-chevron-right"
                                  } ml-2`}
                                />
                              </a>
                            </div>
                          )}
                        {staticImageEnabled && (
                          <StaticImage currentPageName={currentPageName} />
                        )}
                        {displayPageTitle !== "" && (
                          <div className="form-group page-title pt-2 pb-4 title">
                            {displayPageTitle.includes("%%PROPOSER%%")
                              ? displayPageTitle.replace(
                                  "%%PROPOSER%%",
                                  AppStore.getControlByName("first-name")
                                )
                              : displayPageTitle}
                          </div>
                        )}
                        <Route
                          key="0"
                          path={`${import.meta.env.VITE_SITE_ROUTE}/`}
                          exact
                          render={() => (
                            <Suspense
                              fallback={
                                <div>
                                  <span>Loading please wait....</span>
                                  <img
                                    src={resolveStaticAssetPath(
                                      userJourneySettings.generalSettings.loadingImagePath
                                    )}
                                    alt="bike insurance loading"
                                  />
                                  <span>Loading please wait....</span>
                                </div>
                              }>
                              <DynamicForm
                                model={pages[0]}
                                pageIndex={1}
                                formRendered={this.handleFormRendered}
                                structureLoaded={structureLoaded}
                              />
                            </Suspense>
                          )}
                        />
                        <Route
                          path={`${import.meta.env.VITE_SITE_ROUTE}/appError`}
                          component={AppError}
                          exact
                          render={() => (
                            <Suspense
                              fallback={
                                <div>
                                  <span>Loading please wait....</span>
                                  <img
                                    src={resolveStaticAssetPath(
                                      userJourneySettings.generalSettings.loadingImagePath
                                    )}
                                    alt="bike insurance loading"
                                  />
                                  <span>Loading please wait....</span>
                                </div>
                              }
                            />
                          )}
                        />
                        {pages.map((page) => {
                          const listOfPaths = [];
                          if (!isNullOrUndefined(page.pageSettings.CompatibilityPaths)) {
                            listOfPaths.push(
                              `${import.meta.env.VITE_SITE_ROUTE}/${page.url}`
                            );
                            page.pageSettings.CompatibilityPaths.forEach((p) =>
                              listOfPaths.push(`${import.meta.env.VITE_SITE_ROUTE}/${p}`)
                            );
                          } else {
                            listOfPaths.push(
                              `${import.meta.env.VITE_SITE_ROUTE}/${page.url}`
                            );
                          }

                          return (
                            <Route
                              key={page.orderNo}
                              path={listOfPaths}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <div>
                                      <span>Loading please wait....</span>
                                      <img
                                        src={resolveStaticAssetPath(
                                          userJourneySettings.generalSettings
                                            .loadingImagePath
                                        )}
                                        alt="bike insurance loading"
                                      />
                                    </div>
                                  }>
                                  <DynamicForm
                                    model={pages[page.orderNo - 1]}
                                    pageIndex={page.orderNo}
                                    formRendered={this.handleFormRendered}
                                    structureLoaded={structureLoaded}
                                  />
                                </Suspense>
                              )}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {showSideBar && (
                    <div className="col-12 col-md-3 bd-sidebar main-form d-inline-block pull-right">
                      {!userJourneySettings.sidebar.hideMainContent && (
                        <SideBar sidebar_info={userJourneySettings.sidebar} />
                      )}
                      {showDFPMPUOne && <DfpMpuOne />}
                      {import.meta.env.VITE_ADS_ENABLED && skinHasActiveAds && (
                        <AdUnit
                          currentPage={pageComponents[currentPage]}
                          slotName="QSALL_MPU_ONE"
                          cssClass="mpu-one"
                        />
                      )}
                      {sideImageBehavior.canRender && (
                        <div className="d-none d-lg-block">
                          <SideImage sideImageUjCfg={sideImageBehavior.sideImageUjCfg} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {import.meta.env.VITE_ADS_ENABLED && skinHasActiveAds && (
                  <AdUnit
                    currentPage={pageComponents[currentPage]}
                    slotName="QSALL_BB"
                    cssClass="bb"
                  />
                )}
                {showDFPBG && <DfpBg />}
              </div>
              {!isNullOrUndefined(wireframe) && structureLoaded && (
                <LightBox
                  currentPage={pageComponents[currentPage]}
                  currentPageIndex={currentPage}
                  slotName="LIGHTBOX"
                />
              )}

              {AppStore.getUserJourneySettings()?.navigation?.usesButtonBanner &&
                currentPageSettings.buttonBanner && (
                  <ButtonBanner
                    attributes={currentPageSettings?.buttonBanner?.attributes}
                  />
                )}
            </ErrorBoundary>
            <GdprBanner />
            {footerEnabled && (
              <Footer
                footerInfo={userJourneySettings.footer}
                isResultsPage={currentPageCssClass}
              />
            )}
          </div>
        </ErrorBoundary>
      </BrowserRouter>
    );
  }
}
export default observer(App);
