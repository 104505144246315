import DOMPurify from "dompurify";
import { observer } from "mobx-react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { isNullOrUndefined } from "../../../helpers/isNullOrUndefined";
import AppStore from "../../../store/AppStore";

const Label = forwardRef(
  (
    {
      id,
      label,
      forInput, // added for live validation
      className,
      validation, // added for live validation
      defaultValue, // added for live validation
      hasIcon = true, // default to true based on existing components
    },
    ref
  ) => {
    const isCompletedField = AppStore.getHasChanged(forInput); // checking if the parent control has previously been changed, using the hasChanged{} dictionary.
    const hasDefaultValue = !isNullOrUndefined(defaultValue) && defaultValue != ""; // checking if the parent control has a default value - this control will always be valid.

    const inheritCSS = !isNullOrUndefined(className)
      ? className
      : "qs-label d-inline-block col-lg-10 col-md-10 col-sm-10 col-10";

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    const getLabelToDisplay = () => {
      if (!Array.isArray(label)) return label;

      const userData = AppStore.getUserData();

      if (Object.keys(userData).length > 0) {
        let collectedQuoteDataParsed = JSON.parse(userData.collectedQuoteData);
        collectedQuoteDataParsed = Array.isArray(collectedQuoteDataParsed)
          ? Object.fromEntries(collectedQuoteDataParsed)
          : collectedQuoteDataParsed;

        return !isNullOrUndefined(
          collectedQuoteDataParsed["years-licence-held-additional"]
        )
          ? label[1]
          : label[0];
      }
    };

    useEffect(() => {
      setHeight(elementRef.current.clientHeight);
    }, []);

    let heightClass = "height-1";
    if (parseInt(height) > 26 && parseInt(height) < 77) {
      heightClass = "height-2";
    }
    if (parseInt(height) > 77) {
      heightClass = "height-3";
    }
    if (parseInt(height) > 100) {
      heightClass = "height-4";
    }

    const isValidTickClass =
      (hasDefaultValue || isCompletedField) && validation && validation.isValid
        ? " valid"
        : " hidden";
    const iconNode = hasIcon ? (
      <i
        className={`valid-tick${isValidTickClass}`}
        data-testid={`test-tick-${forInput}`}
      />
    ) : null;

    return (
      <React.Fragment>
        <div className={`${inheritCSS} ${heightClass}`} ref={elementRef}>
          {/* the <i> element is used to display the 'tick' that is shown next to a valid field. We define when it is shown below. */}
          {/* we define what is looks like in the CSS files. */}
          {iconNode}
          <label
            htmlFor={forInput}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(getLabelToDisplay(), {
                USE_PROFILES: { html: true },
                ADD_TAGS: ["a", "p", "div", "span"],
                ADD_ATTR: ["href", "target", "name", "class"],
              }),
            }}
            ref={ref}></label>
        </div>
      </React.Fragment>
    );
  }
);

export default observer(Label);
