import dayjs from "dayjs";
import validator from "validator";
import dateParser from "../helpers/dateParser";
import { isNullOrUndefined } from "../helpers/isNullOrUndefined";
import ClientLogging from "../providers/loggingProvider";
import AppStore from "../store/AppStore";

const Validator = {
  isEmpty: (input) => {
    if (input === undefined) return false;

    try {
      return validator.isEmpty(input);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: empty");
      return false;
    }
  },
  isEmptyIterable: (input) => {
    try {
      return input.length === 0;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: isEmptyIterable");
      return false;
    }
  },
  isEmail: (input) => {
    try {
      return validator.isEmail(input);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: email");
      return false;
    }
  },
  isValidAgainstEmail: (input, args) => {
    try {
      let againstValue = input.replace(" ", "");
      let fieldValue = AppStore.getControlByName(args.against).replace(" ", "");

      return validator.matches(fieldValue, againstValue);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against email");
      return false;
    }
  },
  isMobilePhone: (input, args) => {
    try {
      let locale = args.localization;
      let regex = args.regex;

      if (input.startsWith("0044") && validator.matches(input, regex))
        //special case for 00447777777777
        return true;
      else return validator.isMobilePhone(input, locale); //regular case
    } catch (ex) {
      ClientLogging.logError(ex, "validator: mobile");
      return false;
    }
  },
  isValidDate: (input) => {
    try {
      return validator.isISO8601(input, { strict: true });
    } catch (ex) {
      ClientLogging.logError(ex, "validator: valid date");
      return false;
    }
  },
  matches: (input, args) => {
    try {
      let regex = args.regex;
      return validator.matches(input, regex);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: matches regex");
      return false;
    }
  },
  isChecked: (input) => {
    try {
      if (input === true || input === "true" || input === "on") return true;
      else return false;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: is checked");
      return false;
    }
  },
  isInInterval: (input, args) => {
    try {
      if (input === null) return true;

      let conversionMethod = parseInt;
      if (!isNullOrUndefined(args.valueType)) {
        if (args.valueType === "float" || args.valueType === "double")
          conversionMethod = parseFloat;
      }

      let levelValue = conversionMethod(input);
      let upper = conversionMethod(args.upper);
      let lower = conversionMethod(args.lower);
      if (levelValue >= lower && levelValue < upper) return true;
      else return false;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: is in interval");
      return false;
    }
  },
  isValidAgainstDOB: (input, args, message) => {
    try {
      let dobValue = AppStore.getControlByName(args);
      let value = input;
      let licenceType =
        !isNullOrUndefined(args) && args === "date-of-birth-additional"
          ? AppStore.getControlByName("driving-licence-additional")
          : AppStore.getControlByName("driving-licence");

      message = formatMessage(licenceType, message);

      if (dobValue > value) return { isValid: false, message: message };
      else if (dobValue < value) return { isValid: true, message: message };
      else return { isValid: true, message: message };
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against date of birth");
      return false;
    }
  },
  isNumeric: (input) => {
    try {
      return validator.isNumeric(input);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: is numeric");
      return false;
    }
  },
  postcodeFindMyAddressNotClicked: (input, args) => {
    try {
      if (AppStore.getControlByName("button-address-is-manual")) return true;

      //TBI v3 user journey has more than one args for this validation
      let controlToCheck = args.against !== undefined ? args.against : args;
      let controlValue = AppStore.getControlByName(controlToCheck);
      let isPostcodeButtonClicked = controlValue !== undefined;

      return isPostcodeButtonClicked;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: postcode search");
      return false;
    }
  },
  postcodeValidationAgainstDropdownSelection: (input, args) => {
    try {
      let baseRegex = args.regex;
      let postcodeRegex = args.postcodeRegex;
      let postcodeDropdownControlName = args.against;

      let postcodeDropdownControl = AppStore.getControlByName(
        postcodeDropdownControlName
      );

      if (postcodeDropdownControl === undefined) {
        return false;
      }

      if (postcodeDropdownControl === "AddressNotListed") {
        let regex = new RegExp(baseRegex);
        return regex.test(input); // njsscan-ignore: dcd15e73b626e01eaa35f0dc43b4bf4c3df06ebcac27cd8bbd6035ea8fde4de9
      } else if (!postcodeDropdownControl.toUpperCase().includes(input.toUpperCase())) {
        return false;
      } else {
        let regex = new RegExp(postcodeRegex);
        return regex.test(input.toUpperCase()); // njsscan-ignore: df9040ecc526f7e9a2565d395736ec471a57800a6734d8b55c2ef73bc8dd7775
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: postcode selection");
      return false;
    }
  },
  isValidAgainstCurrentDate: (input, args) => {
    try {
      let currentDateValue = dayjs().format("YYYY-MM-DD");
      let value = input;
      let isEqualValidWhen = !isNullOrUndefined(args) ? args.isEqualValidWhen : "";
      if (currentDateValue > value) return false;
      else if (currentDateValue < value) return true;
      else {
        if (isEqualValidWhen !== "" && isEqualValidWhen === "false") {
          return false;
        }
        return true;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against current date");
      return false;
    }
  },
  isValidDateLicenceObtained: (input) => {
    try {
      let currentDateValue = dayjs().format("YYYY-MM-DD");
      let value = input;
      return currentDateValue >= value;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: date licence obtained");
      return false;
    }
  },
  isValidLicenceNumberFirstPart: (input, args) => {
    const section1 = input.substr(0, 5);
    const regexp = new RegExp(args.regex);

    return regexp.test(section1);
  },
  isValidLicenceNumberSecondPart: (input, args) => {
    const section2 = input.substr(5, 6);
    const regexp = new RegExp(args.regex);

    return regexp.test(section2);
  },
  isValidLicenceNumberThirdPart: (input, args) => {
    const section3 = input.substr(11, 2);
    const regexp = new RegExp(args.regex);

    return regexp.test(section3);
  },
  isValidLicenceNumberFourthPart: (input, args) => {
    const section4 = input.substr(13, 3);
    const regexp = new RegExp(args.regex);

    return regexp.test(section4);
  },
  isValidCarLicenceDate: (input, args) => {
    try {
      let currentDateValue = dayjs().format("YYYY-MM-DD");
      let value = input;
      let isEqualValidWhen = !isNullOrUndefined(args) ? args.isEqualValidWhen : "";

      if (currentDateValue < value) return false;
      else if (currentDateValue > value) return true;
      else {
        if (isEqualValidWhen !== "" && isEqualValidWhen === "false") {
          return false;
        }
        return true;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: car licence against current date");
      return false;
    }
  },
  isValidAgainstOccupationType: (input, args) => {
    try {
      let value = input.toString().toLowerCase();
      let occupationTypeValue = args.value;
      let valuesNotAcceptedForEmployed = [
        "student",
        "househusband",
        "not in employment",
        "retired",
        "unemployed",
      ];
      if (occupationTypeValue === "E" || occupationTypeValue === "SE") {
        if (valuesNotAcceptedForEmployed.indexOf(value) === -1) {
          return true;
        } else return false;
      }
      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against occupation type");
      return false;
    }
  },
  isValidAgainstCodeList: (input, args) => {
    return true;
  },
  isValidAgainstItemsList: (input, args) => {
    try {
      let value = input.toString();
      let listControl = args.against.split("|");
      if (value === "yes") {
        listControl.forEach((item) => {
          if (!isNullOrUndefined(AppStore.getControlByName(item))) {
            return false;
          }
        });
      }
      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against items list");
      return false;
    }
  },
  isValidAgainstProposerMaritalStatus: (input, args) => {
    try {
      let value = input.toString();
      let maritalStatusControlName = args.against;
      let maritalStatusValue = AppStore.getControlByName(maritalStatusControlName);
      let maritalStatusAdditional = AppStore.getControlByName(
        "marital-status-additional"
      );
      const commonLawValues = ["CommonLawPartner", "CommonLaw"];
      if (
        maritalStatusValue === "Married" &&
        (value === "CLP" || value === "CP" || value === "C")
      ) {
        return false;
      } else if (
        maritalStatusValue === "Single" &&
        (value === "CLP" || value === "CP" || value === "C" || value === "S")
      ) {
        return false;
      } else if (
        maritalStatusValue === "CivilPartnered" &&
        (value === "CLP" || value === "C" || value === "S")
      ) {
        return false;
      } else if (
        maritalStatusValue === "Widowed" &&
        (value === "CLP" || value === "C" || value === "S" || value === "CP")
      ) {
        return false;
      } else if (
        maritalStatusValue === "Divorced" &&
        (value === "CLP" || value === "C" || value === "CP" || value === "S")
      ) {
        return false;
      } else if (
        (maritalStatusValue === "CommonLawPartner" ||
          maritalStatusValue === "CommonLaw") &&
        (value === "CP" || value === "S" || value === "C" || value === "CLP") &&
        !commonLawValues.includes(maritalStatusAdditional)
      ) {
        return false;
      }
      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against proposer marital status");
      return false;
    }
  },
  homeIsValidAgainstProposerMaritalStatus: (input, args, message) => {
    try {
      let returnedObj = { isValid: true, message: message };
      const value = input.toString();
      const maritalStatusControlName = args.against;
      const marriedValue = args.marriedValue;
      const CPValue = args.CPValue;
      let maritalStatusValue = AppStore.getControlByName(maritalStatusControlName);

      if (maritalStatusValue != "Married" && value == "Spouse") {
        returnedObj["message"] = message
          .replace("%%PROPOSERVALUE%%", marriedValue)
          .replace("%%VALUE%%", value);
        returnedObj["isValid"] = false;
      } else if (maritalStatusValue != "CivilPartnered" && value == "Civil partner") {
        returnedObj["message"] = message
          .replace("%%PROPOSERVALUE%%", CPValue)
          .replace("%%VALUE%%", value);
        returnedObj["isValid"] = false;
      } else {
        returnedObj["isValid"] = true;
      }

      return returnedObj;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against proposer marital status");
      return { isValid: false, message: message };
    }
  },
  isValidAgainstMaritalStatus: (input, args) => {
    try {
      let value = input.toString();
      let maritalStatusControlName = args.against;
      let maritalStatusValue = AppStore.getControlByName(maritalStatusControlName);
      const commonLawValues = ["CommonLawPartner", "CommonLaw"];

      if (
        value === "S" &&
        maritalStatusValue !== "Married" &&
        maritalStatusValue !== ""
      ) {
        return false;
      } else if (
        value === "CLP" &&
        !commonLawValues.includes(maritalStatusValue) &&
        maritalStatusValue !== ""
      ) {
        return false;
      } else if (
        value === "CP" &&
        maritalStatusValue !== "CivilPartnered" &&
        maritalStatusValue !== ""
      ) {
        return false;
      } else return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against marital status");
      return false;
    }
  },
  isValidAgainstOtherDrivers: (input, args) => {
    try {
      let value = input.toString();
      let driversListControlName = args.against;
      let driversList = AppStore.getControlByName(driversListControlName);
      let proposerHasSpouse = false;
      if (Array.isArray(driversList) && driversList !== "" && driversList.length > 1) {
        driversList.forEach(function (driver) {
          let relationToProposerValue = driver["relation-to-proposer"];
          if (value === "S" && relationToProposerValue === "S") {
            proposerHasSpouse = true;
            return false;
          }
        });
      }
      if (proposerHasSpouse === true) {
        return false;
      } else return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against other drivers");
      return false;
    }
  },
  isValidAgainstYrsLicenceHeld(input, args, message) {
    try {
      let returnedObj = { isValid: true, message: message };
      let value = parseInt(input);
      let currentDateValue = dayjs().format("YYYY-MM-DD");

      let licenceTimeControlName = args.against;
      let licenceDateValue = AppStore.getControlByName(licenceTimeControlName);

      let liecenceYearsNumber = dateParser.getDateDiffInYears(
        licenceDateValue,
        currentDateValue
      );

      let ncdDate = dayjs().subtract(value, "years").format("YYYY-MM-DD");

      returnedObj["message"] = message.replace(
        /%YEARS%/g,
        liecenceYearsNumber.toString()
      );
      if (ncdDate < licenceDateValue) {
        returnedObj["isValid"] = false;
      } else {
        returnedObj["isValid"] = true;
      }
      return returnedObj;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against years licence held");
      return { isValid: false, message: message };
    }
  },
  isValidAge(input, args) {
    try {
      let minAgeAllowed = args.minimumAgeAllowed;
      let currentDateValue = dayjs();
      let diffDate = dateParser.getDateDiffInYears(input, currentDateValue);
      if (diffDate < parseInt(minAgeAllowed)) {
        return false;
      } else {
        return true;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: valid age");
      return false;
    }
  },
  isValidAgainstAge(input, args, message) {
    try {
      let returnedObj = { isValid: true, message: message };
      const minAgeAllowed = args.minimumAgeAllowed;
      const minAgeAllowedProvMoped = args.minimumAgeAllowedProvMoped;
      const dobValue = AppStore.getControlByName(args.against);

      // licence-time controls don't allow the day field to be set
      // we have to assume the oldest age, given the month
      const ignoreDayDoBValue = dobValue.substring(0, dobValue.length - 2) + "01";

      const diffDate = dateParser.getDateDiffInYears(ignoreDayDoBValue, input);
      let licenceType =
        !isNullOrUndefined(args.against) && args.against === "date-of-birth-additional"
          ? AppStore.getControlByName("driving-licence-additional")
          : AppStore.getControlByName("driving-licence");

      if (
        !isNullOrUndefined(minAgeAllowedProvMoped) &&
        (licenceType === "P" || licenceType === "M")
      ) {
        returnedObj.isValid = diffDate >= parseInt(minAgeAllowedProvMoped);
      } else {
        returnedObj.isValid = diffDate >= parseInt(minAgeAllowed);
      }

      returnedObj.message = formatMessage(licenceType, message);

      return returnedObj;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against age");
      return { isValid: false, message: message };
    }
  },

  isValidAgainstMaximumNcd(input, args, message) {
    try {
      let today = dayjs().format("YYYY-MM-DD");

      let minAgeAllowed = args.minimumAgeAllowed;
      let dobValue = AppStore.getControlByName(args.against[0]);
      let policyStartValue = AppStore.getControlByName(args.against[1]);
      let currentAge = dateParser.getDateDiffInYears(dobValue, today);

      let maxNcd;

      // (default option) if we want to validate using date-of-birth and policy-start-date
      if (!args.discountedPolicyStartDate === true) {
        // let maxNCD is the rider's age at the policy start date.
        maxNcd = dateParser.getDateDiffInYears(dobValue, policyStartValue);
        // otherwise, only use the user's date of birth to judge valid ncb
      } else {
        maxNcd = currentAge;
      }
      // let maxNCD is the rider's age at the policy start date.

      // let licenceType be the selected type of licence
      let licenceType = AppStore.getControlByName(args.licenceTypeControlName);
      // let licenceValue be the type of licence required to flag a separate validation - normally provisional.
      let licenceValue = args.licenceValue;
      // find the name of the years-licence-held control
      let licenceTimeControlName = args.licenceYearsControlName;
      // find the value of the years-licence-held control
      let licenceDateValue = AppStore.getControlByName(licenceTimeControlName);
      // let licenceYearsNumber be the number of years the user has held their licence, rounded down.
      let licenceYearsNumber = dateParser.getDateDiffInYears(licenceDateValue, today);

      // if the rider has a provisional licence and there is a specific provisional error message:
      if (licenceType === "P" && !isNullOrUndefined(args.provErrorMessage)) {
        message = args.provErrorMessage;
      }

      // if the selected value is "9+", make the value "9"
      if (typeof input === "string")
        input = input.includes("+") ? input.replace("+", "") : input;

      // replace the %YEARS% value with the number of years licence held
      if (!isNullOrUndefined(message))
        message = message.replace("%YEARS%", licenceYearsNumber);

      // if the rider is older than the minimum age allowed:
      if (maxNcd >= minAgeAllowed) {
        maxNcd = maxNcd - minAgeAllowed; // the maxNCD is now their age at the policy-start-date, minus the min allowed age.
      } else {
        // else, the rider is too young to ride - this should get caught earlier on
        return { isValid: false, message: message };
      }

      // if licence type if defined, and licenceValue is defined.
      if (!isNullOrUndefined(licenceType) && !isNullOrUndefined(licenceValue)) {
        // if the values match - in our case, if the licence type is 'P':
        if (licenceType === licenceValue) {
          maxNcd = licenceYearsNumber; // the max NCD is now the number of years the licence is held, instead.
        }
      }

      // if the selected number of years is greater than maxNCD, throw an erorr.
      if (input > maxNcd) {
        return { isValid: false, message: message };
      } else {
        return { isValid: true, message: message };
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against max ncd");
      return false;
    }
  },
  isValidAgainstDOBException: (input, args) => {
    try {
      const exceptDate = args.exceptValue;
      const dobValue = AppStore.getControlByName(args.against);
      const fullDateCompare = AppStore.getControlByName(args.fullDateCompare);

      let currentMonth = dayjs(input).format("MM");
      let currentYear = dayjs(input).format("YYYY");
      let dobMonth = dayjs(dobValue).format("MM");
      let dobYear = dayjs(dobValue).format("YYYY");

      if (exceptDate === input) return true;

      if (fullDateCompare === "true") {
        return dobValue <= input;
      } else {
        return (
          currentYear > dobYear || (dobYear == currentYear && currentMonth >= dobMonth)
        );
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against date of birth");
      return false;
    }
  },
  isValidAgainstCurrentYear: (input, args) => {
    try {
      let currentYearValue = parseInt(dayjs().format("YYYY"));
      let value = parseInt(input);
      let isEqualValidWhen = !isNullOrUndefined(args) ? args.isEqualValidWhen : "";
      if (currentYearValue < value) return false;
      else if (currentYearValue > value) return true;
      else {
        if (isEqualValidWhen !== "" && isEqualValidWhen === "false") {
          return false;
        }
        return true;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against current year");
      return false;
    }
  },
  isValidAgainstPropertyPurchasedDate: (input, args) => {
    try {
      let value = parseInt(input);
      const exceptDate = parseInt(args.exceptValue);
      const propertyPurchaseDateValue = AppStore.getControlByName(args.against);
      if (!isNullOrUndefined(propertyPurchaseDateValue)) {
        let propertyPurchaseDateYear = parseInt(
          dayjs(propertyPurchaseDateValue).format("YYYY")
        );

        if (exceptDate === propertyPurchaseDateYear) return true;

        if (propertyPurchaseDateYear >= value) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against property purchase date");
      return false;
    }
  },
  isValidAgainstMaxDate: (input, args) => {
    try {
      let currentDateValue = dayjs().format("YYYY-MM-DD");
      let value = input;
      let isEqualValidWhen = !isNullOrUndefined(args) ? args.isEqualValidWhen : "";
      let maxDaysAllowed = !isNullOrUndefined(args) ? args.maxDays : "";
      let maxDateAllowed = dayjs()
        .add(parseInt(maxDaysAllowed), "days")
        .format("YYYY-MM-DD");
      if (maxDateAllowed > value) return true;
      else if (maxDateAllowed < value) return false;
      else {
        if (isEqualValidWhen !== "" && isEqualValidWhen === "false") {
          return false;
        }
        return true;
      }
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against max date");
      return false;
    }
  },
  isValidAgainstMaxYears: (input, args) => {
    try {
      const currentDate = dayjs().format("YYYY-MM-DD");
      const diff = dateParser.getDateDiffInYears(input, currentDate);

      if (parseInt(diff) < parseInt(args.maxYears)) return true;
      return false;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against max years");
      return false;
    }
  },
  isPostcodeValidAgainstService: (input, args) => {
    try {
      let trimmedInput = input.replace(/\s+/g, "");
      let regex = new RegExp(args.overnightRegex);
      return regex.test(trimmedInput);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: invalid overnight postcode");
      return false;
    }
  },
  isValidAgainstLicenceTypeAndInterval: (input, args) => {
    try {
      if (input === null) return true;
      let levelValue = parseInt(input);
      let upper = args.upper;
      let lower = args.lower;
      let licenceType = AppStore.getControlByName(args.licenceTypeControlName);
      let licenceValue = args.licenceValue;
      let ageArgument = args.age;
      let dobValue = AppStore.getControlByName(args.dobControlName);

      if (!isNullOrUndefined(licenceType)) {
        // verification made only when age argument is present
        if (
          !isNullOrUndefined(ageArgument) &&
          ageArgument != "" &&
          !isNullOrUndefined(dobValue)
        ) {
          let currentDateValue = dayjs().format("YYYY-MM-DD");
          let dateLimit = dayjs(currentDateValue)
            .subtract(ageArgument, "years")
            .format("YYYY-MM-DD");
          if (dobValue > dateLimit) {
            if (
              licenceType === licenceValue &&
              !(levelValue >= lower && levelValue < upper)
            )
              return false;
          } else return true;
        }

        if (licenceType === licenceValue && !(levelValue >= lower && levelValue < upper))
          return false;
      }

      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against licence and interval");
      return false;
    }
  },
  isEmptyOptionalExtras: (input) => {
    try {
      const obj = input;
      if (!isNullOrUndefined(obj) && obj !== "") {
        let foundEnabled = false;
        Object.keys(obj).map((filter) => {
          if (obj[filter] === true || obj[filter] === "true") foundEnabled = true;
        });
        return !foundEnabled;
      }
      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: empty optional extras");
      return false;
    }
  },
  isCompleteRegSearch: (input) => {
    // isCompleteRegSearch is used to validate the bike-registration control
    // This validation rule is only applied when live validation is applied
    // It returns false if the bike-registration-search field is either empty, or has been filled, but the reg lookup has not been completed.
    // It returns true if the manual inputs are not present.
    try {
      const bikeRegistrationInput = document.getElementById("bike-registration-search");
      const bikeManufacturerControl = document.getElementById("bike-manufacturer");
      const engineCCControl = document.getElementById("engine-cc");
      const yearManufactureControl = document.getElementById("year-manufacture");

      if (
        bikeRegistrationInput &&
        !bikeManufacturerControl &&
        !engineCCControl &&
        !yearManufactureControl
      ) {
        // if bikeRegistrationInput is disabled, a vehicle details info card has been created
        if (isNullOrUndefined(input) || bikeRegistrationInput.disabled === false) {
          return false;
        }
      }

      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: against bike details controls");
      return false;
    }
  },

  isValidRegistrationSearchAgainstModel: (input, args) => {
    try {
      let againstValue = AppStore.getControlByName(args.modelControlName);
      return validator.isEmpty(againstValue);
    } catch (ex) {
      ClientLogging.logError(ex, "validator: registration search against model");
      return false;
    }
  },
  isValidRegistrationNumber: (input, args) => {
    try {
      const bikeManufacturerControl = AppStore.getControlByName(
        args.bikeManufacturerControlName
      );
      const engineCCControl = AppStore.getControlByName(args.engineCCControlName);
      const yearManufactureControl = AppStore.getControlByName(
        args.yearManufactureControlName
      );
      const modelControl = AppStore.getControlByName(args.modelControlName);
      if (
        bikeManufacturerControl !== undefined &&
        engineCCControl !== undefined &&
        yearManufactureControl !== undefined &&
        modelControl !== undefined
      ) {
        return false;
      }
      return true;
    } catch (ex) {
      ClientLogging.logError(ex, "validator: valid registration number");
      return false;
    }
  },
};

export default Validator;

export const formatMessage = (licenceType, message) => {
  //tbi message formatting
  if (!isNullOrUndefined(licenceType) && licenceType.length > 0) {
    message = message.replace(/%LICENCE%/g, formatLicenceType(licenceType));
  } else {
    message = message.replace(/%LICENCE%/g, formatLicenceType("E"));
  }

  if (licenceType === "P" || licenceType === "M")
    message = message.replace(/%YEARS%/g, "16");
  else message = message.replace(/%YEARS%/g, "17");

  return message;
};

const formatLicenceType = (type) => {
  switch (type) {
    case "F":
      return "a Full motorbike";
    case "P":
      return "a Provisional";
    case "M":
      return "a Moped";
    case "E":
      return "an EU motorbike";
    case "I":
      return "an International motorbike";
  }
};
