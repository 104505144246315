const ClientLogging = {
  logError: (exception, details) => {
    if (import.meta.env.VITE_DEBUGGING_MODE === "enabled") {
      console.log(exception, details);
    }
    var error = new Error("LevelError: " + details);
    window.NREUM.noticeError(error, exception);
  },

  logDebug: (exception, details) => {
    var debug = new Error("LevelDebug: " + details);
    window.NREUM.noticeError(exception, debug);
  },

  logInfo: (exception, details) => {
    var info = new Error("LevelInfo: " + details);
    window.NREUM.noticeError(exception, info);
  },
};
export default ClientLogging;
