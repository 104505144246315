import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ControlRowFactory from "../../factories/ControlRowFactory";
import helper from "../../helpers/controlsLogicHelper";
import Error from "../controls/StaticControls/Error.Component";
import Info from "../controls/StaticControls/Info.Component";
import Label from "../controls/StaticControls/Label.Component";
import Tooltip from "../controls/StaticControls/Tooltip.Component";

const Checkbox = ({
    id,
    name,
    label,
    handleCheckboxChange,
    handleSetDisabledChange,
    handleInteraction,
    currentValue,
    helpMessages,
    infoMessages,
    validation,
    attributes,
}) => {
    const errorClass = validation && validation.isValid === false ? "error" : "";
    const [isChecked, setIsChecked] = useState(
        currentValue === ""
            ? false
            : currentValue === true || currentValue === "true" || currentValue === "True"
    );

    useEffect(() => {
        handleCheckboxChange(name, isChecked);
        helper.ManipulateControlValues(name, currentValue);
    }, [currentValue]);

    let [childVisible, setChildControlVisibility] = useState(false);
    let [caravanStorageVisible, setCaravanStorageVisible] = useState(isChecked);

    if (isChecked) {
        childVisible = true;
    } else {
        childVisible = false;
    }


    const controlComponent = (
        <React.Fragment key={`controlkey-${name}`}>
            <div className="form-check form-checkbox form-check-inline">
                <input
                    id={name + "_" + id}
                    data-testid={`test-${name}`}
                    className="form-check-input"
                    type="checkbox"
                    name={name}
                    onChange={(event) => {
                        handleCheckboxChange(name, event.currentTarget.checked);
                        helper.ManipulateControlValues(name, currentValue);
                        setIsChecked(event.currentTarget.checked);
                        setChildControlVisibility(event.currentTarget.checked);
                     
                        handleSetDisabledChange(event);
                        handleInteraction(event);
                    }}
                    checked={isChecked}
                />
                <label
                    className="form-check-label"
                    htmlFor={name + "_" + id}
                    id={`label-for-${name}`}>
                    <svg
                        aria-hidden="true"
                        focusable="false"
                        viewBox="0 0 19 15"
                        enableBackground="new 0 0 19 15;">
                        <path
                            className="st0"
                            d="M17.943,3.859l-9.88,10.065c-0.754,0.769-1.977,0.769-2.731,0.001c0,0,0,0,0,0L1.075,9.588
	                                    c-0.754-0.769-0.754-2.015,0-2.783s1.977-0.769,2.732,0l0,0l2.891,2.947l8.514-8.675c0.754-0.769,1.977-0.769,2.732,0
	                                    c0.754,0.769,0.754,2.015,0,2.783"
                        />
                    </svg>
                </label>
            </div>
            {attributes.checkboxLabel && (
                <label className="form-check-control-label">{attributes.checkboxLabel}</label>
            )}
        </React.Fragment>
    );

    const componentMapping = {
        label: <Label key={`labelkey-${name}`} id={id} label={label} />,
        tooltip: (
            <Tooltip
                key={`tooltipkey-${name}`}
                helpMessages={helpMessages != null ? helpMessages.help : ""}
                controlName={name}
            />
        ),
        info: (
            <Info
                key={`infokey-${name}`}
                infoMessages={infoMessages != null ? infoMessages.info : ""}
            />
        ),
        error: <Error key={`errorkey-${name}`} validation={validation ? validation : ""} />,
        control: controlComponent,
    };

    const groupClasses = {
        "label-group": "col-lg-4 col-md-4 col-sm-12 col-12",
        "form-group": `col-lg-4 col-md-5 col-sm-12 col-12`,
    };

    return (
        <div className={`form-group form-group-container form-group-${name}`}>
            <div className={`row row-${errorClass} `}>
                <ControlRowFactory
                    controlName={name}
                    groupClasses={groupClasses}
                    componentMappings={componentMapping}
                />
            </div>
        </div>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Checkbox;
